import { useAnalytics } from '@hooks/useAnalytics';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  const handleBackToHome = () => {
    trackEvent('404_back_to_home_clicked', {
      from_path: window.location.pathname,
    });
    navigate('/busca');
  };

  return (
    <>
      <Helmet>
        <title>Página não encontrada</title>
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        textAlign="center"
        px={3}
      >
        <ErrorOutlineIcon sx={{ fontSize: 64, color: 'primary.main', mb: 2 }} />
        <Typography variant="h4" component="h1" gutterBottom color="primary">
          Página não encontrada
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
          A página que você está procurando não existe ou foi movida.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleBackToHome} size="large">
          Voltar para a página inicial
        </Button>
      </Box>
    </>
  );
};

export default NotFound;
