import { useAnalytics } from '@hooks/useAnalytics';
import { Add as AddIcon, Info as InfoIcon, Star as StarIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

interface PromoteFavoritesModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const PromoteFavoritesModal: React.FC<PromoteFavoritesModalProps> = ({
  open,
  onClose,
  onSuccess,
}) => {
  const { favoriteLicitacoes, fetchFavoritesLicitacoes } = useFavoritesStore();
  const { followingLicitacoes, addFollowing, isLoading, error } = useFollowingStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLicitacoes, setSelectedLicitacoes] = useState<string[]>([]);
  const [localError, setLocalError] = useState<string | null>(null);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (open) {
      trackEvent('promote_favorites_modal_opened', {
        total_favorites: favoriteLicitacoes.length,
        available_favorites: availableFavorites.length,
      });
      fetchFavoritesLicitacoes();
      setLocalError(null);
    }
  }, [open, fetchFavoritesLicitacoes]);

  // Filter out licitacoes that are already being followed
  const availableFavorites = useMemo(() => {
    const followingIds = new Set(followingLicitacoes.map((l) => l.id));
    return favoriteLicitacoes.filter(
      (licitacao) =>
        !followingIds.has(licitacao.id) &&
        licitacao.titulo.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [favoriteLicitacoes, followingLicitacoes, searchTerm]);

  const handlePromoteFavorites = useCallback(async () => {
    try {
      trackEvent('promote_favorites_initiated', {
        selected_count: selectedLicitacoes.length,
        total_available: availableFavorites.length,
      });
      setLocalError(null);
      for (const licitacaoId of selectedLicitacoes) {
        await addFollowing(licitacaoId);
      }
      trackEvent('promote_favorites_success', {
        promoted_count: selectedLicitacoes.length,
      });
      onSuccess();
      onClose();
      setSelectedLicitacoes([]);
      setSearchTerm('');
    } catch (err) {
      trackEvent('promote_favorites_error', {
        error_message: err instanceof Error ? err.message : String(err),
        selected_count: selectedLicitacoes.length,
      });
      setLocalError('Erro ao promover licitações. Tente novamente.');
    }
  }, [selectedLicitacoes, addFollowing, onClose, onSuccess]);

  const handleToggleLicitacao = useCallback(
    (licitacaoId: string) => {
      trackEvent('promote_favorite_selection_changed', {
        licitacao_id: licitacaoId,
        is_selected: !selectedLicitacoes.includes(licitacaoId),
        total_selected: selectedLicitacoes.length,
      });
      setSelectedLicitacoes((prev) =>
        prev.includes(licitacaoId)
          ? prev.filter((id) => id !== licitacaoId)
          : [...prev, licitacaoId],
      );
    },
    [selectedLicitacoes],
  );

  const handleClose = useCallback(() => {
    onClose();
    setSelectedLicitacoes([]);
    setSearchTerm('');
  }, [onClose]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <StarIcon sx={{ mr: 1 }} color="primary" />
          Promover Favoritos para Acompanhamento
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <InfoIcon color="info" sx={{ mr: 1 }} />
          <Typography variant="body2" color="info.main">
            Selecione as licitações favoritas que deseja promover para acompanhamento.
          </Typography>
        </Box>
        {availableFavorites.length === 0 ? (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Não há favoritos disponíveis para promover.
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Clique no ícone <StarIcon color="primary" /> de uma licitação para favoritar primeiro.
            </Typography>
          </Box>
        ) : (
          <>
            <TextField
              autoFocus
              margin="dense"
              label="Pesquisar Favoritos"
              type="text"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <List>
              {availableFavorites.map((licitacao) => (
                <ListItem key={licitacao.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedLicitacoes.includes(licitacao.id)}
                        onChange={() => handleToggleLicitacao(licitacao.id)}
                      />
                    }
                    label={<ListItemText primary={licitacao.titulo} secondary={licitacao.objeto} />}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={handlePromoteFavorites}
          color="primary"
          disabled={selectedLicitacoes.length === 0 || isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : <AddIcon />}
        >
          {isLoading
            ? 'Promovendo...'
            : `Promover ${selectedLicitacoes.length} Selecionado${selectedLicitacoes.length === 1 ? '' : 's'}`}
        </Button>
      </DialogActions>
      {(error || localError) && (
        <Alert severity="error" sx={{ mx: 3, mb: 2 }}>
          {error || localError}
        </Alert>
      )}
    </Dialog>
  );
};

export default React.memo(PromoteFavoritesModal);
