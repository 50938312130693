import { isKeyOfLicitacoesSearchParams, PORTAL_NAMES, PORTAL_ORDER } from '@constants';
import { STATUS_COLORS, STATUS_LABELS } from '@constants/mappings';
import { Esfera, Licitacao, LicitacaoStatus, LicitacoesSearchParams, Portal } from '@types';
import { isAfter } from 'date-fns';

const COMPRASNET_URL = 'https://cnetmobile.estaleiro.serpro.gov.br/';
export const getComprasNetId = (licitacao: Licitacao) => {
  let uasg = null;
  let numeroAviso = null;
  let modalidade = null;
  if (licitacao.id_licitacao.startsWith('CN-')) {
    const ids = licitacao.id_licitacao.split('-');
    if (ids.length < 4) {
      console.log(`Invalid ComprasNet CN- format: ${licitacao.id_licitacao}`);
      return null;
    }
    uasg = ids[1];
    numeroAviso = ids[3];
    modalidade = ids[2];
  } else if (licitacao.id_licitacao.startsWith('PNCP-')) {
    if (licitacao.linkExterno.startsWith(COMPRASNET_URL)) {
      return licitacao.linkExterno.split('compra=')[1];
    } else if (
      licitacao.portalData &&
      licitacao.portalData.linkSistemaOrigem &&
      licitacao.portalData.linkSistemaOrigem.startsWith(COMPRASNET_URL)
    ) {
      return licitacao.portalData.linkSistemaOrigem.split('compra=')[1];
    }
    if (
      !licitacao.codigo_uasg ||
      !licitacao.portalData ||
      !licitacao.portalData.numeroCompra ||
      !licitacao.portalData.anoCompra
    ) {
      console.log(`Invalid getComprasNetId PNCP data:`, licitacao);
      return null;
    }
    uasg = licitacao.codigo_uasg;
    numeroAviso = licitacao.portalData.numeroCompra + licitacao.portalData.anoCompra.toString();
    modalidade = licitacao.id_tipo.toString();
  } else {
    console.log(`Invalid getComprasNetId data:`, licitacao);
    return null;
  }

  const paddedUasg = String(uasg).padStart(6, '0');
  const paddedNumeroAviso = String(numeroAviso).padStart(8, '0');
  const paddedModalidade = String(modalidade).padStart(2, '0');

  return `${paddedUasg}${paddedModalidade}${paddedNumeroAviso}`;
};

export const parseSearchParams = (locationSearch: string) => {
  const searchParams = new URLSearchParams(locationSearch);
  const params: Partial<LicitacoesSearchParams> = {};

  searchParams.forEach((value, key) => {
    if (isKeyOfLicitacoesSearchParams(key)) {
      if (key === 'id_portal') {
        params[key] = params[key] || [];
        (params[key] as Portal[]).push(value as Portal);
      } else if (key === 'esfera') {
        params[key] = params[key] || [];
        (params[key] as Esfera[]).push(value as Esfera);
      } else if (key === 'data_abertura_inicio' || key === 'data_abertura_fim') {
        params[key] = value;
      } else if (key === 'valor_min' || key === 'valor_max') {
        params[key] = value;
      } else if (key === 'match_exact') {
        params[key] = value === 'true';
      } else if (key === 'cnae') {
        params[key] = params[key] || [];
        (params[key] as string[]).push(value);
      } else if (key === 'palavra_chave' || key === 'palavras_excluidas') {
        if (!params[key]) {
          params[key] = [];
        }
        (params[key] as string[]).push(decodeURIComponent(value));
      } else if (key === 'edital' || key === 'item') {
        params[key] = decodeURIComponent(value);
      } else if (Array.isArray(params[key])) {
        (params[key] as string[]).push(value);
      } else {
        params[key] = [value] as any;
      }
    }
  });

  return params;
};

// Function to determine if a color is light or dark
const isLightColor = (color: string) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 155;
};

// Function to get the text color based on the background color
export const getTextColor = (backgroundColor: string) => {
  return isLightColor(backgroundColor) ? '#000000' : '#FFFFFF';
};

// Function to get portal color and ensure readability
export const getPortalColorAndText = (portalId: string) => {
  const colors = ['#4caf50', '#2196f3', '#ff9800', '#e91e63', '#9c27b0'];
  const backgroundColor = colors[parseInt(portalId) % colors.length];
  const textColor = getTextColor(backgroundColor);
  return { backgroundColor, textColor };
};

export function createPortalOptions() {
  return Object.entries(PORTAL_NAMES).map(([key, value]) => ({
    value: Number(key),
    label: value,
    order: PORTAL_ORDER[key as Portal],
  }));
}

function getComputedStatus(
  status: string | null,
  abertura_datetime: string | null,
  fim_recebimento_propostas_datetime: string | null,
): LicitacaoStatus {
  const now = new Date();

  // First check if we have a valid status that matches our enum
  if (status) {
    const upperStatus = status.toUpperCase();
    if (Object.values(LicitacaoStatus).includes(upperStatus as LicitacaoStatus)) {
      // If status is PUBLISHED, we should still check dates
      if (upperStatus === LicitacaoStatus.PUBLISHED) {
        if (abertura_datetime) {
          if (isAfter(now, new Date(abertura_datetime))) {
            return LicitacaoStatus.IN_PROGRESS;
          } else {
            return LicitacaoStatus.RECEIVING_PROPOSALS;
          }
        }
        if (
          fim_recebimento_propostas_datetime &&
          isAfter(now, new Date(fim_recebimento_propostas_datetime))
        ) {
          return LicitacaoStatus.CLOSED;
        }
      }
      return upperStatus as LicitacaoStatus;
    }
  }

  // If no valid status, compute based on dates
  let computedStatus = LicitacaoStatus.PUBLISHED;

  if (abertura_datetime) {
    if (isAfter(now, new Date(abertura_datetime))) {
      computedStatus = LicitacaoStatus.IN_PROGRESS;
    } else {
      computedStatus = LicitacaoStatus.RECEIVING_PROPOSALS;
    }
  }

  if (
    fim_recebimento_propostas_datetime &&
    isAfter(now, new Date(fim_recebimento_propostas_datetime))
  ) {
    computedStatus = LicitacaoStatus.CLOSED;
  }

  return computedStatus;
}

export const getLicitacaoStatus = (
  status: string | null,
  abertura_datetime: string | null,
  fim_recebimento_propostas_datetime: string | null,
): string => {
  // If we have a raw status that's not in our enum, return it directly
  if (status && !Object.values(LicitacaoStatus).includes(status.toUpperCase() as LicitacaoStatus)) {
    return status;
  }
  const computedStatus = getComputedStatus(
    status,
    abertura_datetime,
    fim_recebimento_propostas_datetime,
  );

  return STATUS_LABELS[computedStatus];
};

export const getLicitacaoStatusInfo = (
  status: string | null,
  abertura_datetime?: string | null,
  fim_recebimento_propostas_datetime?: string | null,
) => {
  const computedStatus = getComputedStatus(
    status,
    abertura_datetime || null,
    fim_recebimento_propostas_datetime || null,
  );
  const label = getLicitacaoStatus(
    status,
    abertura_datetime || null,
    fim_recebimento_propostas_datetime || null,
  );

  return {
    label,
    color: STATUS_COLORS[computedStatus as LicitacaoStatus],
    status: computedStatus,
  };
};
