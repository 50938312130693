import Annotation from '@components/Annotation/Annotation';
import DocumentRequiredModal from '@components/Documents/DocumentsRequiredModal';
import CustomButton from '@components/Input/CustomButton';
import Loading from '@components/Layout/Loading';
import AddToCalendarButton from '@components/Licitacao/AddToCalendarButton';
import AIChatModal from '@components/Licitacao/AIChatModal';
import InfoItem from '@components/Licitacao/InfoItem';
import PortalDetails from '@components/Licitacao/PortalDetails';
import StepsModal from '@components/Licitacao/StepsModal';
import WhatsAppButton from '@components/Licitacao/WhatsAppButton';
import LicitacaoActions from '@components/Search/LicitacaoActions';
import { ESFERAS, PORTAL_NAMES } from '@constants';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import {
  AccountBalance,
  Assignment as AssignmentIcon,
  AttachMoney,
  Business,
  Category,
  Chat as ChatIcon,
  CheckCircle as CheckCircleIcon,
  Description,
  Dns,
  Email,
  Event,
  EventAvailable,
  FilePresent,
  ListAlt,
  LocationOn,
  OpenInNew,
  PictureAsPdf,
  Public as PublicIcon,
  Search,
  Today,
} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { PortalServiceFactory } from '@services/portals/PortalServiceFactory';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { useLicitacoesStore } from '@store/licitacoesStore';
import { useProfileStore } from '@store/profileStore';
import { FeatureId, Licitacao, RecommendedLicitacao } from '@types';
import { formatCurrency, formatIsoDate, getComprasNetId, getLicitacaoStatus } from '@utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';

type CombinedLicitacao = Licitacao & RecommendedLicitacao;

const LicitacaoDetails: React.FC = () => {
  const theme = useTheme();
  const { id_licitacao } = useParams<{ id_licitacao: string }>();
  const { getLicitacaoById, fetchLicitacaoDetails, isLoadingDetails, getModalidadeInfo, error } =
    useLicitacoesStore();
  const { isFollowing, getSelectedItems, following } = useFollowingStore();
  const { favorites, isFavorite } = useFavoritesStore();
  const [licitacao, setLicitacao] = useState<CombinedLicitacao | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isBiddingModalOpen, setIsBiddingModalOpen] = useState(false);
  const { trackEvent } = useAnalytics();
  const { checkFeatureAccess } = useProfileStore();
  const hasAiAccess = checkFeatureAccess(FeatureId.AI_ANALYSIS).hasAccess;
  const hasDocAccess = checkFeatureAccess(FeatureId.DOC_VERIFICATION).hasAccess;
  const [showAnnotation, setShowAnnotation] = useState(false);
  const { showNotification } = useNotification();
  const [searchParams] = useSearchParams();
  const isUpdated = searchParams.get('updated') === 'true';
  const [highlightedFields, setHighlightedFields] = useState<string[]>([]);

  const selectedItems = licitacao ? getSelectedItems(licitacao?.id) : [];

  const isItemSelected = (itemId: string) => {
    return selectedItems.some((selected) => selected.id === itemId);
  };

  useEffect(() => {
    if (!id_licitacao) return;

    const fetchData = async () => {
      const currentLicitacao = getLicitacaoById(id_licitacao) as CombinedLicitacao | undefined;

      if (currentLicitacao) {
        setLicitacao(currentLicitacao);
        trackEvent('licitacao_details_store_load', {
          licitacao_id: id_licitacao,
        });
      } else if (!isLoadingDetails) {
        const updatedLicitacao = await fetchLicitacaoDetails(id_licitacao);
        if (updatedLicitacao) {
          setLicitacao(updatedLicitacao as CombinedLicitacao);
          trackEvent('licitacao_details_api_load', {
            licitacao_id: id_licitacao,
            modalidade: updatedLicitacao.id_tipo,
            portal: updatedLicitacao.id_portal,
          });
        }
      }
    };

    fetchData();
  }, [id_licitacao]);

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
      trackEvent('licitacao_details_error', {
        error_message: error,
        licitacao_id: id_licitacao,
      });
    }
  }, [error]);

  useEffect(() => {
    if (licitacao) {
      setShowAnnotation(isFavorite(licitacao.id) || isFollowing(licitacao.id));
    }
  }, [licitacao, favorites, following]);

  useEffect(() => {
    if (isUpdated && licitacao?.updatedFields) {
      setHighlightedFields(licitacao.updatedFields);
    }
  }, [isUpdated, licitacao]);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      trackEvent('licitacao_tab_change', {
        tab_index: newValue,
        tab_name: newValue === 0 ? 'items' : 'documents',
        licitacao_id: licitacao?.id,
      });
      setTabValue(newValue);
    },
    [licitacao?.id, trackEvent],
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value.length >= 3) {
        trackEvent('items_search', {
          search_term: value,
          licitacao_id: licitacao?.id,
          total_items: licitacao?.items?.length,
        });
      }
      setSearchTerm(value);
    },
    [licitacao?.id, licitacao?.items?.length, trackEvent],
  );

  const handleModalActions = useMemo(
    () => ({
      openChat: () => {
        if (!hasAiAccess) {
          showNotification('Faça upgrade do seu plano para usar a IA', 'warning');
          return;
        }
        setIsChatModalOpen(true);
      },
      closeChat: () => setIsChatModalOpen(false),
      extractDocuments: () => {
        if (!hasDocAccess) {
          showNotification('Faça upgrade do seu plano para analisar documentos', 'warning');
          return;
        }
        setIsDocumentModalOpen(true);
      },
      closeDocument: () => setIsDocumentModalOpen(false),
      openBidding: () => setIsBiddingModalOpen(true),
      closeBidding: () => setIsBiddingModalOpen(false),
    }),
    [hasAiAccess, hasDocAccess, showNotification],
  );

  const filteredItems = useMemo(
    () =>
      licitacao?.items?.filter((item) =>
        item.descricao.toLowerCase().includes(searchTerm.toLowerCase()),
      ) || [],
    [licitacao?.items, searchTerm],
  );

  const TabLabel = useMemo(() => {
    return ({ label, count }: { label: string; count?: number }) => (
      <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
        {label}
        {count !== undefined && (
          <Box
            component="span"
            sx={{
              marginLeft: '8px',
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              borderRadius: '16px',
              padding: '2px 8px',
              fontSize: '0.75rem',
              fontWeight: 'bold',
              minWidth: '24px',
              textAlign: 'center',
              lineHeight: 1.5,
              boxShadow: `0 0 0 1px ${theme.palette.divider}`,
            }}
          >
            {count}
          </Box>
        )}
      </Box>
    );
  }, [theme]);

  if (isLoadingDetails || (!licitacao && !error)) {
    return <Loading />;
  }

  if (!licitacao && error) {
    return (
      <>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom color="primary">
            Licitação não encontrada
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Search />}
            component={RouterLink}
            to={`/busca`}
          >
            Voltar para a lista de licitações
          </Button>
        </Box>
      </>
    );
  }

  if (!licitacao) {
    return <Loading />;
  }

  const modalidadeInfo = getModalidadeInfo(licitacao.id_tipo);

  const currentStatus = getLicitacaoStatus(
    licitacao.status,
    licitacao.abertura_datetime,
    licitacao.fim_recebimento_propostas_datetime,
  );

  const portalService = PortalServiceFactory.create(licitacao);

  const mappedDocuments =
    licitacao.documents?.map((doc) => {
      const url = doc.downloadUrl || doc.url;
      if (url && url.includes('?captcha=1')) {
        const comprasNetId = getComprasNetId(licitacao);
        if (comprasNetId) {
          return {
            ...doc,
            downloadUrl: `${portalService.getLinkBaseUrl()}?url=${encodeURIComponent(url)}&comprasnetId=${comprasNetId}`,
          };
        }
      }
      return doc;
    }) || [];

  return (
    <>
      <Helmet>
        <title>
          {licitacao ? `${licitacao.titulo} - Licitou` : 'Detalhes da Licitação - Licitou'}
        </title>
        <meta
          name="description"
          content={
            licitacao?.objeto ||
            'Detalhes completos da licitação, incluindo documentos, itens e informações do portal'
          }
        />
      </Helmet>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom color="primary">
          {licitacao.titulo}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: theme.palette.background.paper, position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: 10, right: 10, display: 'flex', gap: 1 }}>
                {portalService.getEditalUrl() && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<PictureAsPdf />}
                    href={portalService.getEditalUrl() || '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Baixar Edital
                  </Button>
                )}
                {portalService.getPortalUrl() && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<OpenInNew />}
                    href={portalService.getPortalUrl() || '#'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Abrir no portal
                  </Button>
                )}
                <LicitacaoActions licitacaoId={licitacao.id} />
              </Box>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <InfoItem
                      icon={AccountBalance}
                      label="Órgão"
                      value={licitacao.orgao}
                      highlight={highlightedFields.includes('orgao')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InfoItem
                      icon={LocationOn}
                      label="Local"
                      value={`${licitacao.municipio} - ${licitacao.uf}`}
                      link={`/busca?municipio_ibge=${licitacao.municipio_IBGE}`}
                      highlight={highlightedFields.includes('municipio_ibge')}
                    />
                    <InfoItem
                      icon={Business}
                      label="Portal"
                      value={
                        licitacao.portal ||
                        (licitacao.id_portal && PORTAL_NAMES[licitacao.id_portal]) ||
                        '-'
                      }
                      link={
                        licitacao.id_portal ? `/busca?id_portal=${licitacao.id_portal}` : undefined
                      }
                      highlight={highlightedFields.includes('id_portal')}
                    />
                    <InfoItem
                      icon={Category}
                      label="Código CNAE"
                      value={licitacao.cnae || '-'}
                      link={licitacao.cnae ? `/busca?cnae=${licitacao.cnae}` : undefined}
                      highlight={highlightedFields.includes('cnae')}
                    />
                    <InfoItem
                      icon={Business}
                      label="Código UASG"
                      value={licitacao.codigo_uasg || '-'}
                      link={
                        licitacao.codigo_uasg
                          ? `/busca?codigo_uasg=${licitacao.codigo_uasg}`
                          : undefined
                      }
                      highlight={highlightedFields.includes('codigo_uasg')}
                    />
                    <InfoItem
                      icon={modalidadeInfo.icon}
                      label="Modalidade"
                      value={modalidadeInfo.name}
                      link={`/busca?modalidade=${licitacao.id_tipo}`}
                      highlight={highlightedFields.includes('id_tipo')}
                    />
                    <InfoItem
                      icon={ListAlt}
                      label="Número do edital"
                      value={licitacao.numeroEdital || '-'}
                      link={
                        licitacao.numeroEdital
                          ? `/busca?edital=${licitacao.numeroEdital}`
                          : undefined
                      }
                      highlight={highlightedFields.includes('numeroEdital')}
                    />
                    <InfoItem
                      icon={PublicIcon}
                      label="Esfera"
                      value={ESFERAS[licitacao.esfera] || '-'}
                      link={`/busca?esfera=${licitacao.esfera}`}
                      highlight={highlightedFields.includes('esfera')}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InfoItem
                      icon={AttachMoney}
                      label="Valor estimado"
                      value={licitacao.valor > 0 ? formatCurrency(licitacao.valor) : '-'}
                      highlight={highlightedFields.includes('valor')}
                    />
                    <InfoItem
                      icon={Today}
                      label="Data publicação"
                      value={
                        licitacao.publicacao_datetime
                          ? formatIsoDate(licitacao.publicacao_datetime)
                          : formatIsoDate(licitacao.createdAt)
                      }
                      highlight={highlightedFields.includes('publicacao_datetime')}
                    />
                    <InfoItem
                      icon={Event}
                      label="Data final para propostas"
                      value={
                        licitacao.fim_recebimento_propostas_datetime
                          ? formatIsoDate(licitacao.fim_recebimento_propostas_datetime)
                          : '-'
                      }
                      highlight={highlightedFields.includes('fim_recebimento_propostas_datetime')}
                    />
                    <InfoItem
                      icon={EventAvailable}
                      label="Data de abertura do pregão"
                      value={formatIsoDate(licitacao.abertura_datetime)}
                      highlight={highlightedFields.includes('abertura_datetime')}
                    />
                    <InfoItem
                      icon={Email}
                      label="Contato"
                      value={licitacao.telefoneContato || licitacao.emailContato || '-'}
                      highlight={highlightedFields.includes('telefoneContato')}
                    />
                    <InfoItem
                      icon={Dns}
                      label="Status"
                      value={currentStatus}
                      highlight={highlightedFields.includes('status')}
                    />
                    <InfoItem
                      icon={Description}
                      label="ID Licitou"
                      value={licitacao.id}
                      copyable
                      highlight={highlightedFields.includes('id')}
                    />
                  </Grid>
                </Grid>

                <Box mt={4}>
                  <Typography variant="h6" color="primary" gutterBottom>
                    Descrição
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {licitacao.objeto}
                  </Typography>
                </Box>
                <Box
                  className="licitacao-actions"
                  display="flex"
                  justifyContent="space-between"
                  mt={2}
                >
                  <WhatsAppButton data-tour="whatsapp-button" licitacao={licitacao} />
                  <AddToCalendarButton data-tour="calendar-button" licitacao={licitacao} />
                  {licitacao.documents && licitacao.documents.length > 0 && (
                    <CustomButton
                      data-tour="documents-button"
                      onClick={handleModalActions.extractDocuments}
                      icon={<FilePresent />}
                      text={
                        hasDocAccess
                          ? 'Documentos Necessários'
                          : 'Faça upgrade para analisar documentos'
                      }
                      backgroundColor="#1976d2"
                      hoverColor="#1565c0"
                      disabled={!hasDocAccess}
                      title={!hasDocAccess ? 'Faça upgrade para analisar documentos' : undefined}
                    />
                  )}
                  <Button
                    data-tour="ai-chat-button"
                    variant="contained"
                    color="warning"
                    startIcon={<ChatIcon />}
                    onClick={handleModalActions.openChat}
                    disabled={!hasAiAccess}
                    title={!hasAiAccess ? 'Faça upgrade para usar a IA' : undefined}
                    sx={{
                      opacity: !hasAiAccess ? 0.5 : 1,
                      cursor: !hasAiAccess ? 'not-allowed' : 'pointer',
                      backgroundColor: theme.palette.warning.main,
                      '&:hover': {
                        backgroundColor: theme.palette.warning.dark,
                      },
                      '&.Mui-disabled': {
                        backgroundColor: theme.palette.warning.main,
                        color: theme.palette.warning.contrastText,
                      },
                      '& svg': {
                        opacity: !hasAiAccess ? 0.7 : 1,
                      },
                    }}
                  >
                    {hasAiAccess ? 'Pergunte à LicitAI' : 'Faça upgrade para usar a IA'}
                  </Button>
                  <Button
                    data-tour="participate-button"
                    variant="contained"
                    color="primary"
                    onClick={handleModalActions.openBidding}
                    startIcon={<AssignmentIcon />}
                  >
                    Participar da Licitação
                  </Button>
                </Box>
                {showAnnotation && (
                  <Annotation
                    licitacaoId={licitacao.id}
                    isVisible={true}
                    type={isFollowing(licitacao.id) ? 'following' : 'favorite'}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: theme.palette.background.paper }}>
              <CardContent>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  centered
                  variant="fullWidth"
                  sx={{
                    borderRadius: '8px 8px 0 0',
                    '& .MuiTab-root': {
                      color: theme.palette.text.primary,
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      textTransform: 'none',
                      minHeight: 56,
                      '&.Mui-selected': {
                        color: theme.palette.secondary.main,
                      },
                    },
                    '& .MuiTabs-indicator': {
                      backgroundColor: theme.palette.secondary.main,
                      height: 3,
                    },
                  }}
                >
                  <Tab
                    className="items-tab"
                    label={<TabLabel label="Itens" count={licitacao?.items?.length || 0} />}
                  />
                  <Tab
                    className="documents-tab"
                    label={<TabLabel label="Documentos" count={mappedDocuments.length} />}
                  />
                </Tabs>
                <Box sx={{ mt: 2 }}>
                  {tabValue === 0 && (
                    <>
                      {licitacao?.items?.length > 0 && (
                        <Box sx={{ mb: 2 }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Buscar itens"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      )}
                      <TableContainer component={Paper} sx={{ maxHeight: 440, overflow: 'auto' }}>
                        <Table size="small" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Item
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Quantidade
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Valor Unitário
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: '0.875rem',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Valor Total
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredItems.map((item, index) => {
                              const itemId =
                                item.id || `${licitacao.id}-${item.numeroItem || index}`;
                              const selected = isItemSelected(itemId);

                              return (
                                <TableRow
                                  key={index}
                                  hover
                                  sx={{
                                    backgroundColor: selected
                                      ? (theme) => alpha(theme.palette.primary.main, 0.08)
                                      : 'inherit',
                                    '&:hover': {
                                      backgroundColor: selected
                                        ? (theme) => alpha(theme.palette.primary.main, 0.12)
                                        : undefined,
                                    },
                                  }}
                                >
                                  <TableCell sx={{ fontSize: '0.8125rem' }}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                      {selected && (
                                        <CheckCircleIcon color="primary" sx={{ fontSize: 16 }} />
                                      )}
                                      <Typography
                                        variant="body2"
                                        component="span"
                                        sx={{ fontWeight: selected ? 'bold' : 'normal' }}
                                      >
                                        {item.numeroItem}:
                                      </Typography>
                                      {item.descricao}
                                    </Stack>
                                  </TableCell>
                                  <TableCell
                                    sx={{ fontSize: '0.8125rem', whiteSpace: 'nowrap' }}
                                  >{`${item.quantidade} ${item.unidadeMedida}`}</TableCell>
                                  <TableCell sx={{ fontSize: '0.8125rem', whiteSpace: 'nowrap' }}>
                                    {formatCurrency(item.valorUnitarioEstimado)}
                                  </TableCell>
                                  <TableCell sx={{ fontSize: '0.8125rem', whiteSpace: 'nowrap' }}>
                                    {formatCurrency(item.valorTotal)}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {tabValue === 1 && (
                    <TableContainer component={Paper} sx={{ maxHeight: 440, overflow: 'auto' }}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '0.875rem',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Nome
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '0.875rem',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Tipo
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '0.875rem',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Data
                            </TableCell>
                            <TableCell
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '0.875rem',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Download
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {mappedDocuments.map((doc, index) => (
                            <TableRow key={index} hover>
                              <TableCell sx={{ fontSize: '0.8125rem' }}>{doc.nome}</TableCell>
                              <TableCell sx={{ fontSize: '0.8125rem' }}>{doc.tipo}</TableCell>
                              <TableCell sx={{ fontSize: '0.8125rem', whiteSpace: 'nowrap' }}>
                                {formatIsoDate(doc.dataHora)}
                              </TableCell>
                              <TableCell sx={{ fontSize: '0.8125rem' }}>
                                {doc.downloadUrl ? (
                                  <Link
                                    href={doc.downloadUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <PictureAsPdf color="primary" />
                                  </Link>
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {licitacao.id_portal &&
            licitacao.portalData &&
            Object.keys(licitacao.portalData).length > 0 && (
              <Grid item xs={12} className="portal-details">
                <PortalDetails portalData={licitacao.portalData} licitacao={licitacao} />
              </Grid>
            )}
          {isLoadingDetails && (
            <Grid item xs={12}>
              <Card sx={{ backgroundColor: theme.palette.background.paper }}>
                <CardContent>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress color="primary" />
                    <Typography
                      variant="body1"
                      sx={{
                        marginLeft: '1rem',
                        color: theme.palette.text.secondary,
                      }}
                    >
                      Carregando informações adicionais...
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Box>
      {hasAiAccess && (
        <AIChatModal
          open={isChatModalOpen}
          onClose={handleModalActions.closeChat}
          licitacao={licitacao}
        />
      )}
      {hasDocAccess && (
        <DocumentRequiredModal
          open={isDocumentModalOpen}
          onClose={handleModalActions.closeDocument}
          licitacao={licitacao}
        />
      )}
      <StepsModal
        open={isBiddingModalOpen}
        onClose={handleModalActions.closeBidding}
        licitacao={licitacao}
      />
    </>
  );
};

export default React.memo(LicitacaoDetails);
