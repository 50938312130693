import { BllBncLicitacao, Licitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class BllBncPortalService extends BasePortalService {
  name = 'BLL/BNC';
  logo = '/images/portals/bllbnc.png';
  portal = Portal.BLLCompras;
  portalData: BllBncLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as BllBncLicitacao;
  }

  private isBll(): boolean {
    return this.licitacao.linkExterno.includes('bllcompras.com');
  }
  private isBnc(): boolean {
    return this.licitacao.linkExterno.includes('bnccompras.com');
  }

  getBaseUrl(): string | null {
    return this.isBll() ? 'https://www.bllcompras.com.br' : 'https://www.bnccompras.com.br';
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return `${this.getBaseUrl()}/Participant/ProcessSearch?param1=0`;
  }

  getFollowUpUrl(): string | null {
    return `${this.getBaseUrl()}/Participant/ProcessSearch?param1=0`;
  }
  getImpugnacaoUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getLoginUrl(): string | null {
    return `${this.getBaseUrl()}/Home/Login`;
  }

  getRegisterUrl(): string | null {
    return this.isBnc()
      ? 'https://bll.org.br/cadastro-para-fornecedores/'
      : 'https://bnc.org.br/cadastro/';
  }
}
