import { HelpOutline } from '@mui/icons-material';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';

interface KeywordHelpProps {
  color?: string;
}

const KeywordHelp: React.FC<KeywordHelpProps> = ({ color = 'inherit' }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 1, color }}>
        <HelpOutline />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 3, maxWidth: 400 }}>
          <Typography variant="h6" gutterBottom>
            Dicas de Busca Avançada
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Padrões de Busca:</strong>
          </Typography>
          <Typography variant="body2" component="div">
            <ul>
              <li>
                Use <code>(a|b)</code> para buscar "a" OU "b"
              </li>
              <li>
                Use <code>[s]</code> para tornar o plural opcional
              </li>
              <li>
                Use <code>[es|s]</code> para múltiplas terminações
              </li>
            </ul>
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Limites de Variações:</strong>
          </Typography>
          <Typography variant="body2" component="div">
            <ul>
              <li>Palavras simples: até 250 palavras</li>
              <li>Com um padrão ([] ou ()): até ~31 palavras</li>
              <li>Com múltiplos padrões: até ~15 palavras</li>
            </ul>
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Exemplos:</strong>
          </Typography>
          <Typography variant="body2" component="div">
            <ul>
              <li>
                <code>energia[s] solar[es]</code> encontra: energia solar, energias solares, etc.
              </li>
              <li>
                <code>consult(or|oria)[s]</code> encontra: consultor, consultores, consultoria,
                consultorias
              </li>
              <li>
                <code>gera(r|ção|ções)</code> encontra: gerar, geração, gerações
              </li>
            </ul>
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

export default KeywordHelp;
