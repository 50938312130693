import { getModalidadeInfo, PORTAL_SHORT_NAMES } from '@constants';
import {
  AccountBalance,
  AttachMoney,
  EventAvailable,
  LocationOn,
  Public as PortalIcon,
} from '@mui/icons-material';
import { Box, Button, Card, CardContent, Chip, Typography, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useFollowingStore } from '@store/followingStore';
import { RecommendedLicitacao } from '@types';
import { formatIsoDate, isNew, truncateText } from '@utils';
import React from 'react';
import { Link } from 'react-router-dom';

interface RecommendedCardProps {
  licitacao: RecommendedLicitacao;
  isFirst: boolean;
}

const RecommendedCard: React.FC<RecommendedCardProps> = ({ licitacao }) => {
  const theme = useTheme();
  const { isFollowing } = useFollowingStore();
  const modalidadeInfo = getModalidadeInfo(licitacao.id_tipo);
  const isNewLicitacao = isNew(licitacao.createdAt);

  return (
    <Link to={`/licitacao/${licitacao.id}`} style={{ textDecoration: 'none' }}>
      <Card
        sx={{
          height: '100%',
          minHeight: '240px',
          boxShadow: 3,
          '&:hover': { boxShadow: 6 },
          position: 'relative',
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          ...(isFollowing(licitacao.id) && {
            borderLeft: `4px solid ${theme.palette.primary.main}`,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '4px',
              background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${alpha(
                theme.palette.primary.main,
                0.1,
              )} 100%)`,
            },
          }),
        }}
      >
        {isNewLicitacao && (
          <Chip
            label="Nova"
            color="primary"
            size="small"
            sx={{
              position: 'absolute',
              top: 8,
              left: isFollowing(licitacao.id) ? 12 : 8,
              zIndex: 1,
            }}
          />
        )}
        <CardContent
          sx={{
            p: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box>
            <Box display="flex" alignItems="flex-start" gap={1} mb={0.5}>
              <modalidadeInfo.icon fontSize="small" style={{ color: modalidadeInfo.color }} />
              <Typography
                variant="subtitle2"
                color="primary"
                sx={{
                  flex: 1,
                  fontSize: '0.95rem',
                  fontWeight: 500,
                }}
              >
                {truncateText(licitacao.titulo, 100)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <AccountBalance
                fontSize="small"
                sx={{ color: theme.palette.primary.main, opacity: 0.7 }}
              />
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  flex: 1,
                  fontSize: '0.875rem',
                }}
              >
                {truncateText(licitacao.orgao, 60)}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 0.5,
              mb: 0.5,
            }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <PortalIcon fontSize="small" sx={{ color: 'action.active' }} />
              <Typography variant="caption">{PORTAL_SHORT_NAMES[licitacao.id_portal]}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <LocationOn fontSize="small" sx={{ color: 'action.active' }} />
              <Typography variant="caption">
                {licitacao.municipio ? `${licitacao.municipio}/${licitacao.uf}` : 'N/A'}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <EventAvailable fontSize="small" sx={{ color: 'action.active' }} />
              <Typography variant="caption">
                {licitacao.abertura_datetime ? formatIsoDate(licitacao.abertura_datetime) : 'N/A'}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              <AttachMoney fontSize="small" sx={{ color: 'action.active' }} />
              <Typography variant="caption">
                {licitacao.valor > 0
                  ? new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(licitacao.valor)
                  : 'N/A'}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ flex: 1, minHeight: 0, display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                position: 'relative',
                flex: 1,
                minHeight: 0,
                overflow: 'hidden',
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: '0.875rem',
                  lineHeight: 1.4,
                }}
              >
                {truncateText(licitacao.objeto, 120)}
              </Typography>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: '24px',
                  background: `linear-gradient(to bottom, transparent, ${theme.palette.background.paper})`,
                }}
              />
            </Box>
            <Button
              size="small"
              sx={{
                width: '100%',
                mt: 'auto',
                pt: 0.5,
              }}
            >
              Ver mais
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export default RecommendedCard;
