import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useRef } from 'react';

interface DocumentEditModalProps {
  document: {
    id: string;
    customName: string;
    documentNumber: string;
    generateDate: Date | null;
    expireDate: Date | null;
    documents?: Array<{ id: string; name: string; customName?: string }>;
  } | null;
  isOpen: boolean;
  isLoading: boolean;
  editingChildId: string | null;
  editingChildName: string;
  onClose: () => void;
  onSave: () => void;
  onUpdateDocument: (field: string, value: any) => void;
  onEditChildStart: (id: string, name: string) => void;
  onEditChildCancel: () => void;
  onEditChildSave: (parentId: string, childId: string) => void;
  onEditChildNameChange: (name: string) => void;
  onDownloadChild: (parentId: string, name: string, childId: string) => void;
  onDeleteChild: (parentId: string, childId: string) => void;
  onUploadChild: (event: React.ChangeEvent<HTMLInputElement>, parentId: string) => void;
}

const DocumentEditModal: React.FC<DocumentEditModalProps> = ({
  document,
  isOpen,
  isLoading,
  editingChildId,
  editingChildName,
  onClose,
  onSave,
  onUpdateDocument,
  onEditChildStart,
  onEditChildCancel,
  onEditChildSave,
  onEditChildNameChange,
  onDownloadChild,
  onDeleteChild,
  onUploadChild,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Editar Documento</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Nome do Documento"
              value={document?.customName || ''}
              onChange={(e) => onUpdateDocument('customName', e.target.value)}
              fullWidth
            />
            <TextField
              label="Número do Documento"
              value={document?.documentNumber || ''}
              onChange={(e) => onUpdateDocument('documentNumber', e.target.value)}
              fullWidth
            />
            <DatePicker
              label="Data de Emissão"
              value={document?.generateDate}
              onChange={(newValue) => onUpdateDocument('generateDate', newValue)}
              format="dd/MM/yyyy"
              slotProps={{ textField: { fullWidth: true } }}
            />
            <DatePicker
              label="Data de Validade"
              value={document?.expireDate}
              onChange={(newValue) => onUpdateDocument('expireDate', newValue)}
              format="dd/MM/yyyy"
              slotProps={{ textField: { fullWidth: true } }}
            />

            <Divider sx={{ my: 2 }} />

            <Typography variant="subtitle1">Documentos Anexos</Typography>
            <List dense>
              {document?.documents?.map((doc) => (
                <ListItem
                  key={doc.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    py: 1,
                  }}
                >
                  {editingChildId === doc.id ? (
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexGrow: 1 }}>
                      <TextField
                        size="small"
                        fullWidth
                        value={editingChildName}
                        onChange={(e) => onEditChildNameChange(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            onEditChildSave(document.id, doc.id);
                          }
                        }}
                        autoFocus
                      />
                      <IconButton size="small" onClick={() => onEditChildSave(document.id, doc.id)}>
                        <CheckIcon />
                      </IconButton>
                      <IconButton size="small" onClick={onEditChildCancel}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <Box sx={{ flexGrow: 1 }}>{doc.customName || doc.name}</Box>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton
                          size="small"
                          onClick={() => onEditChildStart(doc.id, doc.customName || doc.name)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => onDownloadChild(document.id, doc.name, doc.id)}
                        >
                          <DownloadIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => onDeleteChild(document.id, doc.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </ListItem>
              ))}
            </List>

            <Button
              variant="outlined"
              size="small"
              startIcon={<CloudUploadIcon />}
              onClick={handleUploadClick}
              disabled={isLoading}
            >
              Adicionar Anexos
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              hidden
              multiple
              onChange={(e) => onUploadChild(e, document!.id)}
            />
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={onSave} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentEditModal;
