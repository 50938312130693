import cnaes from '@data/CNAE.json';
import estados from '@data/estados.json';
import cidades from '@data/municipios.json';
import {
  AiProfile,
  Annotation,
  Bulletin,
  ChildDocument,
  City,
  CNAE,
  Company,
  CustomFollowingItem,
  DailyCount,
  Document,
  Favorite,
  FirestoreUser,
  Following,
  FollowingImportance,
  FollowingItem,
  KanbanBoard,
  KanbanLicitacao,
  Licitacao,
  LicitacoesSearchParams,
  LicitacoesSearchResponse,
  Notification,
  PaymentInfo,
  Preferences,
  RecommendedLicitacoes,
  RecommendedSearchResponse,
  SavedSearch,
  SearchResponseError,
  Tag,
  TagType,
  UASG,
  UserPreferences,
  UserProfile,
} from '@types';

import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL.replace(/\/+$/, '')
  : '';

export interface AuthFunctions {
  getIdToken: (forceRefresh?: boolean) => Promise<string | null>;
  signOut: () => Promise<void>;
  setUserInactive: () => void; // Add this new method
  setUserNotFound: () => void; // Add this new method
}

class Api {
  private api: AxiosInstance;
  private static authFunctions: AuthFunctions | null = null;
  private currentProfileId: string | null = null;
  private consecutiveUnauthorizedCount: number = 0;
  private readonly MAX_UNAUTHORIZED_RETRIES = 3;

  constructor() {
    this.api = axios.create({
      baseURL: `${API_URL}/api`,
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: (status) => {
        return status < 500; // Resolve for any status code less than 500
      },
    });
    const currentProfileId = localStorage.getItem('currentProfileId');
    if (currentProfileId) {
      this.api.defaults.headers.common['X-Profile-ID'] = currentProfileId;
    }
    this.setupInterceptors();
  }

  static getUnauthorizedCount() {
    return api.consecutiveUnauthorizedCount;
  }

  private setupInterceptors() {
    this.api.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem('authToken');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        if (config.params && config.params.profileId) {
          config.headers['X-Profile-ID'] = config.params.profileId;
          delete config.params.profileId;
        } else if (this.currentProfileId) {
          config.headers['X-Profile-ID'] = this.currentProfileId;
        }

        if (config.params === null) {
          config.params = {};
        }

        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      },
    );
  }

  static setAuthFunctions(functions: AuthFunctions | null) {
    Api.authFunctions = functions;
  }

  private async refreshToken(retries = 3): Promise<string | null> {
    if (Api.authFunctions) {
      for (let i = 0; i < retries; i++) {
        try {
          console.log(`Attempting to refresh token (attempt ${i + 1})`);
          const newToken = await Api.authFunctions.getIdToken(true);
          if (newToken) {
            console.log('Token refreshed successfully');
            localStorage.setItem('authToken', newToken);
            return newToken;
          }
        } catch (error) {
          console.error(`Error refreshing token (attempt ${i + 1}):`, error);
          if (i === retries - 1) {
            console.error('All refresh attempts failed');
            if (Api.authFunctions?.signOut) {
              await Api.authFunctions.signOut();
            }
            throw new Error('Failed to refresh authentication token after multiple attempts');
          }

          // Wait for a short time before retrying
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    }
    console.error('No auth functions available for token refresh');
    return null;
  }

  private async handleResponse(response: AxiosResponse | any): Promise<any> {
    if (response && response.status >= 200 && response.status < 300) {
      this.consecutiveUnauthorizedCount = 0;
      return response.data;
    }

    // Handle 402 Payment Required errors
    if (response.status === 402) {
      throw new Error(response.data?.message || response.data?.error || 'Erro de pagamento');
    }

    // Handle other error responses with error messages
    if (response.status !== 401 && response.data?.error) {
      throw new Error(response.data.error);
    }

    // Handle 401 unauthorized errors (existing code)
    if (response.status === 401) {
      this.consecutiveUnauthorizedCount++;

      // console.log(
      //   `[API] 401 Error #${this.consecutiveUnauthorizedCount} for ${response.config.url}`,
      // );

      if (response.data.code === SearchResponseError.INACTIVE_USER) {
        if (Api.authFunctions?.setUserInactive) {
          Api.authFunctions.setUserInactive();
        }
        throw new Error(SearchResponseError.INACTIVE_USER);
      } else if (response.data.code === SearchResponseError.USER_NOT_FOUND) {
        if (Api.authFunctions?.setUserNotFound) {
          Api.authFunctions.setUserNotFound();
        }
        throw new Error(SearchResponseError.USER_NOT_FOUND);
      } else {
        console.log('Token expired, attempting to refresh token');
        const newToken = await this.handleTokenExpired();
        if (newToken) {
          this.api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
          const retryConfig = {
            ...response.config,
            headers: {
              ...response.config.headers,
              Authorization: `Bearer ${newToken}`,
            },
          };
          const retryResponse = await this.api.request(retryConfig);
          return this.handleResponse(retryResponse);
        }
      }

      if (this.consecutiveUnauthorizedCount >= this.MAX_UNAUTHORIZED_RETRIES) {
        if (Api.authFunctions?.signOut) {
          await Api.authFunctions.signOut();
        }
        throw new Error('Authentication failed after multiple retries');
      }
    }

    throw new Error('Ocorreu um erro na requisição');
  }

  private async handleTokenExpired() {
    try {
      const newToken = await this.refreshToken();
      if (newToken) {
        return newToken;
      } else {
        console.error('Failed to refresh token');
        if (Api.authFunctions?.signOut) {
          await Api.authFunctions.signOut();
        }
      }
    } catch (refreshError) {
      console.error('Token refresh failed:', refreshError);
      if (refreshError instanceof Error) {
        if (
          refreshError.message === 'Failed to refresh authentication token after multiple attempts'
        ) {
          if (Api.authFunctions?.signOut) {
            await Api.authFunctions.signOut();
          }
        } else {
          throw new Error(`Authentication failed: ${refreshError.message}`);
        }
      } else {
        throw new Error('Authentication failed: Unexpected error in token refresh');
      }
    }
  }

  setCurrentProfileId(profileId: string | null) {
    this.currentProfileId = profileId;
    if (profileId) {
      this.api.defaults.headers.common['X-Profile-ID'] = profileId;
    } else {
      delete this.api.defaults.headers.common['X-Profile-ID'];
    }
  }

  async getLicitacoes(
    params: LicitacoesSearchParams,
    profileId?: string,
  ): Promise<LicitacoesSearchResponse> {
    const response = await this.api.post<LicitacoesSearchResponse>('/licitacoes/search', params, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getLicitacao(id: string, profileId?: string): Promise<Licitacao> {
    const response = await this.api.get<Licitacao>(`/licitacoes/${id}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getCompany(
    cnpj: string,
    profileId?: string,
  ): Promise<Company | { error: string; status?: number; details?: string }> {
    const response = await this.api.get<Company>(`/company/check/${cnpj}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async saveUserCompany(
    companyInfo: Company,
    isOnboarding: boolean = false,
    profileId?: string,
  ): Promise<void> {
    const response = await this.api.post(
      '/profile/save',
      { companyInfo, isOnboarding },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async saveUserData(user: FirestoreUser, profileId?: string): Promise<void> {
    const response = await this.api.post(
      '/auth/save-user-data',
      { userData: user },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async createProfile(profile: Partial<UserProfile>): Promise<UserProfile> {
    const response = await this.api.post('/profile/create', profile);
    const result = await this.handleResponse(response);
    return result.profile;
  }

  async updateProfile(profileId: string, updates: Partial<UserProfile>): Promise<boolean> {
    const response = await this.api.post(`/profile/update/${profileId}`, updates);
    const result = await this.handleResponse(response);
    return !!result;
  }

  async deleteProfile(profileId: string): Promise<boolean> {
    const response = await this.api.delete(`/profile/${profileId}`);
    const result = await this.handleResponse(response);
    return !!result;
  }

  async getUserProfiles(): Promise<UserProfile[]> {
    const response = await this.api.get('/profile/profiles');
    const result = await this.handleResponse(response);
    return result;
  }

  async searchUASG(query: string, profileId?: string): Promise<UASG[]> {
    const response = await this.api.get<{ uasgs: UASG[] }>('/company/uasg/search', {
      params: { query, profileId },
    });
    const result = await this.handleResponse(response);
    return result.uasgs as UASG[];
  }

  async searchUASGByCity(municipio: string, profileId?: string): Promise<UASG[]> {
    const response = await this.api.get<{ uasgs: UASG[] }>('/company/uasg/search', {
      params: { municipio, profileId },
    });
    const result = await this.handleResponse(response);
    return result.uasgs as UASG[];
  }

  async saveUserPreferences(
    preferences: UserPreferences,
    isOnboarding: boolean = false,
    profileId?: string,
  ): Promise<void> {
    const response = await this.api.post(
      '/profile/save',
      { preferences, isOnboarding },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async getAllStates(): Promise<any[]> {
    return estados;
  }

  async getCitiesByStateId(id: number): Promise<any> {
    return cidades.filter((cidade) => cidade.codigo_uf === id);
  }

  getCitiesByStateUF(stateUF: string): City[] {
    return cidades.filter((cidade) => cidade.uf === stateUF);
  }

  getCitiesByMunicipioIBGE(municipioIBGE: number): City {
    return cidades.filter((cidade) => cidade.codigo_ibge === municipioIBGE)[0] as City;
  }

  async getCnaes(): Promise<CNAE[]> {
    return cnaes;
  }

  async getRecommended(
    profileId?: string,
  ): Promise<{ recommended: RecommendedLicitacoes[]; total: number }> {
    const response = await this.api.get<{ recommended: RecommendedLicitacoes[]; total: number }>(
      '/recommended',
      {
        params: {
          profileId,
        },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async getRecommendedList(
    page: number = 1,
    licitacoesPerPage: number = 20,
    profileId?: string,
  ): Promise<RecommendedSearchResponse> {
    const response = await this.api.get<RecommendedSearchResponse>('/recommended/licitacoes', {
      params: {
        pagina: page,
        licitacoesPorPagina: licitacoesPerPage,
        profileId,
      },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getUserData(profileId?: string): Promise<FirestoreUser> {
    const response = await this.api.get<FirestoreUser>('/auth/user-data', {
      params: { profileId: profileId || this.currentProfileId },
    });
    return this.handleResponse(response);
  }

  async getLicitacaoDetails(id: string, profileId?: string): Promise<Licitacao> {
    const response = await this.api.get<Licitacao>(`/licitacoes/details/${id}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getFavorites(profileId?: string): Promise<{ favorites: Favorite[]; total: number }> {
    const response = await this.api.get<{ favorites: Favorite[]; total: number }>('/favorites', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async addFavorite(licitacaoId: string, profileId?: string): Promise<Favorite> {
    const response = await this.api.post<{ favorite: Favorite }>(
      '/favorites',
      {
        licitacaoId,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.favorite;
  }

  async removeFavorite(licitacaoId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/favorites/${licitacaoId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async checkFavoriteStatus(licitacaoId: string, profileId?: string): Promise<boolean> {
    const response = await this.api.get<{ isFavorite: boolean }>(`/favorites/${licitacaoId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.isFavorite;
  }

  async getFavoriteLicitacoes(
    page: number = 1,
    licitacoesPerPage: number = 20,
    profileId?: string,
  ): Promise<LicitacoesSearchResponse> {
    const response = await this.api.get<LicitacoesSearchResponse>('/favorites/licitacoes', {
      params: {
        pagina: page,
        licitacoesPorPagina: licitacoesPerPage,
        profileId,
      },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getFollowing(profileId?: string): Promise<{ following: Following[]; total: number }> {
    const response = await this.api.get<{ following: Following[]; total: number }>('/following', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async addFollowing(licitacaoId: string, profileId?: string): Promise<Following> {
    const response = await this.api.post<{ following: Following }>(
      '/following',
      {
        licitacaoId,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.following;
  }

  async removeFollowing(licitacaoId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/following/${licitacaoId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async checkFollowingStatus(licitacaoId: string, profileId?: string): Promise<boolean> {
    const response = await this.api.get<{ isFollowing: boolean }>(`/following/${licitacaoId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.isFollowing;
  }

  async getFollowingLicitacoes(
    page: number = 1,
    licitacoesPerPage: number = 20,
    profileId?: string,
  ): Promise<LicitacoesSearchResponse> {
    const response = await this.api.get<LicitacoesSearchResponse>('/following/licitacoes', {
      params: {
        pagina: page,
        licitacoesPorPagina: licitacoesPerPage,
        profileId,
      },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async addFollowingItem(
    licitacaoId: string,
    item: FollowingItem,
    profileId?: string,
  ): Promise<Following> {
    const response = await this.api.post<{ following: Following }>(
      `/following/${licitacaoId}/items`,
      { item },
      { params: { profileId } },
    );
    const result = await this.handleResponse(response);
    return result.following;
  }

  async removeFollowingItem(
    licitacaoId: string,
    itemId: string,
    profileId?: string,
  ): Promise<Following> {
    const response = await this.api.delete<{ following: Following }>(
      `/following/${licitacaoId}/items/${itemId}`,
      { params: { profileId } },
    );
    const result = await this.handleResponse(response);
    return result.following;
  }

  async updateFollowingItem(
    licitacaoId: string,
    itemId: string,
    updates: Partial<FollowingItem>,
    profileId?: string,
  ): Promise<Following> {
    const response = await this.api.put<{ following: Following }>(
      `/following/${licitacaoId}/items/${itemId}`,
      updates,
      { params: { profileId } },
    );
    const result = await this.handleResponse(response);
    return result.following;
  }

  // Add to Api class
  async addFollowingCustomItem(
    licitacaoId: string,
    customItem: CustomFollowingItem,
    profileId?: string,
  ): Promise<FollowingItem> {
    const response = await this.api.post<{ item: FollowingItem }>(
      `/following/${licitacaoId}/custom-items`,
      customItem,
      { params: { profileId } },
    );
    const result = await this.handleResponse(response);
    return result.item;
  }

  async searchLicitacoesByUASG(uasgCode: string, profileId?: string): Promise<Licitacao[]> {
    const response = await this.api.get<{ licitacoes: Licitacao[] }>(
      `/licitacoes/uasg/${uasgCode}`,
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.licitacoes;
  }

  async searchLicitacoesByItem(
    item: string,
    state: string = '',
    profileId?: string,
  ): Promise<Licitacao[]> {
    const response = await this.api.get<{ licitacoes: Licitacao[] }>('/licitacoes/search-item', {
      params: { itemKeyword: item, state, profileId },
    });
    const result = await this.handleResponse(response);
    return result.licitacoes;
  }

  async getPaymentInfo(profileId?: string): Promise<PaymentInfo> {
    const response = await this.api.get<PaymentInfo>('/payments/info', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async cancelSubscription(
    subscriptionId: string,
    profileId?: string,
  ): Promise<{ status: string; cancelAtPeriodEnd: boolean }> {
    const response = await this.api.post<{ status: string; cancelAtPeriodEnd: boolean }>(
      '/payments/cancel-subscription',
      { subscriptionId },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async setDefaultPaymentMethod(paymentMethodId: string, profileId?: string): Promise<void> {
    const response = await this.api.post(
      '/payments/set-default-method',
      { paymentMethodId },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async reactivateSubscription(
    subscriptionId: string,
    profileId?: string,
  ): Promise<{ success: boolean; subscription: any }> {
    const response = await this.api.post<{ success: boolean; subscription: any }>(
      '/payments/reactivate-subscription',
      { subscriptionId },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async setOnboardingComplete(profileId?: string): Promise<void> {
    const response = await this.api.post('/auth/set-onboarding-complete', null, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async checkUserPlanHistory(profileId?: string): Promise<boolean> {
    const response = await this.api.get<{ hasHadActivePlan: boolean }>(
      '/payments/check-plan-history',
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.hasHadActivePlan;
  }

  async addPaymentMethod(
    paymentMethodId: string,
    profileId?: string,
  ): Promise<{
    success?: boolean;
  }> {
    const response = await this.api.post(
      '/payments/add-method',
      { paymentMethodId },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async getBulletins(date?: string, profileId?: string): Promise<Bulletin[]> {
    const response = await this.api.get<{ bulletins: Bulletin[] }>('/bulletin', {
      params: { date, profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async createBulletin(
    name: string,
    searchParams: LicitacoesSearchParams,
    profileId?: string,
  ): Promise<Bulletin> {
    const response = await this.api.post<{ bulletin: Bulletin }>(
      '/bulletin',
      {
        name,
        searchParams,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.bulletin;
  }

  async getBulletinLicitacoes(
    bulletinId: string,
    date?: string,
    page?: number,
    licitacoesPerPage?: number,
    profileId?: string,
  ): Promise<LicitacoesSearchResponse> {
    const response = await this.api.get<LicitacoesSearchResponse>(
      `/bulletin/${bulletinId}/licitacoes`,
      {
        params: {
          date,
          pagina: page,
          licitacoesPorPagina: licitacoesPerPage,
          profileId,
        },
      },
    );
    const result = await this.handleResponse(response);
    return result;
  }

  async updateBulletin(
    bulletinId: string,
    updates: Partial<{ name: string; description: string; searchParams: LicitacoesSearchParams }>,
    profileId?: string,
  ): Promise<Bulletin> {
    const response = await this.api.put<{ bulletin: Bulletin }>(
      `/bulletin/${bulletinId}`,
      updates,
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.bulletin;
  }

  async deleteBulletin(bulletinId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/bulletin/${bulletinId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  // Kanban Board methods

  async getKanbanBoard(profileId?: string): Promise<KanbanBoard> {
    const response = await this.api.get<{ board: KanbanBoard }>('/kanban/board', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.board;
  }

  async addKanbanLicitacao(
    columnId: string,
    licitacaoId: string,
    extraData: Partial<KanbanLicitacao>,
    profileId?: string,
  ): Promise<KanbanBoard> {
    const response = await this.api.post<{ board: KanbanBoard }>(
      '/kanban/licitacao',
      {
        columnId,
        licitacaoId,
        ...extraData,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.board;
  }

  async updateKanbanLicitacao(
    licitacaoId: string,
    updates: Partial<KanbanLicitacao>,
    profileId?: string,
  ): Promise<KanbanBoard> {
    const response = await this.api.put<{ board: KanbanBoard }>(
      `/kanban/licitacao/${licitacaoId}`,
      updates,
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.board;
  }

  async removeKanbanLicitacao(licitacaoId: string, profileId?: string): Promise<KanbanBoard> {
    const response = await this.api.delete<{ board: KanbanBoard }>(
      `/kanban/licitacao/${licitacaoId}`,
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.board;
  }

  async moveKanbanLicitacao(
    licitacaoId: string,
    newColumnId: string,
    newPosition: number,
    profileId?: string,
  ): Promise<KanbanBoard> {
    const response = await this.api.post<{ board: KanbanBoard }>(
      `/kanban/licitacao/${licitacaoId}/move`,
      {
        newColumnId,
        newPosition,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.board;
  }

  async addKanbanLicitacoes(
    columnId: string,
    licitacaoIds: string[],
    extraData: Partial<KanbanLicitacao>,
    profileId?: string,
  ): Promise<KanbanBoard> {
    const response = await this.api.post<{ board: KanbanBoard }>(
      '/kanban/licitacoes',
      {
        columnId,
        licitacaoIds,
        ...extraData,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.board;
  }

  async getDocuments(profileId?: string, section?: string): Promise<Document[]> {
    const response = await this.api.get<{ documents: Document[] }>('/documents', {
      params: { profileId, section },
    });
    const result = await this.handleResponse(response);
    return result.documents;
  }

  async uploadDocument(
    file: File,
    type: string,
    profileId?: string,
    section?: string,
  ): Promise<Document> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);
    if (section) {
      formData.append('section', section);
    }

    const response = await this.api.post<{ document: Document }>('/documents/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.document;
  }

  async deleteDocument(documentId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/documents/${documentId}`, {
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async updateDocument(
    documentId: string,
    updates: Partial<Document>,
    profileId?: string,
  ): Promise<Document> {
    const response = await this.api.put<{ document: Document }>(
      `/documents/${documentId}`,
      updates,
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.document;
  }

  async downloadDocument(documentId: string, childId?: string, profileId?: string): Promise<Blob> {
    const url = childId
      ? `/documents/${documentId}/children/${childId}/download`
      : `/documents/${documentId}/download`;

    const response = await this.api.get(url, {
      responseType: 'blob',
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async sendFeedback(feedback: string, profileId?: string): Promise<void> {
    const response = await this.api.post(
      '/auth/feedback',
      { feedback },
      {
        params: { profileId },
      },
    );
    return this.handleResponse(response);
  }
  async askAIQuestion(licitacaoId: string, question: string, profileId?: string): Promise<string> {
    const response = await this.api.post<{ response: string }>(
      '/ai-chat/ask',
      { licitacaoId, question },
      { params: { profileId } },
    );
    const result = await this.handleResponse(response);
    return result.response;
  }

  async extractLicitacaoDocuments(
    licitacaoId: string,
    profileId?: string,
  ): Promise<{
    documentsRequired: Record<string, boolean>;
    seguroGarantia: boolean;
  }> {
    const response = await this.api.post<{
      documentsRequired: Record<string, boolean>;
      seguroGarantia: boolean;
    }>('/ai-chat/extract-documents', { licitacaoId }, { params: { profileId } });
    const result = await this.handleResponse(response);
    return result;
  }

  async createSubscription(
    planId: string,
    duration: string,
    paymentMethodId: string,
  ): Promise<{
    success?: boolean;
    error?: string;
    status?: number;
    message?: string;
    subscriptionId?: string;
  }> {
    const response = await this.api.post('/payments/subscribe-with-method', {
      planId,
      durationId: duration,
      paymentMethodId,
    });
    const result = await this.handleResponse(response);
    return result;
  }
  async downloadAllDocuments(section?: string, documentIds?: string[]): Promise<Blob> {
    const response = await this.api.post<Blob>(
      '/documents/download-all',
      { documentIds },
      {
        params: { section },
        responseType: 'blob',
      },
    );
    return this.handleResponse(response);
  }

  async getSavedSearches(profileId?: string): Promise<SavedSearch[]> {
    const response = await this.api.get<{ savedSearches: SavedSearch[] }>('/saved-searches', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result.savedSearches;
  }

  async addSavedSearch(
    name: string,
    params: LicitacoesSearchParams,
    profileId?: string,
  ): Promise<SavedSearch> {
    const response = await this.api.post<{ savedSearch: SavedSearch }>(
      '/saved-searches',
      { name, params },
      { params: { profileId } },
    );
    const result = await this.handleResponse(response);
    return result.savedSearch;
  }

  async removeSavedSearch(id: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/saved-searches/${id}`, {
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async updateSavedSearch(
    id: string,
    name: string,
    params: LicitacoesSearchParams,
    profileId?: string,
  ): Promise<SavedSearch> {
    const response = await this.api.put<{ savedSearch: SavedSearch }>(
      `/saved-searches/${id}`,
      { name, params },
      { params: { profileId } },
    );
    const result = await this.handleResponse(response);
    return result.savedSearch;
  }

  async getNotifications(
    page: number = 1,
    perPage: number = 20,
    profileId?: string,
  ): Promise<{
    notifications: Notification[];
    unreadCount: number;
    total: number;
    totalPages: number;
  }> {
    const response = await this.api.get<{
      notifications: Notification[];
      unreadCount: number;
      total: number;
      totalPages: number;
    }>('/notifications', {
      params: {
        pagina: page,
        notificacoesPorPagina: perPage,
        profileId,
      },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async markNotificationAsRead(notificationId: string, profileId?: string): Promise<void> {
    const response = await this.api.post(
      `/notifications/${notificationId}/read`,
      {},
      {
        params: { profileId },
      },
    );
    return this.handleResponse(response);
  }

  async markAllNotificationsAsRead(profileId?: string): Promise<void> {
    const response = await this.api.post(
      '/notifications/read-all',
      {},
      {
        params: { profileId },
      },
    );
    return this.handleResponse(response);
  }

  async deleteNotification(notificationId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/notifications/${notificationId}`, {
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async updateFollowingImportance(
    licitacaoId: string,
    importance: FollowingImportance,
    profileId?: string,
  ): Promise<Following> {
    const response = await this.api.put<{ following: Following }>(
      `/following/${licitacaoId}/importance`,
      { importance },
      { params: { profileId } },
    );
    const result = await this.handleResponse(response);
    return result.following;
  }

  async uploadChildDocuments(
    parentId: string,
    files: File[],
    profileId?: string,
  ): Promise<ChildDocument[]> {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    const response = await this.api.post<{ documents: ChildDocument[] }>(
      `/documents/${parentId}/children`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.documents;
  }

  async deleteChildDocument(parentId: string, childId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/documents/${parentId}/children/${childId}`, {
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async updateChildDocument(
    parentId: string,
    childId: string,
    updates: Partial<ChildDocument>,
    profileId?: string,
  ): Promise<ChildDocument> {
    const response = await this.api.put<{ document: ChildDocument }>(
      `/documents/${parentId}/children/${childId}`,
      updates,
      { params: { profileId } },
    );
    const result = await this.handleResponse(response);
    return result.document;
  }

  async getDailyLicitacoesCount(days: number = 30): Promise<DailyCount[]> {
    const response = await this.api.get<DailyCount[]>('/stats/daily-count', {
      params: { days },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getTags(profileId?: string): Promise<{ tags: Tag[]; total: number }> {
    const response = await this.api.get<{ tags: Tag[]; total: number }>('/tags', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async addTag(licitacaoId: string, type: TagType, profileId?: string): Promise<Tag> {
    const response = await this.api.post<{ tag: Tag }>(
      '/tags',
      {
        licitacaoId,
        type,
      },
      {
        params: { profileId },
      },
    );
    const result = await this.handleResponse(response);
    return result.tag;
  }

  async removeTag(licitacaoId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/tags/${licitacaoId}`, {
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async getTag(licitacaoId: string, profileId?: string): Promise<{ type: TagType | null }> {
    const response = await this.api.get<{ type: TagType | null }>(`/tags/${licitacaoId}`, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async upsertAnnotation(licitacaoId: string, content: string, profileId?: string): Promise<void> {
    const response = await this.api.post(
      '/annotations',
      {
        licitacaoId,
        content,
      },
      {
        params: { profileId },
      },
    );
    return this.handleResponse(response);
  }

  async getAnnotation(licitacaoId: string, profileId?: string): Promise<Annotation | null> {
    const response = await this.api.get(`/annotations/${licitacaoId}`, {
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async getAnnotations(profileId?: string): Promise<{ annotations: Annotation[]; total: number }> {
    const response = await this.api.get('/annotations', {
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async removeAnnotation(licitacaoId: string, profileId?: string): Promise<void> {
    const response = await this.api.delete(`/annotations/${licitacaoId}`, {
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async uploadCompanyLogo(formData: FormData): Promise<{ url: string }> {
    const response = await this.api.post('/profile/upload-logo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async removeCompanyLogo(): Promise<void> {
    const response = await this.api.delete('/profile/remove-logo');
    return this.handleResponse(response);
  }

  async uploadUserProfileImage(formData: FormData, profileId?: string): Promise<{ url: string }> {
    const response = await this.api.post('/profile/upload-user-image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async removeUserProfileImage(profileId?: string): Promise<void> {
    const response = await this.api.delete('/profile/remove-user-image', {
      params: { profileId },
    });
    return this.handleResponse(response);
  }

  async getPreferences(profileId?: string): Promise<Preferences | null> {
    const response = await this.api.get<Preferences>('/preferences', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async updatePreferences(
    preferences: Partial<Preferences>,
    profileId?: string,
  ): Promise<Preferences> {
    const response = await this.api.put<Preferences>('/preferences', preferences, {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getRealtimeRecommended(
    page: number = 1,
    licitacoesPerPage: number = 20,
    searchQueryName?: string,
    profileId?: string,
  ): Promise<RecommendedSearchResponse> {
    const response = await this.api.get<RecommendedSearchResponse>('/recommended/realtime', {
      params: {
        ...(searchQueryName && { searchQueryName }),
        pagina: page,
        licitacoesPorPagina: licitacoesPerPage,
        profileId,
      },
    });
    const result = await this.handleResponse(response);
    return result;
  }

  async getAiProfile(profileId?: string): Promise<AiProfile | null> {
    const response = await this.api.get('/recommended/profile', {
      params: { profileId },
    });
    const result = await this.handleResponse(response);
    return result;
  }
}

const api = new Api();
export { Api as ApiService };
export default api;
