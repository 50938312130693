import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import RecommendIcon from '@mui/icons-material/Recommend';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { useRecommendedStore } from '@store/recommendedStore';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RecommendedCard from './RecommendedCard';
import RecommendedCardSkeleton from './RecommendedCardSkeleton';

interface RecommendationsProps {
  title?: string;
}

const Recommendations: React.FC<RecommendationsProps> = ({
  title = 'Recomendações personalizadas',
}) => {
  const {
    recommendedRealtimeList,
    isLoading,
    error,
    itemsPerPage,
    fetchRealtimeRecommended,
    totalRealtimeRecommended,
  } = useRecommendedStore();

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const { trackEvent } = useAnalytics();
  const { showNotification } = useNotification();

  useEffect(() => {
    fetchRealtimeRecommended(1, undefined, true);
  }, [fetchRealtimeRecommended]);

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
    }
  }, [error, showNotification]);

  useEffect(() => {
    trackEvent('realtime_recommendations_view', {
      total_recommendations: totalRealtimeRecommended,
      items_per_page: itemsPerPage,
    });
  }, [trackEvent, totalRealtimeRecommended, itemsPerPage]);

  const handleScroll = useCallback(() => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  }, []);

  useEffect(() => {
    handleScroll();
    window.addEventListener('resize', handleScroll);
    return () => window.removeEventListener('resize', handleScroll);
  }, []);

  const scroll = useCallback((direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const cardWidth = scrollContainerRef.current.clientWidth / 4;
      const scrollAmount = cardWidth * 4;
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  }, []);

  const titleContent = useMemo(
    () => (
      <Box display="flex" alignItems="center" gap={1} mb={2}>
        <RecommendIcon color="primary" />
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
      </Box>
    ),
    [title],
  );

  if (isLoading && !recommendedRealtimeList.length) {
    return (
      <>
        {titleContent}
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            gap: 2,
            py: 1,
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
            '& > *': {
              flex: '0 0 auto',
              width: 'calc(25% - 12px)',
            },
          }}
        >
          {[...Array(4)].map((_, index) => (
            <RecommendedCardSkeleton key={index} />
          ))}
        </Box>
      </>
    );
  }

  if (!recommendedRealtimeList.length) {
    return (
      <>
        {titleContent}
        <Paper sx={{ p: 2, backgroundColor: 'background.paper', borderRadius: 2 }}>
          <Box display="flex" alignItems="center">
            <InfoIcon color="info" sx={{ mr: 1 }} />
            <Typography variant="body2" color="text.secondary">
              Nenhuma recomendação encontrada no momento
            </Typography>
          </Box>
        </Paper>
      </>
    );
  }

  return (
    <>
      {titleContent}
      <Box sx={{ position: 'relative' }}>
        <Box
          ref={scrollContainerRef}
          onScroll={handleScroll}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            gap: 2,
            py: 1,
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
            '& > *': {
              flex: '0 0 auto',
              width: 'calc(25% - 12px)',
            },
          }}
        >
          {recommendedRealtimeList.map((licitacao, index) => (
            <RecommendedCard key={licitacao.id} licitacao={licitacao} isFirst={index === 0} />
          ))}
        </Box>

        {showLeftArrow && (
          <IconButton
            onClick={() => scroll('left')}
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
              backgroundColor: 'background.paper',
              boxShadow: 1,
              '&:hover': { backgroundColor: 'action.hover' },
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}

        {showRightArrow && (
          <IconButton
            onClick={() => scroll('right')}
            sx={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1,
              backgroundColor: 'background.paper',
              boxShadow: 1,
              '&:hover': { backgroundColor: 'action.hover' },
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export default React.memo(Recommendations);
