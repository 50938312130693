import { Search as SearchIcon } from '@mui/icons-material';
import { Autocomplete, Box, InputAdornment, TextField, Typography } from '@mui/material';
import { FollowingItem } from '@types';
import { formatCurrency } from '@utils';
import React, { useMemo } from 'react';

interface FollowingItemsAutocompleteProps {
  items: FollowingItem[];
  selectedItems: FollowingItem[];
  onItemSelect: (item: FollowingItem) => void;
}

// Simple hash function for items
const createItemId = (item: FollowingItem) => {
  const str = `${item.numeroItem}-${item.descricao}-${item.quantidade}`;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return `item-${Math.abs(hash)}`;
};

export const FollowingItemsAutocomplete: React.FC<FollowingItemsAutocompleteProps> = ({
  items,
  selectedItems,
  onItemSelect,
}) => {
  const availableItems = useMemo(() => {
    // Add IDs to items if they don't have one
    const itemsWithIds = items.map((item) => ({
      ...item,
      id: item.id || createItemId(item),
    }));

    // Get selected item IDs for faster lookup
    const selectedIds = new Set(selectedItems.map((item) => item.id));

    // Filter out selected items
    return itemsWithIds.filter((item) => !selectedIds.has(item.id));
  }, [items, selectedItems]);

  return (
    <Autocomplete
      options={availableItems}
      getOptionLabel={(item) => `${item.numeroItem} - ${item.descricao}`}
      filterOptions={(options, state) => {
        if (!state.inputValue) return options;
        const searchTerm = state.inputValue.toLowerCase();
        return options.filter((option) => option.descricao.toLowerCase().includes(searchTerm));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Escolha um item da lista para adicionar"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, item) => (
        <li {...props}>
          <Box
            sx={{
              width: '100%',
              minWidth: 0,
              display: 'flex',
              flexDirection: 'column',
              px: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                width: '100%',
              }}
            >
              {item.numeroItem} - {item.descricao.slice(0, 100)}
              {item.descricao.length > 100 ? '...' : ''}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                color: 'text.secondary',
                fontSize: '0.875rem',
                gap: 2,
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  flex: '0 1 auto',
                  minWidth: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                Quantidade: {item.quantidade} {item.unidadeMedida}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  flex: '0 0 auto', // Prevents price from shrinking
                }}
              >
                Valor estimado: {formatCurrency(item.valorUnitarioEstimado)}
              </Typography>
            </Box>
          </Box>
        </li>
      )}
      onChange={(_, value) => {
        if (value) onItemSelect(value);
      }}
      fullWidth
      disableClearable
      openOnFocus
      noOptionsText="Nenhum item disponível"
      value={undefined}
      key={`${items.length}-${selectedItems.length}`}
      slotProps={{
        popper: {
          sx: {
            width: 'fit-content !important',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: 'none',
            },
          },
        },
        paper: {
          elevation: 8,
          sx: {
            overflow: 'hidden',
            maxWidth: '100%',
          },
        },
        listbox: {
          sx: {
            maxHeight: '400px',
            width: '100%',
            '& li': {
              borderBottom: '1px solid',
              borderColor: 'divider',
              '&:last-child': {
                borderBottom: 'none',
              },
              maxWidth: '100%',
              px: 2,
            },
          },
        },
      }}
    />
  );
};
