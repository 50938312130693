// import { Box, Typography } from '@mui/material';
// import { ReactNode } from 'react';
import { ComprasnetLicitacao, Licitacao, Portal } from '@types';
import { getComprasNetId } from '@utils';
import { BasePortalService } from './BasePortal';

export class ComprasnetPortalService extends BasePortalService {
  name = 'Comprasnet';
  logo = '/images/portals/comprasnet.png';
  portal = Portal.ComprasGovernamentais;
  portalData: ComprasnetLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as ComprasnetLicitacao;
  }

  private getComprasNetId(): string | null {
    return getComprasNetId(this.licitacao);
  }

  getPortalUrl(): string | null {
    const comprasNetId = this.getComprasNetId();
    return comprasNetId
      ? `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/public/landing?destino=acompanhamento-compra&compra=${comprasNetId}`
      : this.licitacao.linkExterno;
  }

  getApplyUrl(): string | null {
    const comprasNetId = this.getComprasNetId();
    if (!comprasNetId) return '#';

    return !this.isPastAbertura()
      ? `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/seguro/fornecedor/cadastro-propostas?compra=${comprasNetId}`
      : null;
  }

  getFollowUpUrl(): string | null {
    const comprasNetId = this.getComprasNetId();
    return comprasNetId && this.isPastAbertura()
      ? `https://cnetmobile.estaleiro.serpro.gov.br/comprasnet-web/seguro/fornecedor/compras`
      : null;
  }

  getLoginUrl(): string {
    return 'https://www.gov.br/compras/pt-br';
  }

  getRegisterUrl(): string {
    return 'https://www.gov.br/compras/pt-br/fornecedor';
  }

  override getEditalUrl(): string | null {
    const url = super.getEditalUrl();
    if (!url) return null;

    if (url.includes('?captcha=1')) {
      const comprasNetId = this.getComprasNetId();
      if (!comprasNetId) return url;

      return `${this.getLinkBaseUrl()}?url=${encodeURIComponent(url)}&comprasnetId=${comprasNetId}`;
    }

    return url;
  }

  // protected getLoginHelpText(): ReactNode {
  //   return (
  //     <Box>
  //       <Typography variant="body1" gutterBottom>
  //         Para participar desta licitação no Comprasnet, você precisa:
  //       </Typography>
  //       <Typography component="ol">
  //         <li>Estar cadastrado no SICAF (Sistema de Cadastramento Unificado de Fornecedores)</li>
  //         <li>Ter certificado digital válido</li>
  //         <li>Possuir credenciamento no portal Comprasnet</li>
  //       </Typography>
  //       <Box sx={{ mt: 2, bgcolor: 'info.lighter', p: 2, borderRadius: 1 }}>
  //         <Typography variant="body2" color="info.main">
  //           UASG: {this.portalData.uasg} - {this.portalData.orgao}
  //         </Typography>
  //       </Box>
  //     </Box>
  //   );
  // }
}
