export const formatCNPJ = (cnpj: string): string => {
  const cleaned = cnpj.replace(/\D/g, '');
  if (cleaned.length !== 14) return cleaned;
  return cleaned.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const CNPJ_MASK = '99.999.999/9999-99';
export const CPF_MASK = '999.999.999-99';
export const RG_MASK = '99.999.999-9';
export const PHONE_MASK = '(99) 99999-9999';
export const CEP_MASK = '99999-999';
