import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { ComprasbrLicitacao } from '../../types';

interface ComprasbrDetailsProps {
  details: ComprasbrLicitacao;
}

const ComprasbrDetails: React.FC<ComprasbrDetailsProps> = ({ details }) => {
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  const getLocalString = (orgao: ComprasbrLicitacao['orgao']) => {
    return `${orgao.nome}, ${orgao.municipio.descricao} - ${orgao.municipio.uf}`;
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary">
          Informações adicionais do portal
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Número do Processo:</strong> {details.numProcesso}
            </Typography>
            <Typography>
              <strong>Número do Edital:</strong> {details.numeroEdital}
            </Typography>
            <Typography>
              <strong>Status:</strong> {details.status}
            </Typography>
            <Typography>
              <strong>Local:</strong> {getLocalString(details.orgao)}
            </Typography>
            <Typography>
              <strong>Modalidade:</strong> {details.modalidade}
            </Typography>
            <Typography>
              <strong>Fase:</strong> {details.fase}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Tipo de Licitação:</strong> {details.tipoLicitacao}
            </Typography>
            <Typography>
              <strong>Tipo de Disputa:</strong> {details.tipoDisputa}
            </Typography>
            <Typography>
              <strong>Modo de Disputa:</strong> {details.modoDisputa}
            </Typography>
            <Typography>
              <strong>Pregoeiro:</strong> {details.pregoeiro}
            </Typography>
            <Typography>
              <strong>Legislação:</strong> {details.legislacao}
            </Typography>
            <Typography>
              <strong>Ano da Compra:</strong> {details.anoCompra}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography>
              <strong>Objeto:</strong> {details.objeto}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Início Envio Proposta:</strong> {formatDate(details.dataIniEnvioProposta)}
            </Typography>
            <Typography>
              <strong>Fim Envio Proposta:</strong> {formatDate(details.dataFimEnvioProposta)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Abertura da Licitação:</strong> {formatDate(details.dataAbertura)}
            </Typography>
            <Typography>
              <strong>Data Limite Esclarecimento/Impugnação:</strong>{' '}
              {formatDate(details.dataLimiteEsclarecimentoImpugnacao)}
            </Typography>
          </Grid>
          {details.amparoLegal && (
            <Grid item xs={12}>
              <Typography>
                <strong>Amparo Legal:</strong> {details.amparoLegal.lei}
              </Typography>
            </Grid>
          )}
          {details.dataPublicacao && (
            <Grid item xs={12}>
              <Typography>
                <strong>Data de Publicação:</strong> {formatDate(details.dataPublicacao)}
              </Typography>
            </Grid>
          )}
          {details.sequencialCompraPublica && (
            <Grid item xs={12}>
              <Typography>
                <strong>Sequencial Compra Pública:</strong> {details.sequencialCompraPublica}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>
              <strong>PNCP:</strong> {details.pncp ? 'Sim' : 'Não'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ComprasbrDetails;
