import LoadMoreTrigger from '@components/Common/LoadMoreTrigger';
import {
  Circle as CircleIcon,
  Delete as DeleteIcon,
  Notifications as NotificationsIcon,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { useNotificationStore } from '@store/notificationStore';
import { formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const POLLING_INTERVAL = 5 * 60 * 1000; // 5 minutes in milliseconds

const NotificationMenu: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const pollingInterval = useRef<NodeJS.Timeout | null>(null);
  const lastFetchTimestamp = useRef<number>(0);
  const {
    notifications,
    unreadCount,
    fetchNotifications,
    markAsRead,
    markAllAsRead,
    deleteNotification,
    currentPage,
    hasMore,
    isLoading,
  } = useNotificationStore();

  useEffect(() => {
    const initializeNotifications = async () => {
      try {
        await fetchNotifications(1);
        lastFetchTimestamp.current = Date.now();

        pollingInterval.current = setInterval(() => {
          fetchNotifications(1);
          lastFetchTimestamp.current = Date.now();
        }, POLLING_INTERVAL);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
        if (pollingInterval.current) {
          clearInterval(pollingInterval.current);
        }
      }
    };

    initializeNotifications();

    return () => {
      if (pollingInterval.current) {
        clearInterval(pollingInterval.current);
      }
    };
  }, [fetchNotifications]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Clear interval when tab is hidden
        if (pollingInterval.current) {
          clearInterval(pollingInterval.current);
          pollingInterval.current = null;
        }
      } else {
        // Resume polling when tab becomes visible
        const timeSinceLastFetch = Date.now() - lastFetchTimestamp.current;

        if (!pollingInterval.current) {
          if (timeSinceLastFetch >= POLLING_INTERVAL) {
            fetchNotifications();
            lastFetchTimestamp.current = Date.now();
          }

          pollingInterval.current = setInterval(() => {
            fetchNotifications();
            lastFetchTimestamp.current = Date.now();
          }, POLLING_INTERVAL);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fetchNotifications]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (
    notificationId: string,
    data?: { licitacaoId?: string; url?: string },
  ) => {
    await markAsRead(notificationId);
    handleMenuClose();

    if (data?.url) {
      navigate(data.url);
    } else if (data?.licitacaoId) {
      navigate(`/licitacao/${data.licitacaoId}`);
    }
  };

  const handleMarkAllAsRead = async (event: React.MouseEvent) => {
    event.stopPropagation();
    await markAllAsRead();
  };

  const handleDelete = async (event: React.MouseEvent, notificationId: string) => {
    event.stopPropagation();
    await deleteNotification(notificationId);
  };

  const handleLoadMore = useCallback(() => {
    if (!isLoading && hasMore) {
      fetchNotifications(currentPage + 1);
    }
  }, [fetchNotifications, currentPage, isLoading, hasMore]);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleMenuOpen}
        sx={{
          color: theme.palette.text.primary,
        }}
      >
        <Badge badgeContent={unreadCount || 0} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            maxHeight: 400,
            width: '360px',
          },
        }}
      >
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Notificações</Typography>
          {(unreadCount || 0) > 0 && (
            <Button size="small" onClick={handleMarkAllAsRead}>
              Marcar todas como lidas
            </Button>
          )}
        </Box>
        <Divider />
        <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
          {!notifications?.length ? (
            <MenuItem disabled>
              <Typography variant="body2">Nenhuma notificação</Typography>
            </MenuItem>
          ) : (
            <>
              {notifications.map((notification) => (
                <MenuItem
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification.id, notification.data)}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 1,
                    py: 1,
                    px: 2,
                    maxWidth: '360px',
                  }}
                >
                  {!notification.read && (
                    <CircleIcon
                      sx={{
                        fontSize: 8,
                        color: theme.palette.primary.main,
                        mt: 1,
                        flexShrink: 0,
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      flex: 1,
                      minWidth: 0, // Important for text truncation
                      maxWidth: '280px', // Leave space for delete button
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {notification.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        wordBreak: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                      }}
                    >
                      {notification.message}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {formatDistanceToNow(new Date(notification.createdAt), {
                        addSuffix: true,
                        locale: ptBR,
                      })}
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={(e) => handleDelete(e, notification.id)}
                    sx={{
                      mt: -0.5,
                      flexShrink: 0,
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </MenuItem>
              ))}
              <LoadMoreTrigger
                onLoadMore={handleLoadMore}
                hasMore={Boolean(hasMore)}
                debounceTime={1000}
              />
            </>
          )}
        </Box>
      </Menu>
    </>
  );
};

export default NotificationMenu;
