import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface FeatureLimitAlertProps {
  title: string;
  message: string;
  variant?: 'info' | 'warning';
  showUpgradeButton?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  usage?: number;
  limit?: number;
}

const FeatureLimitAlert: React.FC<FeatureLimitAlertProps> = ({
  title,
  message,
  variant = 'info',
  showUpgradeButton = true,
  onClick,
  usage,
  limit,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleUpgradeClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClick?.(e);
      navigate('/configuracoes/pagamento');
    },
    [onClick, navigate],
  );

  return (
    <Stack
      spacing={1}
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 1,
        bgcolor: isDarkMode ? `${variant}.darker` : `${variant}.lighter`,
        borderLeft: 4,
        borderColor: theme.palette[variant].main,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <InfoIcon sx={{ color: theme.palette[variant].main }} />
        <Typography
          variant="subtitle2"
          sx={{ color: isDarkMode ? `${variant}.light` : `${variant}.dark` }}
        >
          {title}
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Box>
          <Typography variant="body2" color="text.primary">
            {message}
          </Typography>
          {usage !== undefined && limit !== undefined && (
            <Typography variant="caption" color="text.secondary">
              {usage} de {limit} boletins utilizados
            </Typography>
          )}
        </Box>
        {showUpgradeButton && (
          <Button
            variant="contained"
            color={variant}
            size="small"
            onClick={handleUpgradeClick}
            sx={{
              color: 'common.white',
              '&:hover': {
                bgcolor: theme.palette[variant].dark,
              },
            }}
          >
            Fazer Upgrade
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default React.memo(FeatureLimitAlert);
