import BulletinCard from '@components/Bulletin/BulletinCard';
import BulletinCardSkeleton from '@components/Bulletin/BulletinCardSkeleton';
import BulletinModal from '@components/Bulletin/BulletinModal';
import FeatureLimitAlert from '@components/Common/FeatureLimitAlert';
import ListControls from '@components/Layout/ListControls';
import Pagination, { PaginationInfo } from '@components/Layout/Pagination';
import LicitacaoCard from '@components/Search/LicitacaoCard';
import LicitacaoCardSkeleton from '@components/Search/LicitacaoCardSkeleton';
import LicitacaoColumnCard from '@components/Search/LicitacaoColumnCard';
import LicitacaoColumnCardSkeleton from '@components/Search/LicitacaoColumnCardSkeleton';
import { useNotification } from '@contexts/NotificationContext';
import { useProfile } from '@contexts/ProfileContext';
import { useAnalytics } from '@hooks/useAnalytics';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useBulletinStore } from '@store/bulletinStore';
import { useProfileStore } from '@store/profileStore';
import { Bulletin as BulletinType, FeatureId, LicitacoesSearchParams } from '@types';
import { TIMEZONE } from '@utils';
import { scrollToTop } from '@utils/scroll';
import { format as formatDate, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const Bulletin: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    bulletins,
    currentBulletinLicitacoes,
    currentPage,
    totalPages,
    itemsPerPage,
    setItemsPerPage,
    viewFormat,
    setViewFormat,
    isLoadingBulletins,
    isLoadingLicitacoes,
    error,
    fetchBulletins,
    fetchBulletinLicitacoes,
    createBulletin,
    updateBulletin,
    setCurrentPage,
    deleteBulletin,
    setCurrentBulletin,
  } = useBulletinStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showNotification } = useNotification();

  // Initialize states from URL params
  const initialDate =
    searchParams.get('date') || format(new Date(), 'yyyy-MM-dd', { timeZone: TIMEZONE });

  const [selectedBulletinId, setSelectedBulletinId] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>(initialDate);
  const { userPreferences } = useProfileStore();
  const { currentProfileId } = useProfile();

  const [dialogProps, setDialogProps] = useState<{
    open: boolean;
    usePreferences: boolean;
    editingBulletin?: BulletinType;
  }>({
    open: false,
    usePreferences: false,
    editingBulletin: undefined,
  });

  const [deletingBulletin, setDeletingBulletin] = useState<BulletinType | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuBulletinId, setMenuBulletinId] = useState<string | null>(null);
  const [anchorElInfo, setAnchorElInfo] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();
  const { bulletinId } = useParams<{ bulletinId?: string }>();

  const { trackEvent } = useAnalytics();

  const { checkFeatureAccess } = useProfileStore();
  const featureAccess = useMemo(
    () => checkFeatureAccess(FeatureId.BULLETINS, bulletins.length),
    [checkFeatureAccess, bulletins.length],
  );

  // Add ref to track previous bulletinId
  const prevBulletinIdRef = useRef<string | null>(null);

  const loadBulletinLicitacoes = useCallback(
    async (bulletinId: string, page: number, forceRefresh: boolean = false) => {
      try {
        if (
          bulletinId !== prevBulletinIdRef.current || // Check if bulletin changed
          page !== currentPage ||
          forceRefresh
        ) {
          await fetchBulletinLicitacoes(bulletinId, selectedDate, page);
          prevBulletinIdRef.current = bulletinId;
          setCurrentPage(page);
        }
      } catch (error) {
        showNotification('Erro ao carregar licitações do boletim', 'error');
      }
    },
    [fetchBulletinLicitacoes, selectedDate, setCurrentPage, currentPage],
  );

  // Main effect for bulletin initialization
  useEffect(() => {
    if (bulletinId) {
      setSelectedBulletinId(bulletinId);

      // Only update URL if params are missing
      if (!searchParams.has('date')) {
        searchParams.set('date', selectedDate);
        setSearchParams(searchParams);
      }

      if (!searchParams.has('pagina')) {
        searchParams.set('pagina', '1');
        setSearchParams(searchParams);
      }
    } else {
      setSelectedBulletinId(null);
    }
  }, [bulletinId, searchParams, selectedDate]);

  // Single effect for loading bulletins and licitacoes
  useEffect(() => {
    if (currentProfileId && selectedBulletinId) {
      const pageParam = parseInt(searchParams.get('pagina') || '1', 10);
      loadBulletinLicitacoes(selectedBulletinId, pageParam);
    } else if (currentProfileId) {
      fetchBulletins(selectedDate);
    }
  }, [currentProfileId, selectedBulletinId, searchParams, selectedDate]);

  const handleBulletinClick = useCallback(
    (id: string) => {
      const selectedBulletin = bulletins.find((b) => b?.id === id);
      if (selectedBulletin) {
        setCurrentBulletin(selectedBulletin);
        setSelectedBulletinId(id);
        navigate(`/boletins/${id}?${searchParams.toString()}`);
      }
    },
    [bulletins, navigate, searchParams, setCurrentBulletin],
  );

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>, bulletinId: string) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuBulletinId(bulletinId);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setMenuBulletinId(null);
  }, []);

  const handleEditClick = useCallback(
    (bulletin: BulletinType) => {
      setDialogProps({ open: true, usePreferences: false, editingBulletin: bulletin });
      handleMenuClose();
    },
    [handleMenuClose],
  );

  const handleDeleteClick = useCallback(
    (bulletin: BulletinType) => {
      handleDeleteBulletin(bulletin);
      handleMenuClose();
    },
    [handleMenuClose],
  );

  const handleRefresh = useCallback(() => {
    fetchBulletins(selectedDate);
    if (selectedBulletinId) {
      loadBulletinLicitacoes(selectedBulletinId, currentPage, true);
    }
  }, [fetchBulletins, selectedBulletinId, loadBulletinLicitacoes, selectedDate, currentPage]);

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
      trackEvent('bulletin_error', {
        error_message: error,
        bulletin_id: selectedBulletinId,
        date: selectedDate,
      });
    }
  }, [error]);

  const handleCreateBulletin = useCallback(
    async (name: string, searchParams: LicitacoesSearchParams) => {
      try {
        await createBulletin(name, searchParams);
        if (!error) {
          setDialogProps({ open: false, usePreferences: false, editingBulletin: undefined });
          fetchBulletins(selectedDate);
          trackEvent('bulletin_create', {
            bulletin_name: name,
            has_filters: Boolean(searchParams),
          });
          showNotification('Boletim criado com sucesso', 'success');
        }
      } catch (error) {
        showNotification('Erro ao criar boletim', 'error');
        trackEvent('bulletin_create_error', {
          error_message: error instanceof Error ? error.message : String(error),
        });
      }
    },
    [createBulletin, fetchBulletins, selectedDate, trackEvent],
  );

  const handleEditBulletin = useCallback(
    async (bulletinId: string, name: string, updatedSearchParams: LicitacoesSearchParams) => {
      try {
        await updateBulletin(bulletinId, { name, searchParams: updatedSearchParams });
        setDialogProps({ open: false, usePreferences: false, editingBulletin: undefined });

        // Reload bulletins
        await fetchBulletins(selectedDate);

        // If this is the currently selected bulletin, reload its licitações
        if (selectedBulletinId === bulletinId) {
          setCurrentPage(1);
          searchParams.set('pagina', '1');
          setSearchParams(searchParams);
          await loadBulletinLicitacoes(bulletinId, 1, true); // Force refresh
        }

        showNotification('Boletim atualizado com sucesso', 'success');
      } catch (error) {
        showNotification('Erro ao atualizar boletim', 'error');
        trackEvent('bulletin_edit_error', {
          error_message: error instanceof Error ? error.message : String(error),
          bulletin_id: bulletinId,
        });
      }
    },
    [updateBulletin, trackEvent, showNotification],
  );

  const handleCreateFromPreferences = useCallback(() => {
    trackEvent('bulletin_create_from_preferences', {
      has_preferences: Boolean(userPreferences),
    });
    if (!userPreferences) {
      trackEvent('bulletin_create_from_preferences_redirect', {
        reason: 'no_preferences',
      });
      navigate('/empresa/preferencias');
      return;
    }
    setDialogProps({ open: true, usePreferences: true, editingBulletin: undefined });
  }, [userPreferences, navigate, trackEvent]);

  const handleDeleteBulletin = (bulletin: BulletinType) => {
    setDeletingBulletin(bulletin);
  };

  const confirmDeleteBulletin = useCallback(async () => {
    if (deletingBulletin) {
      try {
        await deleteBulletin(deletingBulletin.id);
        showNotification('Boletim excluído com sucesso', 'success');
      } catch (error) {
        showNotification('Erro ao excluir boletim', 'error');
        trackEvent('bulletin_delete_error', {
          bulletin_id: deletingBulletin.id,
          error_message: error instanceof Error ? error.message : String(error),
        });
      }
      setDeletingBulletin(null);
    }
  }, [deleteBulletin, showNotification, trackEvent]);

  const handleDateChange = useCallback(
    (newDate: string) => {
      trackEvent('bulletin_date_change', {
        old_date: selectedDate,
        new_date: newDate,
        bulletin_id: selectedBulletinId,
      });
      setSelectedDate(newDate);
      searchParams.set('date', newDate);
      searchParams.set('pagina', '1');
      if (selectedBulletinId) {
        loadBulletinLicitacoes(selectedBulletinId, 1);
        navigate(`/boletins/${selectedBulletinId}?${searchParams.toString()}`);
      } else {
        navigate(`/boletins?${searchParams.toString()}`);
      }
    },
    [navigate, searchParams, selectedBulletinId, loadBulletinLicitacoes],
  );

  const handleCloseBulletin = useCallback(() => {
    setSelectedBulletinId(null);
    setCurrentBulletin(null);
    navigate(`/boletins?${searchParams.toString()}`);
  }, [navigate, searchParams]);

  const handleInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElInfo(event.currentTarget);
  };

  const handleInfoClose = () => {
    setAnchorElInfo(null);
  };

  const selectedBulletinName = useMemo(
    () => bulletins.find((b) => b?.id === selectedBulletinId)?.name,
    [bulletins, selectedBulletinId],
  );

  const selectedBulletinSearchKeywords = useMemo(
    () => bulletins.find((b) => b?.id === selectedBulletinId)?.searchParams.palavra_chave,
    [bulletins, selectedBulletinId],
  );

  const skeletonCards = useMemo(
    () => (
      <Grid container spacing={2}>
        {[...Array(3)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <BulletinCardSkeleton />
          </Grid>
        ))}
      </Grid>
    ),
    [],
  );

  // Force column view on mobile, otherwise use store preference
  const effectiveViewFormat = isMobile ? 'column' : viewFormat;

  // Update the view format only if not on mobile
  const handleViewFormatChange = useCallback(
    (newFormat: string) => {
      if (!isMobile) {
        trackEvent('bulletin_view_format_change', {
          old_format: viewFormat,
          new_format: newFormat,
        });
        setViewFormat(newFormat as 'list' | 'column');
      }
    },
    [viewFormat, setViewFormat, trackEvent, isMobile],
  );

  // Update memoized content to use effectiveViewFormat
  const loadingLicitacoesSkeletons = useMemo(
    () => (
      <Grid container spacing={2}>
        {effectiveViewFormat === 'column' ? (
          <>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <LicitacaoColumnCardSkeleton />
              </Grid>
            ))}
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            {[...Array(5)].map((_, index) => (
              <LicitacaoCardSkeleton key={index} />
            ))}
          </Box>
        )}
      </Grid>
    ),
    [effectiveViewFormat],
  );

  const bulletinTitle = useMemo(() => {
    if (selectedBulletinId) {
      const bulletin = bulletins.find((b) => b?.id === selectedBulletinId);
      return bulletin?.name ? `${bulletin.name} - Boletins - Licitou` : 'Boletins - Licitou';
    }
    return 'Boletins - Licitou';
  }, [bulletins, selectedBulletinId]);

  // Remove the separate effect for searchParams changes
  // Instead, handle page changes only in the pagination callback
  const handlePageChange = useCallback(
    (newPage: number) => {
      if (selectedBulletinId && !isLoadingLicitacoes) {
        searchParams.set('pagina', newPage.toString());
        setSearchParams(searchParams);
        loadBulletinLicitacoes(selectedBulletinId, newPage);
        scrollToTop('bulletin-scroll-anchor');
      }
    },
    [
      selectedBulletinId,
      searchParams,
      setSearchParams,
      loadBulletinLicitacoes,
      isLoadingLicitacoes,
    ],
  );

  return (
    <>
      <Helmet>
        <title>{bulletinTitle}</title>
        <meta
          name="description"
          content="Acompanhe seus boletins de licitações personalizados e mantenha-se atualizado sobre novas oportunidades"
        />
      </Helmet>
      <div id="bulletin-scroll-anchor"></div>
      {!featureAccess.hasAccess ? (
        <FeatureLimitAlert
          title="Limite de Boletins"
          message={featureAccess.reason || 'Você atingiu o limite de boletins do seu plano'}
          variant="warning"
        />
      ) : featureAccess.limit && bulletins.length >= featureAccess.limit ? (
        <FeatureLimitAlert
          title="Limite de Boletins Atingido"
          message="Você atingiu o limite de boletins do seu plano atual"
          showUpgradeButton={false}
          variant="warning"
          usage={bulletins.length}
          limit={featureAccess.limit}
        />
      ) : null}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={4}
        className="bulletin-header"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h4" component="h1" color="primary">
            Boletins
          </Typography>
          <IconButton
            onClick={handleInfoClick}
            size="small"
            sx={{
              ml: 1,
              color: theme.palette.primary.main,
            }}
          >
            <HelpOutlineIcon />
          </IconButton>
          <Popover
            open={Boolean(anchorElInfo)}
            anchorEl={anchorElInfo}
            onClose={handleInfoClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box sx={{ p: 3, maxWidth: 400 }}>
              <Typography variant="h6" gutterBottom>
                Termos de Uso e Responsabilidade sobre Conteúdo
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Boletim Informativo:</strong> Este documento não substitui as publicações
                oficiais. Seu propósito é divulgar avisos de editais e informações sobre os
                processos de licitação em todo o território nacional, que foram incorporadas ao
                nosso banco de dados.
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Serviços de Informação:</strong> As informações fornecidas são
                caracterizadas como obrigações de meio, não assegurando resultados nos processos
                licitatórios.
              </Typography>
              <Typography variant="body2">
                <strong>Uso Restrito:</strong> O acesso é exclusivo para o cliente associado ao
                cadastro empresarial da plataforma Licitou. É proibido ao usuário compartilhar,
                transmitir, exibir, reproduzir, transferir ou comercializar qualquer informação
                deste boletim com terceiros.
              </Typography>
            </Box>
          </Popover>
        </Box>
        <Box>
          <input
            type="date"
            className="bulletin-date-picker"
            value={selectedDate}
            onChange={(e) => handleDateChange(e.target.value)}
            style={{ marginRight: '1rem' }}
            max={format(new Date(), 'yyyy-MM-dd', { timeZone: TIMEZONE })}
          />
          <IconButton
            className="bulletin-refresh"
            onClick={handleRefresh}
            disabled={isLoadingBulletins}
            sx={{ color: theme.palette.primary.main }}
          >
            <RefreshIcon />
          </IconButton>
          <Button
            className="bulletin-create"
            startIcon={<AddIcon />}
            onClick={() => setDialogProps({ open: true, usePreferences: false })}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            disabled={
              !featureAccess.hasAccess ||
              !!(featureAccess.limit && bulletins.length >= featureAccess.limit)
            }
          >
            Criar Boletim
          </Button>
        </Box>
      </Box>
      {isLoadingBulletins && bulletins.length === 0 ? (
        skeletonCards
      ) : !bulletins || bulletins.length === 0 ? (
        <Paper
          elevation={3}
          className="bulletin-empty-info"
          sx={{
            p: 3,
            mt: 4,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
          }}
        >
          <Box display="flex" alignItems="center" mb={2}>
            <InfoIcon color="info" sx={{ mr: 2, fontSize: 40 }} />
            <Typography variant="h6" color="info.main">
              Nenhum boletim encontrado
            </Typography>
          </Box>
          <Typography color="text.secondary" paragraph>
            Você ainda não criou nenhum boletim. Os boletins ajudam a organizar e acompanhar
            licitações de seu interesse.
          </Typography>
          <Typography color="text.secondary" paragraph>
            Ao criar um boletim, você poderá definir critérios de busca e visualizar licitações
            relevantes para datas específicas.
          </Typography>
          <Typography color="text.secondary">
            Crie seu primeiro boletim para começar a acompanhar oportunidades de forma mais
            eficiente.
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setDialogProps({ open: true, usePreferences: false })}
              variant="contained"
              color="primary"
            >
              Criar Novo Boletim
            </Button>
            <Button
              onClick={handleCreateFromPreferences}
              className="bulletin-create-preferences"
              variant="contained"
              color="secondary"
              title={!userPreferences ? 'Você ainda não configurou suas preferências' : ''}
            >
              Criar Boletim usando Minhas Preferências
            </Button>
          </Box>
        </Paper>
      ) : (
        <Grid container spacing={2}>
          {bulletins.map((bulletin, index) =>
            bulletin && bulletin.id ? (
              <Grid item xs={12} sm={6} md={4} key={bulletin.id}>
                <BulletinCard
                  bulletin={bulletin}
                  isSelected={selectedBulletinId === bulletin.id}
                  onBulletinClick={handleBulletinClick}
                  onMenuOpen={handleMenuOpen}
                />
              </Grid>
            ) : (
              <Grid item xs={12} key={index}>
                <Typography color="error">Erro ao carregar dados do boletim {index}</Typography>
              </Grid>
            ),
          )}
        </Grid>
      )}
      {selectedBulletinId && currentBulletinLicitacoes && (
        <Box className="bulletin-licitacoes" mt={4}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                Licitações do Boletim
                <Typography
                  component="span"
                  color="primary.main"
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 600,
                  }}
                >
                  {selectedBulletinName}
                </Typography>
                <Typography
                  component="span"
                  color="text.secondary"
                  sx={{
                    fontSize: '0.875rem',
                  }}
                >
                  {formatDate(parseISO(selectedDate), 'dd/MM/yyyy')}
                </Typography>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <PaginationInfo
                currentPage={currentPage}
                totalPages={totalPages}
                totalItems={currentBulletinLicitacoes.totalLicitacoes}
                itemsPerPage={itemsPerPage}
                isLoading={isLoadingLicitacoes}
                id="bulletin-scroll-anchor"
              />
              <ListControls
                itemsPerPage={itemsPerPage}
                onItemsPerPageChange={setItemsPerPage}
                viewFormat={effectiveViewFormat}
                onViewFormatChange={handleViewFormatChange}
                onRefresh={() => loadBulletinLicitacoes(selectedBulletinId, currentPage, true)}
                disabled={isLoadingLicitacoes}
              />
              <Button
                startIcon={<CloseIcon />}
                onClick={handleCloseBulletin}
                variant="outlined"
                color="secondary"
              >
                Fechar
              </Button>
            </Box>
          </Box>
          {isLoadingLicitacoes && currentBulletinLicitacoes?.licitacoes.length === 0 ? (
            loadingLicitacoesSkeletons
          ) : currentBulletinLicitacoes.licitacoes.length === 0 ? (
            <Paper
              elevation={3}
              className="bulletin-empty-results"
              sx={{
                p: 3,
                mt: 4,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 2,
                textAlign: 'center',
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                <InfoIcon color="info" sx={{ fontSize: 48 }} />
                <Typography variant="h6" color="info.main">
                  Nenhuma licitação encontrada
                </Typography>
                <Typography color="text.secondary">
                  Não foram encontradas licitações para este boletim na data selecionada.
                </Typography>
                <Typography color="text.secondary">
                  Tente selecionar outra data ou ajustar os filtros do boletim.
                </Typography>
              </Box>
            </Paper>
          ) : (
            <Grid container spacing={2} className="bulletin-grid">
              {effectiveViewFormat === 'column' ? (
                <>
                  {currentBulletinLicitacoes.licitacoes.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={`${item.id}-${currentPage}`}>
                      <LicitacaoColumnCard
                        className="bulletin-card"
                        item={item}
                        isFirst={index === 0}
                        searchKeywords={selectedBulletinSearchKeywords || []}
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <Box sx={{ width: '100%' }}>
                  {currentBulletinLicitacoes.licitacoes.map((item, index) => (
                    <LicitacaoCard
                      key={`${item.id}-${currentPage}`}
                      className="bulletin-card"
                      item={item}
                      isFirst={index === 0}
                      searchKeywords={selectedBulletinSearchKeywords || []}
                    />
                  ))}
                </Box>
              )}
            </Grid>
          )}

          {currentBulletinLicitacoes.totalLicitacoes > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={currentBulletinLicitacoes.totalLicitacoes}
              itemsPerPage={itemsPerPage}
              onPageChange={handlePageChange}
              isLoading={isLoadingLicitacoes}
            />
          )}
        </Box>
      )}
      <BulletinModal
        open={dialogProps.open}
        onClose={() =>
          setDialogProps({ open: false, usePreferences: false, editingBulletin: undefined })
        }
        onCreateBulletin={dialogProps.editingBulletin ? undefined : handleCreateBulletin}
        onEditBulletin={dialogProps.editingBulletin ? handleEditBulletin : undefined}
        usePreferences={dialogProps.usePreferences}
        bulletin={dialogProps.editingBulletin}
      />
      {deletingBulletin && (
        <Dialog
          open={!!deletingBulletin}
          onClose={() => setDeletingBulletin(null)}
          aria-labelledby="delete-bulletin-dialog-title"
          aria-describedby="delete-bulletin-dialog-description"
        >
          <DialogTitle id="delete-bulletin-dialog-title">Excluir Boletim</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você está excluindo o boletim "{deletingBulletin?.name}".
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeletingBulletin(null)} color="primary">
              Cancelar
            </Button>
            <Button onClick={confirmDeleteBulletin} color="primary">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem
          onClick={() =>
            menuBulletinId && handleEditClick(bulletins.find((b) => b.id === menuBulletinId)!)
          }
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() =>
            menuBulletinId && handleDeleteClick(bulletins.find((b) => b.id === menuBulletinId)!)
          }
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Excluir</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Bulletin;
