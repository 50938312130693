import PageContainer from '@components/Login/PageContainer';
import { useAuth } from '@contexts/AuthContext';
import { FirebaseError } from '@firebase/app';
import { useAnalytics } from '@hooks/useAnalytics';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { GoogleIcon } from '@theme/Icons';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const { signIn, signInWithGoogle, error, resetPassword, resetPasswordError, isLoading } =
    useAuth();
  const navigate = useNavigate();
  const { trackPublicEvent } = useAnalytics();

  useEffect(() => {
    trackPublicEvent('login_page_view');
  }, []);

  const validateInputs = () => {
    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage('Por favor, insira um endereço de e-mail válido.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('A senha deve ter pelo menos 6 caracteres.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  useEffect(() => {
    if (error instanceof FirebaseError) {
      handleFirebaseError(error);
    } else if (error instanceof Error) {
      setEmailError(true);
      setPasswordError(true);
      setEmailErrorMessage('Ocorreu um erro. Tente novamente.');
      setPasswordErrorMessage('Ocorreu um erro. Tente novamente.');
    }
  }, [error]);

  const handleFirebaseError = (firebaseError: FirebaseError) => {
    switch (firebaseError.code) {
      case 'auth/invalid-credential':
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        setEmailError(true);
        setPasswordError(true);
        setEmailErrorMessage('E-mail ou senha inválidos.');
        setPasswordErrorMessage('E-mail ou senha inválidos.');
        break;
      case 'auth/too-many-requests':
        setEmailError(true);
        setPasswordError(true);
        setEmailErrorMessage('Muitas tentativas. Tente novamente mais tarde.');
        setPasswordErrorMessage('Muitas tentativas. Tente novamente mais tarde.');
        break;
      case 'auth/user-disabled':
        setEmailError(true);
        setEmailErrorMessage('Esta conta foi desativada.');
        break;
      case 'auth/popup-closed-by-user':
        // This is specific to Google sign-in
        console.log('Login cancelado pelo usuário.');
        break;
      case 'auth/cancelled-popup-request':
        // This is specific to Google sign-in
        console.log('Requisição de popup cancelada.');
        break;
      default:
        setEmailError(true);
        setPasswordError(true);
        setEmailErrorMessage('Ocorreu um erro. Tente novamente.');
        setPasswordErrorMessage('Ocorreu um erro. Tente novamente.');
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateInputs()) {
      try {
        trackPublicEvent('login_attempt', {
          method: 'email',
          has_email: Boolean(email),
        });
        await signIn(email, password);
        trackPublicEvent('login_success', {
          method: 'email',
        });
        navigate('/busca');
      } catch (error) {
        trackPublicEvent('login_error', {
          method: 'email',
          error_code: error instanceof FirebaseError ? error.code : 'unknown',
          error_message: error instanceof Error ? error.message : String(error),
        });
        console.error('Login falhou:', error);
        if (error instanceof FirebaseError) {
          handleFirebaseError(error);
        } else if (error instanceof Error) {
          setEmailError(true);
          setPasswordError(true);
          setEmailErrorMessage('Ocorreu um erro. Tente novamente.');
          setPasswordErrorMessage('Ocorreu um erro. Tente novamente.');
        }
      }
    }
  };

  const handleGoogleSignIn = async () => {
    trackPublicEvent('login_attempt', {
      method: 'google',
    });

    try {
      const token = await signInWithGoogle();
      if (token) {
        trackPublicEvent('login_success', {
          method: 'google',
        });
        navigate('/busca');
      }
    } catch (catchError) {
      // Don't track error if user just closed the popup
      if (
        catchError instanceof FirebaseError &&
        (catchError.code === 'auth/popup-closed-by-user' ||
          catchError.code === 'auth/cancelled-popup-request')
      ) {
        return;
      }

      trackPublicEvent('login_error', {
        method: 'google',
        error_code: catchError instanceof FirebaseError ? catchError.code : 'unknown',
        error_message: catchError instanceof Error ? catchError.message : String(catchError),
      });
      console.error('Login com Google falhou:', catchError);
      if (catchError instanceof FirebaseError) {
        handleFirebaseError(catchError);
      } else if (catchError instanceof Error) {
        setEmailError(true);
        setPasswordError(true);
        setEmailErrorMessage('Ocorreu um erro. Tente novamente.');
        setPasswordErrorMessage('Ocorreu um erro. Tente novamente.');
      }
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (resetEmail) {
      trackPublicEvent('password_reset_attempt', {
        has_email: Boolean(resetEmail),
      });
      await resetPassword(resetEmail);
      if (!resetPasswordError) {
        trackPublicEvent('password_reset_success');
        setResetModalOpen(false);
        setResetEmail('');
      } else {
        trackPublicEvent('password_reset_error', {
          error_message: resetPasswordError,
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Entrar - Licitou</title>
        <meta name="description" content="Faça login no Licitou para acessar licitações" />
      </Helmet>
      <CssBaseline />
      <PageContainer>
        <Typography
          component="h1"
          variant="h4"
          sx={{
            width: '100%',
            fontSize: 'clamp(2rem, 10vw, 2.15rem)',
            textAlign: 'center',
          }}
        >
          Entrar em Licitou
        </Typography>
        <Box
          component="form"
          onSubmit={handleLogin}
          noValidate
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 2,
          }}
        >
          <FormControl>
            <FormLabel htmlFor="email">E-mail</FormLabel>
            <TextField
              error={emailError}
              id="email"
              type="email"
              name="email"
              placeholder="seu@email.com"
              autoComplete="email"
              autoFocus
              required
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Senha</FormLabel>
            <TextField
              error={passwordError}
              helperText={passwordErrorMessage || emailErrorMessage}
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="current-password"
              required
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Link
              component="button"
              variant="body2"
              onClick={() => setResetModalOpen(true)}
              sx={{ alignSelf: 'flex-end', mt: 1 }}
            >
              Esqueceu sua senha?
            </Link>
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Lembrar-me"
          />
          {emailErrorMessage ||
            (passwordErrorMessage && (
              <Typography color="error" align="center">
                {emailErrorMessage || passwordErrorMessage}
              </Typography>
            ))}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
            Entrar
          </Button>
          <Typography sx={{ textAlign: 'center' }}>
            Não tem uma conta?{' '}
            <Link href="https://licitou.com.br/assine" variant="body2">
              Cadastre-se
            </Link>
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }}>ou</Divider>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleGoogleSignIn}
          startIcon={<GoogleIcon />}
        >
          Entrar com Google
        </Button>
      </PageContainer>
      <Dialog open={resetModalOpen} onClose={() => setResetModalOpen(false)}>
        <DialogTitle>Recuperar Senha</DialogTitle>
        <DialogContent>
          <Box component="form" onSubmit={handleResetPassword} sx={{ mt: 2 }}>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Digite seu e-mail para receber um link de recuperação de senha.
            </Typography>
            {resetPasswordError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {resetPasswordError}
              </Alert>
            )}
            <TextField
              autoFocus
              required
              fullWidth
              id="reset-email"
              label="E-mail"
              type="email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResetModalOpen(false)}>Cancelar</Button>
          <Button
            onClick={handleResetPassword}
            variant="contained"
            disabled={!resetEmail || isLoading}
          >
            {isLoading ? 'Enviando...' : 'Enviar'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Login;
