import Api from '@services/api';
import {
  CustomFollowingItem,
  Following,
  FollowingImportance,
  FollowingItem,
  Licitacao,
} from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface FollowingState {
  following: Following[];
  followingLicitacoes: Licitacao[];
  selectedItems: { [licitacaoId: string]: FollowingItem[] };
  isLoading: boolean;
  error: string | null;
  currentPage: number;
  totalFollowing: number;
  totalPages: number;
  itemsPerPage: number;
  viewFormat: 'list' | 'column';
  fetchFollowing: (force?: boolean) => Promise<void>;
  fetchFollowingLicitacoes: (page?: number, forceRefresh?: boolean) => Promise<void>;
  addFollowing: (licitacaoId: string) => Promise<void>;
  removeFollowing: (licitacaoId: string) => Promise<void>;
  isFollowing: (licitacaoId: string) => boolean;
  resetStore: () => void;
  setCurrentPage: (page: number) => void;
  addItemToFollowing: (
    licitacaoId: string,
    item: FollowingItem,
    customPrice?: number,
  ) => Promise<void>;
  updateItem: (
    licitacaoId: string,
    itemId: string,
    { customPrice, customMarca, customFabricante }: Partial<FollowingItem>,
  ) => Promise<void>;
  removeItemFromFollowing: (licitacaoId: string, itemId: string) => Promise<void>;
  getSelectedItems: (licitacaoId: string) => FollowingItem[];
  updateFollowingImportance: (
    licitacaoId: string,
    importance: FollowingImportance,
  ) => Promise<void>;
  getFollowingImportance: (licitacaoId: string) => FollowingImportance;
  addCustomItemToFollowing: (licitacaoId: string, item: CustomFollowingItem) => Promise<void>;
  setItemsPerPage: (perPage: number) => void;
  setViewFormat: (format: 'list' | 'column') => void;
  lastFetch: number | null;
  lastFetchList: number | null;
}

export const useFollowingStore = create<FollowingState>()(
  persist(
    (set, get) => ({
      following: [],
      followingLicitacoes: [],
      selectedItems: {},
      isLoading: false,
      error: null,
      currentPage: 1,
      totalFollowing: 0,
      totalPages: 0,
      itemsPerPage: 20,
      viewFormat: 'list' as 'list' | 'column',
      lastFetch: null,
      lastFetchList: null,
      fetchFollowing: async (force = false) => {
        set({ isLoading: true, error: null });
        try {
          const now = Date.now();
          const lastFetch = get().lastFetch;
          const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

          if (!force && lastFetch && now - lastFetch < CACHE_DURATION) {
            return;
          }

          const response = await Api.getFollowing();
          set({
            following: response.following,
            totalFollowing: response.total,
            isLoading: false,
            lastFetch: now,
            lastFetchList: null,
          });
        } catch (error) {
          console.error('Error fetching following:', error);
          set({ error: 'Erro ao buscar acompanhamentos', isLoading: false, lastFetch: null });
        }
      },

      fetchFollowingLicitacoes: async (page: number = 1, forceRefresh = false) => {
        const now = Date.now();
        const lastFetchList = get().lastFetchList;
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

        // Return cached data if within cache duration and not forcing refresh
        if (!forceRefresh && page === 1 && lastFetchList && now - lastFetchList < CACHE_DURATION) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const response = await Api.getFollowingLicitacoes(page, get().itemsPerPage);
          set({
            followingLicitacoes: response.licitacoes,
            totalFollowing: response.totalLicitacoes,
            totalPages: response.paginas,
            isLoading: false,
            currentPage: page,
            lastFetchList: now,
          });
        } catch (error) {
          console.error('Error fetching following licitacoes:', error);
          set({
            error: 'Erro ao buscar acompanhamentos',
            isLoading: false,
            totalPages: 0,
          });
        }
      },

      addFollowing: async (licitacaoId: string) => {
        set({ isLoading: true, error: null });
        try {
          const newFollowing = await Api.addFollowing(licitacaoId);
          set((state) => ({
            following: [
              ...state.following,
              {
                ...newFollowing,
              },
            ],
            lastFetchList: null, // Reset cache when state changes
          }));

          // Update followingLicitacoes if the licitacao exists there
          set((state) => ({
            followingLicitacoes: state.followingLicitacoes.map((licitacao) =>
              licitacao.id === licitacaoId ? { ...licitacao } : licitacao,
            ),
          }));

          await get().fetchFollowingLicitacoes();
          set({ isLoading: false });
        } catch (error: any) {
          console.error('Error adding following:', error);
          const errorMessage = error.message || 'Erro ao adicionar acompanhamento';
          set({ error: errorMessage, isLoading: false });
          throw error;
        }
      },

      removeFollowing: async (licitacaoId: string) => {
        set({ isLoading: true, error: null });
        try {
          await Api.removeFollowing(licitacaoId);
          set((state) => ({
            following: state.following.filter((fav) => fav.licitacaoId !== licitacaoId),
            followingLicitacoes: state.followingLicitacoes.filter((l) => l.id !== licitacaoId),
            lastFetchList: null,
            isLoading: false,
          }));
        } catch (error: any) {
          console.error('Error removing following:', error);
          const errorMessage = error.message || 'Erro ao remover acompanhamento';
          set({ error: errorMessage, isLoading: false });
          throw error;
        }
      },

      isFollowing: (licitacaoId: string) => {
        return get().following.some((fav) => fav.licitacaoId === licitacaoId);
      },

      resetStore: () =>
        set({
          following: [],
          followingLicitacoes: [],
          isLoading: false,
          error: null,
          currentPage: 1,
          totalFollowing: 0,
          totalPages: 0,
          itemsPerPage: 20,
          viewFormat: 'list',
          lastFetchList: null, // Reset cache timestamp
        }),

      setCurrentPage: (page: number) => set({ currentPage: page }),

      getSelectedItems: (licitacaoId: string) => {
        const following = get().following.find((f) => f.licitacaoId === licitacaoId);
        return following?.items || [];
      },

      addItemToFollowing: async (
        licitacaoId: string,
        item: FollowingItem,
        customPrice?: number,
      ) => {
        const previousState = {
          following: [...get().following],
          selectedItems: { ...get().selectedItems },
        };
        set({ isLoading: true, error: null });
        try {
          const itemWithPrice = { ...item, customPrice };
          await Api.addFollowingItem(licitacaoId, itemWithPrice);
          set((state) => ({
            following: state.following.map((f) => {
              if (f.licitacaoId === licitacaoId) {
                return {
                  ...f,
                  items: [...(f.items || []), itemWithPrice],
                };
              }
              return f;
            }),
            selectedItems: {
              ...state.selectedItems,
              [licitacaoId]: [...(state.selectedItems[licitacaoId] || []), itemWithPrice],
            },
            isLoading: false,
          }));
        } catch (error: any) {
          console.error('Error adding item to following:', error);
          const errorMessage = error.message || 'Erro ao adicionar item ao acompanhamento';
          set({ ...previousState, error: errorMessage, isLoading: false });
          throw error;
        }
      },

      removeItemFromFollowing: async (licitacaoId: string, itemId: string) => {
        const previousState = {
          following: [...get().following],
          selectedItems: { ...get().selectedItems },
        };
        set({ isLoading: true, error: null });
        try {
          await Api.removeFollowingItem(licitacaoId, itemId);
          set((state) => ({
            following: state.following.map((f) => {
              if (f.licitacaoId === licitacaoId) {
                return {
                  ...f,
                  items: (f.items || []).filter((i) => i.id !== itemId),
                };
              }
              return f;
            }),
            selectedItems: {
              ...state.selectedItems,
              [licitacaoId]: state.selectedItems[licitacaoId]?.filter((i) => i.id !== itemId) || [],
            },
            isLoading: false,
          }));
        } catch (error: any) {
          console.error('Error removing item from following:', error);
          const errorMessage = error.message || 'Erro ao remover item do acompanhamento';
          set({ ...previousState, error: errorMessage, isLoading: false });
          throw error;
        }
      },

      updateItem: async (
        licitacaoId: string,
        itemId: string,
        { customPrice, customMarca, customFabricante }: Partial<FollowingItem>,
      ) => {
        try {
          await Api.updateFollowingItem(licitacaoId, itemId, {
            customPrice,
            customMarca,
            customFabricante,
          });

          // Update both following and selectedItems arrays
          set((state) => ({
            following: state.following.map((f) => {
              if (f.licitacaoId === licitacaoId) {
                return {
                  ...f,
                  items: (f.items || []).map((item) =>
                    item.id === itemId
                      ? { ...item, customPrice, customMarca, customFabricante }
                      : item,
                  ),
                };
              }
              return f;
            }),
            selectedItems: {
              ...state.selectedItems,
              [licitacaoId]:
                state.selectedItems[licitacaoId]?.map((item) =>
                  item.id === itemId
                    ? { ...item, customPrice, customMarca, customFabricante }
                    : item,
                ) || [],
            },
          }));
        } catch (error) {
          console.error('Error updating item price:', error);
          set({ error: 'Erro ao atualizar preço do item' });
        }
      },

      updateFollowingImportance: async (licitacaoId: string, importance: FollowingImportance) => {
        const previousFollowing = [...get().following];
        set({ isLoading: true, error: null });
        try {
          await Api.updateFollowingImportance(licitacaoId, importance);
          set((state) => ({
            following: state.following.map((f) =>
              f.licitacaoId === licitacaoId ? { ...f, importance } : f,
            ),
            isLoading: false,
          }));
        } catch (error: any) {
          console.error('Error updating following importance:', error);
          const errorMessage = error.message || 'Erro ao atualizar importância do acompanhamento';
          set({ following: previousFollowing, error: errorMessage, isLoading: false });
          throw error;
        }
      },

      getFollowingImportance: (licitacaoId: string) => {
        const following = get().following.find((f) => f.licitacaoId === licitacaoId);
        return following?.importance || '';
      },

      addCustomItemToFollowing: async (licitacaoId: string, customItem: CustomFollowingItem) => {
        try {
          const response = await Api.addFollowingCustomItem(licitacaoId, customItem);

          set((state) => ({
            following: state.following.map((f) => {
              if (f.licitacaoId === licitacaoId) {
                return {
                  ...f,
                  items: [...(f.items || []), response],
                };
              }
              return f;
            }),
            selectedItems: {
              ...state.selectedItems,
              [licitacaoId]: [...(state.selectedItems[licitacaoId] || []), response],
            },
          }));
        } catch (error) {
          console.error('Error adding custom item to following:', error);
          set({ error: 'Erro ao adicionar item personalizado' });
        }
      },

      setItemsPerPage: (perPage: number) => {
        set({ itemsPerPage: perPage, lastFetchList: null, currentPage: 1 });
        get().fetchFollowingLicitacoes(1, true);
      },

      setViewFormat: (format: 'list' | 'column') => set({ viewFormat: format }),
    }),
    {
      name: 'following-storage',
      partialize: (state) => ({ itemsPerPage: state.itemsPerPage, viewFormat: state.viewFormat }),
    },
  ),
);
