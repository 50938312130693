import { ComprasbrLicitacao, Licitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class ComprasbrPortalService extends BasePortalService {
  name = 'ComprasBR';
  logo = '/images/portals/comprasbr.png';
  portal = Portal.ComprasBR;
  portalData: ComprasbrLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as ComprasbrLicitacao;
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getFollowUpUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getEsclarecimentosUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getImpugnacaoUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getLoginUrl(): string | null {
    return 'https://app.comprasbr.com.br/auto-cadastro/#/validado/login/externo?uri=http:%2F%2Fapp.comprasbr.com.br%2Flicitacao&produto=licitacao';
  }

  getRegisterUrl(): string | null {
    return 'https://comprasbr.com.br/cadastro/';
  }
}
