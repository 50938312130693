import {
  AccessTime as AccessTimeIcon,
  AttachMoney as AttachMoneyIcon,
  Info as InfoIcon,
  OpenInNew as OpenInNewIcon,

  // PictureAsPdf as PdfIcon,
  Print as PrintIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import { Licitacao, PortalService } from '@types';
import { formatCurrency } from '@utils';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMemo } from 'react';
interface ApplyStepProps {
  portalService: PortalService;
  licitacao: Licitacao;
  portalName: string;
}

export const ApplyStep: React.FC<ApplyStepProps> = ({ portalService, licitacao }) => {
  const { getSelectedItems } = useFollowingStore();
  const selectedItems = getSelectedItems(licitacao.id);
  const totalValue = selectedItems.reduce(
    (total, item) => total + (item.customPrice || 0) * item.quantidade,
    0,
  );

  const endTime = licitacao.fim_recebimento_propostas_datetime || licitacao.abertura_datetime;
  const formattedDeadline = useMemo(
    () =>
      endTime
        ? format(new Date(endTime), "dd/MM/yyyy 'às' HH:mm", { locale: ptBR })
        : 'Prazo não definido',
    [endTime],
  );

  const formattedEstimatedValue = useMemo(
    () => (licitacao.valor === 0 ? 'Não informado' : formatCurrency(licitacao.valor)),
    [licitacao.valor],
  );

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ display: 'flex', flex: 1, gap: 2, width: '100%' }}>
        {/* Left Column */}
        <Box
          sx={{
            flex: 1,
            width: '50%',
            '& .MuiTypography-body1, & .MuiTypography-body2': { fontSize: '0.875rem' },
          }}
        >
          <Stack spacing={3}>
            {/* Informações da licitação */}
            <Box>
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  fontSize: '1rem',
                  fontWeight: 500,
                  mb: 2,
                }}
              >
                Informações da Licitação
              </Typography>
              <Stack spacing={1.5}>
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <AccessTimeIcon color="primary" sx={{ fontSize: 20 }} />
                  <Typography variant="body2" color="text.primary">
                    Prazo final: {formattedDeadline}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <AttachMoneyIcon color="primary" sx={{ fontSize: 20 }} />
                  <Typography variant="body2" color="text.primary">
                    Valor estimado: {formattedEstimatedValue}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <InfoIcon color="primary" sx={{ fontSize: 20 }} />
                  <Typography variant="body2" color="text.primary">
                    Itens: {licitacao.items?.length || 0}
                  </Typography>
                </Stack>
              </Stack>
            </Box>

            {/* Resumo da proposta */}
            <Box>
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  fontSize: '1rem',
                  fontWeight: 500,
                  mb: 2,
                }}
              >
                Resumo da sua Proposta
              </Typography>
              <Stack spacing={1.5} sx={{ mb: 3 }}>
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <AttachMoneyIcon color="primary" sx={{ fontSize: 20 }} />
                  <Typography variant="body2" color="text.primary">
                    Valor total da proposta: {formatCurrency(totalValue)}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <InfoIcon color="primary" sx={{ fontSize: 20 }} />
                  <Typography variant="body2" color="text.primary">
                    Itens selecionados: {selectedItems.length} de {licitacao.items?.length || 0}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Box
            sx={{
              bgcolor: 'warning.light',
              border: 1,
              borderColor: 'warning.main',
              p: 2,
              borderRadius: 1,
              mb: 2,
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <WarningIcon color="warning" />
              <Typography variant="subtitle2" color="warning.dark" sx={{ fontSize: '0.875rem' }}>
                Após o envio, a proposta não poderá ser alterada. Certifique-se de que todos os
                dados estão corretos.
              </Typography>
            </Stack>
          </Box>
        </Box>

        {/* Right Column */}
        <Box sx={{ flex: 1, width: '50%', display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Typography
              variant="h6"
              color="primary"
              sx={{
                fontSize: '1rem',
                fontWeight: 500,
                mb: 1,
              }}
            >
              Checklist da Proposta
            </Typography>

            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2 }}>
              {/* Left Column */}
              <Stack spacing={1}>
                {/* Preços e Produtos */}
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ fontSize: '0.75rem', mb: 0.25 }}
                  >
                    Preços e Produtos
                  </Typography>
                  <Stack spacing={0.25}>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Confira preços unitários/totais
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Especifique marca/modelo
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Verifique compatibilidade
                    </Typography>
                  </Stack>
                </Box>

                {/* Prazos e Validade */}
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ fontSize: '0.75rem', mb: 0.25 }}
                  >
                    Prazos e Validade
                  </Typography>
                  <Stack spacing={0.25}>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Prazo de entrega
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Validade da proposta
                    </Typography>
                  </Stack>
                </Box>
              </Stack>

              {/* Right Column */}
              <Stack spacing={1}>
                {/* Documentação */}
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ fontSize: '0.75rem', mb: 0.25 }}
                  >
                    Documentação
                  </Typography>
                  <Stack spacing={0.25}>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Dados bancários
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Declarações exigidas
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Exigências do edital
                    </Typography>
                  </Stack>
                </Box>

                {/* Revisão Final */}
                <Box>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ fontSize: '0.75rem', mb: 0.25 }}
                  >
                    Revisão Final
                  </Typography>
                  <Stack spacing={0.25}>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Revise os cálculos
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Considere todos custos
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                      • Mantenha uma cópia
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                Gere uma proposta comercial para conferência:
              </Typography>
              <Button
                component={Link}
                href={`/documentos/proposta?id=${licitacao.id}`}
                startIcon={<PrintIcon />}
                variant="contained"
                color="primary"
                sx={{ width: 'fit-content' }}
              >
                Gerar Proposta Comercial
              </Button>
            </Box>
          </Box>

          <Box sx={{ mt: 'auto' }}>
            {portalService.isPastAbertura() ? (
              <Button
                variant="contained"
                color="primary"
                endIcon={<OpenInNewIcon />}
                href={portalService.getFollowUpUrl() || '#'}
                target="_blank"
                rel="noopener noreferrer"
                fullWidth
                sx={{
                  height: 48,
                  fontSize: '1rem',
                }}
              >
                Acompanhar no Portal do Fornecedor
              </Button>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  color="success"
                  endIcon={<OpenInNewIcon />}
                  href={portalService.getApplyUrl() || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  fullWidth
                  sx={{
                    height: 48,
                    fontSize: '1rem',
                  }}
                >
                  Enviar Proposta no Portal do Fornecedor
                </Button>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    display: 'block',
                    mt: 1,
                  }}
                >
                  Você será redirecionado para o portal do fornecedor para enviar sua proposta
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
