import { Licitacao, LicitarDigitalLicitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class LicitardigitalPortalService extends BasePortalService {
  name = 'Licitar Digital';
  logo = '/images/portals/licitardigital.png';
  portal = Portal.LicitarDigital;
  portalData: LicitarDigitalLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as LicitarDigitalLicitacao;
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getFollowUpUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getEsclarecimentosUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getImpugnacaoUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getLoginUrl(): string | null {
    return 'https://app.licitardigital.com.br/login/';
  }

  getRegisterUrl(): string | null {
    return 'https://app2.licitardigital.com.br/cadastro/';
  }
}
