export const formatCNAE = (cnae: string): string => {
  if (cnae.length !== 7) return cnae;
  return `${cnae.slice(0, 4)}-${cnae.slice(4, 5)}/${cnae.slice(5)}`;
};

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;

  const truncated = text.substr(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  if (lastSpaceIndex === -1) return truncated + '...';

  return truncated.substr(0, lastSpaceIndex) + '...';
};
