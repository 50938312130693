import {
  AttachMoney as AttachMoneyIcon,
  Info as InfoIcon,
  Launch as LaunchIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { ComprasnetPortalService } from '../../services/portals/ComprasnetPortal';
import { ComprasnetLicitacao, Licitacao } from '../../types';

interface ComprasnetDetailsProps {
  details: ComprasnetLicitacao;
  licitacao: Licitacao;
}

const ComprasnetDetails: React.FC<ComprasnetDetailsProps> = ({ details, licitacao }) => {
  const portalService = new ComprasnetPortalService(licitacao);
  const portalUrl = portalService.getPortalUrl();
  const applyUrl = portalService.getApplyUrl();
  const followUpUrl = portalService.getFollowUpUrl();
  const isPastAbertura = portalService.isPastAbertura();

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <InfoIcon color="primary" />
            <Typography variant="h6" color="primary">
              Informações do ComprasNet
            </Typography>
          </Stack>

          <Box display="flex" gap={1}>
            {portalUrl && portalUrl !== '#' && (
              <Button
                href={portalUrl}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                color="info"
                startIcon={<LaunchIcon />}
              >
                Acompanhar no portal
              </Button>
            )}
            {isPastAbertura
              ? followUpUrl &&
                followUpUrl !== '#' && (
                  <Button
                    href={followUpUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                    color="primary"
                    startIcon={<VisibilityIcon />}
                  >
                    Acompanhar proposta
                  </Button>
                )
              : applyUrl &&
                applyUrl !== '#' && (
                  <Button
                    href={applyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="contained"
                    color="success"
                    startIcon={<AttachMoneyIcon />}
                  >
                    Cadastrar Proposta
                  </Button>
                )}
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <b>Órgão:</b> {details.orgao}
            </Typography>
            <Typography>
              <b>UASG:</b> {details.uasg}
            </Typography>
            <Typography>
              <b>Modalidade:</b> {details.modalidade}
            </Typography>
            <Typography>
              <b>Edital:</b> {details.numero}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <b>Data do Edital:</b> {details.dataEditalAPartirDe}
            </Typography>
            <Typography>
              <b>Telefone:</b> {details.telefone || 'Não informado'}
            </Typography>
            <Typography>
              <b>Fax:</b> {details.fax || 'Não informado'}
            </Typography>
            <Typography>
              <b>Início de Entrega da Proposta:</b> {details.entregaProposta}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography>
              <b>Endereço:</b> {details.endereco}
            </Typography>
          </Grid>
        </Grid>

        {details.objeto && (
          <Box sx={{ mt: 3, bgcolor: 'info.lighter', p: 2, borderRadius: 1 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <InfoIcon color="info" fontSize="small" />
              <Typography variant="subtitle2" color="info.main">
                <b>Objeto:</b> {details.objeto}
              </Typography>
            </Stack>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ComprasnetDetails;
