import { DocumentSection } from '@types';

export const DOCUMENT_SECTIONS: DocumentSection[] = [
  {
    id: 'habilitacao_juridica',
    title: 'Habilitação Jurídica',
    documents: [
      { id: 'registro_comercial', name: 'Registro comercial (empresa individual)' },
      {
        id: 'ato_constitutivo',
        name: 'Contrato Social/Ato Constitutivo/Estatuto',
        link: 'https://consultacnpj.redesim.gov.br/minhas-empresas',
      },
      { id: 'documentos_eleicao', name: 'Documentação de Eleição Administradores' },
      {
        id: 'decreto_autorizacao',
        name: 'Decreto Autorização/Ato de Registro (Empresas Estrangeiras)',
        link: 'https://solicitacao.servicos.gov.br/processos',
      },
      {
        id: 'cedula_identidade',
        name: 'RG dos sócios',
        link: 'https://www.gov.br/governodigital/pt-br/identidade/carteira-de-documentos-digitais',
      },
      { id: 'procuracao', name: 'Procuração/Documento Procurador' },
      {
        id: 'alvara_funcionamento',
        name: 'Alvará de Funcionamento',
        link: null,
        modalLinks: {
          title: 'Alvará de Funcionamento por Estado',
          links: [
            {
              label: 'São Paulo',
              url: 'https://www.prefeitura.sp.gov.br/cidade/secretarias/licenciamento/servicos/index.php?p=3197',
            },
            {
              label: 'Rio de Janeiro',
              url: 'https://carioca.rio/servicos/alvara-de-funcionamento/',
            },
            {
              label: 'Minas Gerais',
              url: 'https://www.mg.gov.br/servico/obter-alvara-de-localizacao-e-funcionamento',
            },
            { label: 'Paraná', url: 'https://www.curitiba.pr.gov.br/servicos/alvara-comercial/18' },
            {
              label: 'Rio Grande do Sul',
              url: 'https://prefeitura.poa.br/carta-de-servicos/alvara-de-localizacao-e-funcionamento',
            },
            {
              label: 'Bahia',
              url: 'http://www.salvador.ba.gov.br/index.php/servicos-sedur/alvara-de-funcionamento',
            },
            {
              label: 'Santa Catarina',
              url: 'https://www.sc.gov.br/servicos/detalhe/alvara-de-funcionamento',
            },
            { label: 'Pernambuco', url: 'https://licenca.recife.pe.gov.br/como-obter-alvara' },
            { label: 'Ceará', url: 'https://www.fortaleza.ce.gov.br/servicos/servico/246' },
            {
              label: 'Distrito Federal',
              url: 'https://www.df.gov.br/category/alvara-de-funcionamento/',
            },
            { label: 'Goiás', url: '#' },
            { label: 'Espírito Santo', url: '#' },
            { label: 'Pará', url: '#' },
            { label: 'Maranhão', url: '#' },
            { label: 'Paraíba', url: '#' },
            { label: 'Amazonas', url: '#' },
            { label: 'Mato Grosso', url: '#' },
            { label: 'Rio Grande do Norte', url: '#' },
            { label: 'Piauí', url: '#' },
            { label: 'Alagoas', url: '#' },
            { label: 'Mato Grosso do Sul', url: '#' },
            { label: 'Sergipe', url: '#' },
            { label: 'Rondônia', url: '#' },
            { label: 'Tocantins', url: '#' },
            { label: 'Acre', url: '#' },
            { label: 'Amapá', url: '#' },
            { label: 'Roraima', url: '#' },
          ],
        },
      },
    ],
  },
  {
    id: 'habilitacao_fiscal_trabalhista',
    title: 'Habilitação Fiscal e Trabalhista',
    documents: [
      {
        id: 'cartao_cnpj',
        name: 'Cartão CNPJ',
        link: 'https://servicos.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp',
      },
      {
        id: 'inscricao_estadual',
        name: 'IE (Inscrição Estadual)',
        link: null,
        modalLinks: {
          title: 'Inscrição Estadual por Estado',
          links: [
            { label: 'Acre', url: 'https://www.sefaz.ac.gov.br' },
            { label: 'Alagoas', url: 'https://www.sefaz.al.gov.br' },
            { label: 'Amapá', url: 'https://www.sefaz.ap.gov.br' },
            { label: 'Amazonas', url: 'https://online.sefaz.am.gov.br' },
            { label: 'Bahia', url: 'https://www.sefaz.ba.gov.br' },
            { label: 'Ceará', url: 'https://www.sefaz.ce.gov.br' },
            { label: 'Distrito Federal', url: 'https://www.fazenda.df.gov.br' },
            { label: 'Espírito Santo', url: 'https://internet.sefaz.es.gov.br' },
            { label: 'Goiás', url: 'https://www.sefaz.go.gov.br' },
            { label: 'Maranhão', url: 'https://sistemas.sefaz.ma.gov.br' },
            { label: 'Mato Grosso', url: 'https://www.sefaz.mt.gov.br' },
            { label: 'Mato Grosso do Sul', url: 'https://www.sefaz.ms.gov.br' },
            { label: 'Minas Gerais', url: 'https://www2.fazenda.mg.gov.br' },
            { label: 'Pará', url: 'https://app.sefa.pa.gov.br' },
            { label: 'Paraíba', url: 'https://www.receita.pb.gov.br' },
            { label: 'Paraná', url: 'https://www.fazenda.pr.gov.br' },
            { label: 'Pernambuco', url: 'https://www.sefaz.pe.gov.br' },
            { label: 'Piauí', url: 'https://portal.sefaz.pi.gov.br' },
            { label: 'Rio de Janeiro', url: 'https://www.fazenda.rj.gov.br' },
            { label: 'Rio Grande do Norte', url: 'https://www.set.rn.gov.br' },
            { label: 'Rio Grande do Sul', url: 'https://www.sefaz.rs.gov.br' },
            { label: 'Rondônia', url: 'https://www.sefin.ro.gov.br' },
            { label: 'Roraima', url: 'https://www.sefaz.rr.gov.br' },
            { label: 'Santa Catarina', url: 'https://www.sef.sc.gov.br' },
            { label: 'São Paulo', url: 'https://www.fazenda.sp.gov.br' },
            { label: 'Sergipe', url: 'https://www.sefaz.se.gov.br' },
            { label: 'Tocantins', url: 'https://www.sefaz.to.gov.br' },
          ],
        },
      },
      {
        id: 'inscricao_municipal',
        name: 'IM (Inscrição Municipal)',
        link: null,
        modalLinks: {
          title: 'Inscrição Municipal por Estado',
          links: [
            {
              label: 'São Paulo',
              url: 'https://www.prefeitura.sp.gov.br/cidade/secretarias/licenciamento/servicos/index.php?p=3197',
            },
            {
              label: 'Rio de Janeiro',
              url: 'https://carioca.rio/servicos/alvara-de-funcionamento/',
            },
            {
              label: 'Minas Gerais',
              url: 'https://www.mg.gov.br/servico/obter-alvara-de-localizacao-e-funcionamento',
            },
            { label: 'Paraná', url: 'https://www.curitiba.pr.gov.br/servicos/alvara-comercial/18' },
            {
              label: 'Rio Grande do Sul',
              url: 'https://prefeitura.poa.br/carta-de-servicos/alvara-de-localizacao-e-funcionamento',
            },
            {
              label: 'Bahia',
              url: 'http://www.salvador.ba.gov.br/index.php/servicos-sedur/alvara-de-funcionamento',
            },
            {
              label: 'Santa Catarina',
              url: 'https://www.sc.gov.br/servicos/detalhe/alvara-de-funcionamento',
            },
            { label: 'Pernambuco', url: 'https://licenca.recife.pe.gov.br/como-obter-alvara' },
            { label: 'Ceará', url: 'https://www.fortaleza.ce.gov.br/servicos/servico/246' },
            {
              label: 'Distrito Federal',
              url: 'https://www.df.gov.br/category/alvara-de-funcionamento/',
            },
            { label: 'Goiás', url: '#' },
            { label: 'Espírito Santo', url: '#' },
            { label: 'Pará', url: '#' },
            { label: 'Maranhão', url: '#' },
            { label: 'Paraíba', url: '#' },
            { label: 'Amazonas', url: '#' },
            { label: 'Mato Grosso', url: '#' },
            { label: 'Rio Grande do Norte', url: '#' },
            { label: 'Piauí', url: '#' },
            { label: 'Alagoas', url: '#' },
            { label: 'Mato Grosso do Sul', url: '#' },
            { label: 'Sergipe', url: '#' },
            { label: 'Rondônia', url: '#' },
            { label: 'Tocantins', url: '#' },
            { label: 'Acre', url: '#' },
            { label: 'Amapá', url: '#' },
            { label: 'Roraima', url: '#' },
          ],
        },
      },
      {
        id: 'certidao_negativa_federal',
        name: 'CND Federal',
        link: 'https://solucoes.receita.fazenda.gov.br/Servicos/certidaointernet/PJ/Emitir',
      },
      {
        id: 'certidao_negativa_estadual',
        name: 'CND Estadual',
        link: null,
        modalLinks: {
          title: 'Certidão Negativa de Débitos por Estado',
          links: [
            {
              label: 'São Paulo',
              url: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/crda/emitirCrda.jsf',
            },
            {
              label: 'Rio de Janeiro',
              url: 'http://www4.fazenda.rj.gov.br/certidao-fiscal-web/emitirCertidao.jsf',
            },
            {
              label: 'Minas Gerais',
              url: 'https://www2.fazenda.mg.gov.br/sol/ctrl/SOL/CDT/SERVICO_829?ACAO=INICIAR',
            },
            {
              label: 'Paraná',
              url: 'http://www.cdw.fazenda.pr.gov.br/cdw/emissao/certidaoAutomatica',
            },
            {
              label: 'Rio Grande do Sul',
              url: 'https://www.sefaz.rs.gov.br/sat/CertidaoSitFiscalSolic.aspx',
            },
            { label: 'Bahia', url: 'https://servicos.sefaz.ba.gov.br/sistemas/DSCRE/' },
            {
              label: 'Santa Catarina',
              url: 'https://sat.sef.sc.gov.br/tax.NET/Sat.CtaCte.Web/SolicitacaoCnd.aspx',
            },
            {
              label: 'Pernambuco',
              url: 'https://efisco.sefaz.pe.gov.br/sfi_trb_gcc/PREmitirCertidaoRegularidadeFiscal',
            },
            { label: 'Ceará', url: 'https://servicos.sefaz.ce.gov.br/internet/index.asp' },
            {
              label: 'Distrito Federal',
              url: 'https://ww1.receita.fazenda.df.gov.br/cidadao/certidoes/emitir',
            },
            { label: 'Goiás', url: 'http://www.sefaz.go.gov.br/Certidao/Emissao/' },
            {
              label: 'Espírito Santo',
              url: 'https://internet.sefaz.es.gov.br/agenciavirtual/area_publica/cnd/emissao.php',
            },
            { label: 'Pará', url: 'https://app.sefa.pa.gov.br/emissao-certidao/' },
            {
              label: 'Maranhão',
              url: 'https://sistemas1.sefaz.ma.gov.br/portalsefaz/jsp/principal/principal.jsf',
            },
            { label: 'Paraíba', url: '#' },
            { label: 'Amazonas', url: '#' },
            { label: 'Mato Grosso', url: '#' },
            { label: 'Rio Grande do Norte', url: '#' },
            { label: 'Piauí', url: '#' },
            { label: 'Alagoas', url: '#' },
            { label: 'Mato Grosso do Sul', url: '#' },
            { label: 'Sergipe', url: '#' },
            { label: 'Rondônia', url: '#' },
            { label: 'Tocantins', url: '#' },
            { label: 'Acre', url: '#' },
            { label: 'Amapá', url: '#' },
            { label: 'Roraima', url: '#' },
          ],
        },
      },
      {
        id: 'certidao_negativa_municipal',
        name: 'CND Municipal',
        link: null,

        modalLinks: {
          title: 'Certidão Negativa de Débitos Municipal por Capital',
          links: [
            {
              label: 'São Paulo',
              url: 'https://duc.prefeitura.sp.gov.br/certidoes/forms_anonimo/frmConsultaEmissaoCertificado.aspx',
            },
            {
              label: 'Rio de Janeiro',
              url: 'https://www4.fazenda.rj.gov.br/certidao-fiscal-web/emitirCertidao.jsf',
            },
            { label: 'Belo Horizonte', url: 'https://cnd.pbh.gov.br/CNDOnline/' },
            { label: 'Curitiba', url: 'https://cnd-cidadao.curitiba.pr.gov.br/' },
            {
              label: 'Porto Alegre',
              url: 'https://prefeitura.poa.br/previmpa/certidoes-negativas',
            },
            {
              label: 'Salvador',
              url: 'https://servicosweb.sefaz.salvador.ba.gov.br/sistema/certidao_negativa/servicos_certidao_negativa.asp',
            },
            {
              label: 'Florianópolis',
              url: 'https://e-gov.betha.com.br/cdweb/03114-449/contribuinte/rel_cndcontribuinte.faces',
            },
            { label: 'Recife', url: 'https://certidoes.recife.pe.gov.br/certidoes/' },
            {
              label: 'Fortaleza',
              url: 'https://www.sefin.fortaleza.ce.gov.br/certidoes#?certidao-negativa-de-debitos-tributos-municipais',
            },
            {
              label: 'Brasília',
              url: 'https://ww1.receita.fazenda.df.gov.br/cidadao/certidoes/emitir',
            },
            {
              label: 'Goiânia',
              url: 'https://www.goiania.go.gov.br/sistemas/sccer/asp/sccer00300f0.asp',
            },
            {
              label: 'Vitória',
              url: 'https://fazenda.vitoria.es.gov.br/fazendaonline/certidao/certidao_negativa.cfm',
            },
            {
              label: 'Belém',
              url: 'http://www.belem.pa.gov.br/sefin/certidao-negativa-de-debitos/',
            },
            {
              label: 'São Luís',
              url: 'https://stm.semfaz.saoluis.ma.gov.br/credito/emitirCertidaoNegativa',
            },
            { label: 'João Pessoa', url: '#' },
            { label: 'Manaus', url: '#' },
            { label: 'Cuiabá', url: '#' },
            { label: 'Natal', url: '#' },
            { label: 'Teresina', url: '#' },
            { label: 'Maceió', url: '#' },
            { label: 'Campo Grande', url: '#' },
            { label: 'Aracaju', url: '#' },
            { label: 'Porto Velho', url: '#' },
            { label: 'Palmas', url: '#' },
            { label: 'Rio Branco', url: '#' },
            { label: 'Macapá', url: '#' },
            { label: 'Boa Vista', url: '#' },
          ],
        },
      },
      {
        id: 'certidao_negativa_trabalhista',
        name: 'CND Trabalhista',
        link: 'https://www.tst.jus.br/certidao1',
      },
      {
        id: 'certidao_negativa_fgts',
        name: 'CRF FGTS',
        link: 'https://consulta-crf.caixa.gov.br/consultacrf/pages/consultaEmpregador.jsf',
      },
      {
        id: 'certidao_simplificada',
        name: 'Certidão Simplificada',
        link: null,
        modalLinks: {
          title: 'Certidão Simplificada por Estado',
          links: [
            { label: 'Acre', url: 'https://www.juceac.ac.gov.br' },
            { label: 'Alagoas', url: 'https://www.juceal.al.gov.br' },
            { label: 'Amapá', url: 'https://www.jucap.ap.gov.br' },
            { label: 'Amazonas', url: 'https://www.jucea.am.gov.br' },
            { label: 'Bahia', url: 'https://www.juceb.ba.gov.br' },
            { label: 'Ceará', url: 'https://www.jucec.ce.gov.br' },
            { label: 'Distrito Federal', url: 'https://www.jcdf.df.gov.br' },
            { label: 'Espírito Santo', url: 'https://www.jucees.es.gov.br' },
            { label: 'Goiás', url: 'https://www.juceg.go.gov.br' },
            { label: 'Maranhão', url: 'https://www.jucema.ma.gov.br' },
            { label: 'Mato Grosso', url: 'https://www.jucemat.mt.gov.br' },
            { label: 'Mato Grosso do Sul', url: 'https://www.jucems.ms.gov.br' },
            { label: 'Minas Gerais', url: 'https://www.jucemg.mg.gov.br' },
            { label: 'Pará', url: 'https://www.jucepa.pa.gov.br' },
            { label: 'Paraíba', url: 'https://www.jucep.pb.gov.br' },
            { label: 'Paraná', url: 'https://www.juntacomercial.pr.gov.br' },
            { label: 'Pernambuco', url: 'https://www.jucepe.pe.gov.br' },
            { label: 'Piauí', url: 'https://www.jucepi.pi.gov.br' },
            { label: 'Rio de Janeiro', url: 'https://www.jucerja.rj.gov.br' },
            { label: 'Rio Grande do Norte', url: 'https://www.jucern.rn.gov.br' },
            { label: 'Rio Grande do Sul', url: 'https://jucergs.rs.gov.br' },
            { label: 'Rondônia', url: 'https://www.jucer.ro.gov.br' },
            { label: 'Roraima', url: 'https://www.jucerr.rr.gov.br' },
            { label: 'Santa Catarina', url: 'https://www.jucesc.sc.gov.br' },
            { label: 'São Paulo', url: 'https://www.jucesp.sp.gov.br' },
            { label: 'Sergipe', url: 'https://www.jucese.se.gov.br' },
            { label: 'Tocantins', url: 'https://www.jucetins.to.gov.br' },
          ],
        },
      },
    ],
  },
  {
    id: 'qualificacao_economica_financeira',
    title: 'Qualificação Econômico-Financeira',
    documents: [
      { id: 'balanco_patrimonial', name: 'Balanço patrimonial', link: null },
      { id: 'indices_contabeis', name: 'Índices Contábeis', link: null },
      {
        id: 'certidao_insolvencia_civil',
        name: 'Certidão de Insolvência Civil',
        link: null,
      },
      {
        id: 'capital_social',
        name: 'Declaração Capital Social ou Patrimônio Líquido',
        link: null,
      },
      {
        id: 'certidao_falencia_concordata',
        name: 'Certidão Negativa de Falência e Concordata',
        link: null,

        // link: 'https://www.tjdft.jus.br/servicos/certidao-nada-consta/tipos-de-certidao/certidao-falencias-concordatas-recuperacoes-judiciais',
      },
    ],
  },
  {
    id: 'qualificacao_tecnica',
    title: 'Qualificação Técnica',
    documents: [
      {
        id: 'atestado_capacidade_tecnica_profissional',
        name: 'Atestado(s) de Capacidade Técnica Profissional',
        link: null,
      },
      {
        id: 'atestado_capacidade_tecnica_operacional',
        name: 'Atestado(s) de Capacidade Técnica Operacional',
        link: null,
      },
      {
        id: 'inscricao_entidade_profissional',
        name: 'Inscrição na Entidade Profissional Competente',
        link: null,
      },
      { id: 'registro_orgao_regulamentador', name: 'Registro em órgão regulamentador', link: null },
      {
        id: 'contrato_prestacao_servico_engenheiro',
        name: 'Contrato de prestação de Serviço do Engenheiro',
        link: null,
      },
      {
        id: 'autorizacao_anvisa',
        name: 'Autorização Anvisa',
        link: 'https://www.gov.br/anvisa/pt-br/assuntos/paf/anuencia/importacao-de-produtos/formularios-e-modelos',
      },
      {
        id: 'crea_pessoa_fisica',
        name: 'CREA Pessoa Física',
        link: null,
        modalLinks: {
          title: 'CREA por Estado',
          links: [
            { label: 'Acre', url: 'https://servicos-crea-ac.sitac.com.br/' },
            { label: 'Alagoas', url: 'https://servicos-crea-al.sitac.com.br/index.php' },
            { label: 'Amazonas', url: 'https://servicos-crea-am.sitac.com.br/index.php' },
            { label: 'Amapá', url: 'https://servicos-crea-ap.sitac.com.br/' },
            { label: 'Bahia', url: 'https://servicos-crea-ba.sitac.com.br/' },
            { label: 'Ceará', url: 'https://servicos-crea-ce.sitac.com.br/' },
            { label: 'Distrito Federal', url: 'https://ged.creadf.org.br/login' },
            {
              label: 'Espírito Santo',
              url: 'https://creaes.org.br/ServicosOnline/pgLoginMaster.aspx?ReturnUrl=%2fservicosonline',
            },
            { label: 'Goiás', url: 'https://api.crea-go.org.br/usuario/login' },
            { label: 'Maranhão', url: 'https://servicos-crea-ma.sitac.com.br/' },
            { label: 'Minas Gerais', url: 'https://servicos-crea-mg.sitac.com.br/' },
            {
              label: 'Mato Grosso do Sul',
              url: 'https://ecrea.creams.org.br/Autenticacao/Login?ReturnUrl=%2f',
            },
            {
              label: 'Mato Grosso',
              url: 'https://ecrea.crea-mt.org.br/Autenticacao/Login?ReturnUrl=%2f',
            },
            { label: 'Pará', url: 'https://servicos-crea-pa.sitac.com.br/' },
            { label: 'Paraíba', url: 'https://servicos-crea-pb.sitac.com.br/' },
            { label: 'Pernambuco', url: 'https://servicos-crea-pe.sitac.com.br/' },
            { label: 'Piauí', url: 'https://sigec.crea-pi.org.br/sigec/servicosOnline/' },
            { label: 'Paraná', url: 'https://servicos.crea-pr.org.br/restrito/' },
            {
              label: 'Rio de Janeiro',
              url: 'https://creaonline.crea-rj.org.br/creaOnLine/home/efetuarloginAction.do?action=inicioEmpresa',
            },
            { label: 'Rio Grande do Norte', url: 'https://servicos-crea-rn.sitac.com.br/' },
            {
              label: 'Rondônia',
              url: 'https://www.crearo.org.br/plataforma-profissional-empresa/',
            },
            { label: 'Roraima', url: 'https://servicos-crea-rr.sitac.com.br/' },
            {
              label: 'Rio Grande do Sul',
              url: 'https://apolo.crea-rs.org.br:8443/apoloaplsrv01/servlet/org.crears.apolo.prod.hcrea_login',
            },
            { label: 'Santa Catarina', url: 'https://creanet.crea-sc.org.br/login' },
            { label: 'Sergipe', url: 'https://servicos-crea-se.sitac.com.br/' },
            { label: 'São Paulo', url: 'https://creanet1.creasp.org.br/Seguranca/Login.aspx' },
            { label: 'Tocantins', url: 'https://servicos-crea-to.sitac.com.br/' },
          ],
        },
      },
      {
        id: 'crea_pessoa_juridica',
        name: 'CREA Pessoa Jurídica',
        link: null,
        modalLinks: {
          title: 'CREA por Estado',
          links: [
            { label: 'Acre', url: 'https://servicos-crea-ac.sitac.com.br/' },
            { label: 'Alagoas', url: 'https://servicos-crea-al.sitac.com.br/index.php' },
            { label: 'Amazonas', url: 'https://servicos-crea-am.sitac.com.br/index.php' },
            { label: 'Amapá', url: 'https://servicos-crea-ap.sitac.com.br/' },
            { label: 'Bahia', url: 'https://servicos-crea-ba.sitac.com.br/' },
            { label: 'Ceará', url: 'https://servicos-crea-ce.sitac.com.br/' },
            { label: 'Distrito Federal', url: 'https://ged.creadf.org.br/login' },
            {
              label: 'Espírito Santo',
              url: 'https://creaes.org.br/ServicosOnline/pgLoginMaster.aspx?ReturnUrl=%2fservicosonline',
            },
            { label: 'Goiás', url: 'https://api.crea-go.org.br/usuario/login' },
            { label: 'Maranhão', url: 'https://servicos-crea-ma.sitac.com.br/' },
            { label: 'Minas Gerais', url: 'https://servicos-crea-mg.sitac.com.br/' },
            {
              label: 'Mato Grosso do Sul',
              url: 'https://ecrea.creams.org.br/Autenticacao/Login?ReturnUrl=%2f',
            },
            {
              label: 'Mato Grosso',
              url: 'https://ecrea.crea-mt.org.br/Autenticacao/Login?ReturnUrl=%2f',
            },
            { label: 'Pará', url: 'https://servicos-crea-pa.sitac.com.br/' },
            { label: 'Paraíba', url: 'https://servicos-crea-pb.sitac.com.br/' },
            { label: 'Pernambuco', url: 'https://servicos-crea-pe.sitac.com.br/' },
            { label: 'Piauí', url: 'https://sigec.crea-pi.org.br/sigec/servicosOnline/' },
            { label: 'Paraná', url: 'https://servicos.crea-pr.org.br/restrito/' },
            {
              label: 'Rio de Janeiro',
              url: 'https://creaonline.crea-rj.org.br/creaOnLine/home/efetuarloginAction.do?action=inicioEmpresa',
            },
            { label: 'Rio Grande do Norte', url: 'https://servicos-crea-rn.sitac.com.br/' },
            {
              label: 'Rondônia',
              url: 'https://www.crearo.org.br/plataforma-profissional-empresa/',
            },
            { label: 'Roraima', url: 'https://servicos-crea-rr.sitac.com.br/' },
            {
              label: 'Rio Grande do Sul',
              url: 'https://apolo.crea-rs.org.br:8443/apoloaplsrv01/servlet/org.crears.apolo.prod.hcrea_login',
            },
            { label: 'Santa Catarina', url: 'https://creanet.crea-sc.org.br/login' },
            { label: 'Sergipe', url: 'https://servicos-crea-se.sitac.com.br/' },
            { label: 'São Paulo', url: 'https://creanet1.creasp.org.br/Seguranca/Login.aspx' },
            { label: 'Tocantins', url: 'https://servicos-crea-to.sitac.com.br/' },
          ],
        },
      },
      {
        id: 'ibama',
        name: 'IBAMA',
        link: 'https://servicos.ibama.gov.br/sicafiext/sistema.php',
      },
    ],
  },
  {
    id: 'outros_documentos',
    title: 'Outros Documentos',
    documents: [
      {
        id: 'autorizacao_vigilancia_sanitaria',
        name: 'Autorização da Vigilância Sanitária',
        link: null,
      },
      {
        id: 'autorizacao_vigilancia_sanitaria',
        name: 'Autorização da Vigilância Sanitária',
        link: null,
      },
      {
        id: 'alvara_sanitario',
        name: 'Alvará Sanitário',
        link: null,
      },
      {
        id: 'licenca_ambiental',
        name: 'Licença ambiental',
        link: null,
        modalLinks: {
          title: 'Licença Ambiental por Estado',
          links: [
            { label: 'Acre', url: 'https://www.imac.acre.gov.br' },
            { label: 'Alagoas', url: 'https://www.ima.al.gov.br' },
            { label: 'Amapá', url: 'https://sema.portal.ap.gov.br' },
            { label: 'Amazonas', url: 'https://www.ipaam.am.gov.br' },
            { label: 'Bahia', url: 'https://www.inema.ba.gov.br' },
            { label: 'Ceará', url: 'https://www.sema.ce.gov.br' },
            { label: 'Distrito Federal', url: 'https://www.ibram.df.gov.br' },
            { label: 'Espírito Santo', url: 'https://iema.es.gov.br' },
            { label: 'Goiás', url: 'https://www.meioambiente.go.gov.br' },
            { label: 'Maranhão', url: 'https://www.sema.ma.gov.br' },
            { label: 'Mato Grosso', url: 'https://www.sema.mt.gov.br' },
            { label: 'Mato Grosso do Sul', url: 'https://www.imasul.ms.gov.br' },
            { label: 'Minas Gerais', url: 'https://www.meioambiente.mg.gov.br' },
            { label: 'Pará', url: 'https://www.semas.pa.gov.br' },
            { label: 'Paraíba', url: 'https://www.sudema.pb.gov.br' },
            { label: 'Paraná', url: 'https://www.iat.pr.gov.br' },
            { label: 'Pernambuco', url: 'https://www.cprh.pe.gov.br' },
            { label: 'Piauí', url: 'https://www.semar.pi.gov.br' },
            { label: 'Rio de Janeiro', url: 'https://www.inea.rj.gov.br' },
            { label: 'Rio Grande do Norte', url: 'https://www.idema.rn.gov.br' },
            { label: 'Rio Grande do Sul', url: 'https://www.fepam.rs.gov.br' },
            { label: 'Rondônia', url: 'https://www.sedam.ro.gov.br' },
            { label: 'Roraima', url: 'https://www.femarh.rr.gov.br' },
            { label: 'Santa Catarina', url: 'https://www.ima.sc.gov.br' },
            { label: 'São Paulo', url: 'https://cetesb.sp.gov.br' },
            { label: 'Sergipe', url: 'https://www.adema.se.gov.br' },
            { label: 'Tocantins', url: 'https://www.naturatins.to.gov.br' },
          ],
        },
      },
      {
        id: 'certificado_registro_cadastral',
        name: 'Certificado de Registro Cadastral (CRC – Demais Órgãos)',
        link: 'https://www3.comprasnet.gov.br/sicaf-web/public/pages/consultas/consultarCRC.jsf',
      },
      {
        id: 'certificado_registro_cadastral_sicaf',
        name: 'Certificado de Registro Cadastral (SICAF)',
        link: 'https://www3.comprasnet.gov.br/sicaf-web/public/pages/consultas/consultarCRC.jsf',
      },
      { id: 'comprovacao_visita_tecnica', name: 'Comprovação de visita técnica', link: null },
      {
        id: 'declaracao_simples_nacional',
        name: 'Declaração Simples Nacional',
        link: 'https://www8.receita.fazenda.gov.br/SimplesNacional/Servicos/Grupo.aspx?grp=8',
      },
    ],
  },
  {
    id: 'outras_declaracoes',
    title: 'Outras Declarações',
    documents: [
      {
        id: 'declaracao_nao_emprega_menor',
        name: 'Declaração de não-emprego de menores',
        link: null,
      },
      { id: 'declaracao_idoneidade', name: 'Declaração de Idoneidade', link: null },
      {
        id: 'declaracao_cumprimento_inciso_xxxiii',
        name: 'Declaração de Cumprimento do Inciso XXXIII',
        link: null,
      },
      {
        id: 'declaracao_inexistencia_fatos_impeditivos',
        name: 'Declaração de Inexistência de Fatos Impeditivos',
        link: null,
      },
      { id: 'declaracao_habilitacao', name: 'Declaração de Habilitação', link: null },
      {
        id: 'declaracao_renuncia_vistoria',
        name: 'Declaração de Renúncia de Vistoria',
        link: null,
      },
      {
        id: 'atestado_visita_tecnica',
        name: 'Atestado de Visita Técnica Facultativa',
        link: null,
      },
      {
        id: 'termo_aceitacao_condicoes_edital',
        name: 'Termo de Aceitação às Condições do Edital',
        link: null,
      },
      {
        id: 'declaracao_inexistencia_processo_falimentar',
        name: 'Declaração de Inexistência de Processo Falimentar',
        link: null,
      },
      { id: 'pedido_esclarecimentos', name: 'Pedido de Esclarecimentos', link: null },
      {
        id: 'declaracao_direitos_trabalhistas',
        name: 'Declaração de Direitos Trabalhistas',
        link: null,
      },
      {
        id: 'declaracao_servidor_publico',
        name: 'Declaração Servidor Público',
        link: null,
      },
      {
        id: 'declaracao_condenacao_5_anos',
        name: 'Declaração Condenação 5 Anos',
        link: null,
      },
      {
        id: 'declaracao_sancoes_vigentes',
        name: 'Declaração Sanções Vigentes',
        link: null,
      },
      {
        id: 'declaracao_unificada',
        name: 'Declaração Unificada',
        link: null,
      },
    ],
  },
];
