import ListControls from '@components/Layout/ListControls';
import Pagination, { PaginationInfo } from '@components/Layout/Pagination';
import Recommendations from '@components/Recommended/Recommendations';
import LicitacaoCard from '@components/Search/LicitacaoCard';
import LicitacaoCardSkeleton from '@components/Search/LicitacaoCardSkeleton';
import LicitacaoColumnCard from '@components/Search/LicitacaoColumnCard';
import LicitacaoColumnCardSkeleton from '@components/Search/LicitacaoColumnCardSkeleton';
import { useNotification } from '@contexts/NotificationContext';
import { useProfile } from '@contexts/ProfileContext';
import { useAnalytics } from '@hooks/useAnalytics';
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFavoritesStore } from '@store/favoritesStore';
import { useProfileStore } from '@store/profileStore';
import { scrollToTop } from '@utils/scroll';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

const FavoriteLicitacoes: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showNotification } = useNotification();
  const {
    favoriteLicitacoes,
    fetchFavoritesLicitacoes,
    isLoading,
    error,
    currentPage,
    setCurrentPage,
    totalFavorites,
    totalPages,
    itemsPerPage,
    setItemsPerPage,
    viewFormat,
    setViewFormat,
  } = useFavoritesStore();
  const { currentProfileId } = useProfile();
  const { userPreferences } = useProfileStore();
  const { trackEvent } = useAnalytics();
  const [searchParams, setSearchParams] = useSearchParams();

  const loadFavorites = useCallback(
    async (page: number, forceRefresh = false) => {
      try {
        await fetchFavoritesLicitacoes(page, forceRefresh);
        trackEvent('favorites_loaded', {
          page,
          total_favorites: totalFavorites,
          items_per_page: itemsPerPage,
          force_refresh: forceRefresh,
        });
      } catch (error) {
        trackEvent('favorites_load_error', {
          error_message: error instanceof Error ? error.message : String(error),
          page,
          force_refresh: forceRefresh,
        });
        showNotification('Erro ao carregar favoritos', 'error');
      }
    },
    [fetchFavoritesLicitacoes, trackEvent, totalFavorites, itemsPerPage, showNotification],
  );

  useEffect(() => {
    const pageParam = parseInt(searchParams.get('pagina') || '1', 10);
    if (!searchParams.has('pagina')) {
      searchParams.set('pagina', '1');
      setSearchParams(searchParams);
    } else if (pageParam !== currentPage) {
      setCurrentPage(pageParam);
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentProfileId) {
      const pageParam = parseInt(searchParams.get('pagina') || '1', 10);
      loadFavorites(pageParam);
      trackEvent('favorites_page_view', {
        total_favorites: totalFavorites,
      });
    }
  }, [currentProfileId]);

  useEffect(() => {
    const pageParam = parseInt(searchParams.get('pagina') || '1', 10);
    if (pageParam !== currentPage) {
      loadFavorites(pageParam);
    }
  }, [searchParams]);

  useEffect(() => {
    if (error) {
      trackEvent('favorites_error', {
        error_message: error,
        current_page: currentPage,
      });
      showNotification(error, 'error');
    }
  }, [error, showNotification]);

  const handleRefresh = useCallback(() => {
    trackEvent('favorites_refresh', {
      current_page: currentPage,
      total_favorites: totalFavorites,
    });
    loadFavorites(currentPage, true);
  }, [loadFavorites, currentPage, totalFavorites, trackEvent]);

  const effectiveViewFormat = isMobile ? 'column' : viewFormat;

  const handleViewFormatChange = useCallback(
    (newFormat: string) => {
      if (!isMobile) {
        trackEvent('favorites_view_format_change', {
          old_format: viewFormat,
          new_format: newFormat,
        });
        setViewFormat(newFormat as 'list' | 'column');
      }
    },
    [viewFormat, setViewFormat, trackEvent, isMobile],
  );

  const handleItemsPerPageChange = useCallback(
    (newValue: number) => {
      trackEvent('favorites_items_per_page_change', {
        old_value: itemsPerPage,
        new_value: newValue,
        total_favorites: totalFavorites,
      });
      setItemsPerPage(newValue);
    },
    [itemsPerPage, totalFavorites, setItemsPerPage, trackEvent],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      trackEvent('favorites_page_change', {
        old_page: currentPage,
        new_page: newPage,
        total_pages: totalPages,
      });
      searchParams.set('pagina', newPage.toString());
      setSearchParams(searchParams);
      scrollToTop('favorites-scroll-anchor');
    },
    [currentPage, totalPages, trackEvent, searchParams, setSearchParams],
  );

  const loadingSkeletons = useMemo(
    () => (
      <Grid container spacing={2}>
        {effectiveViewFormat === 'column' ? (
          <>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <LicitacaoColumnCardSkeleton />
              </Grid>
            ))}
          </>
        ) : (
          <Box sx={{ width: '100%' }}>
            {[...Array(5)].map((_, index) => (
              <LicitacaoCardSkeleton key={index} />
            ))}
          </Box>
        )}
      </Grid>
    ),
    [effectiveViewFormat],
  );

  const favoritesListContent = useMemo(
    () =>
      effectiveViewFormat === 'column' ? (
        <>
          {favoriteLicitacoes.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={`${item.id}-${currentPage}`}>
              <LicitacaoColumnCard
                className="favorite-card"
                item={item}
                isFirst={index === 0}
                searchKeywords={userPreferences?.keywords || []}
              />
            </Grid>
          ))}
        </>
      ) : (
        <Box sx={{ width: '100%' }}>
          {favoriteLicitacoes.map((item, index) => (
            <LicitacaoCard
              key={`${item.id}-${currentPage}`}
              className="favorite-card"
              item={item}
              isFirst={index === 0}
              searchKeywords={userPreferences?.keywords || []}
            />
          ))}
        </Box>
      ),
    [favoriteLicitacoes, currentPage, effectiveViewFormat],
  );

  return (
    <>
      <Helmet>
        <title>{`Licitações Favoritas (${totalFavorites}) - Licitou`}</title>
        <meta
          name="description"
          content="Acesse suas licitações favoritas e acompanhe as oportunidades mais relevantes"
        />
      </Helmet>
      <div id="favorites-scroll-anchor"></div>
      <Box className="favorites-list">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          className="favorites-header"
        >
          <Typography variant="h6" component="h2" color="primary">
            Licitações Favoritas
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <PaginationInfo
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={totalFavorites}
              itemsPerPage={itemsPerPage}
              isLoading={isLoading}
              id="favorites-scroll-anchor"
            />
            <ListControls
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
              viewFormat={effectiveViewFormat}
              onViewFormatChange={handleViewFormatChange}
              onRefresh={handleRefresh}
              disabled={isLoading}
            />
          </Box>
        </Box>
        <Grid container spacing={2} className="favorites-grid">
          {favoritesListContent}
        </Grid>

        {totalFavorites > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalFavorites}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
            isLoading={isLoading}
          />
        )}

        {isLoading && favoriteLicitacoes.length === 0 && loadingSkeletons}
        {!isLoading && !error && totalFavorites === 0 && (
          <>
            <Paper
              elevation={3}
              className="favorites-empty"
              onClick={() => trackEvent('empty_favorites_message_view')}
              sx={{
                p: 3,
                mt: 4,
                backgroundColor: theme.palette.background.paper,
                borderRadius: 2,
              }}
            >
              <Box display="flex" alignItems="center" mb={2}>
                <InfoIcon color="info" sx={{ mr: 2, fontSize: 40 }} />
                <Typography variant="h6" color="info.main">
                  Você ainda não tem licitações favoritas
                </Typography>
              </Box>
              <Typography color="text.secondary" paragraph>
                Ao navegar pelas licitações, clique no ícone <StarIcon color="primary" /> para
                marcar uma licitação como favorita.
              </Typography>
              <Typography color="text.secondary">
                Isso facilitará o acompanhamento das oportunidades mais relevantes para você.
                Explore as licitações disponíveis e comece a criar sua lista de favoritos!
              </Typography>
            </Paper>
            <Box mt={4}>
              <Recommendations title="Outras licitações que podem te interessar" />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default FavoriteLicitacoes;
