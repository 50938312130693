import { Box, Button, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { retry } from '@utils/retry';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

import { useColorScheme } from '@mui/material/styles';

// Immediately loaded components
import Bulletin from '@pages/Bulletin';
import Company from '@pages/Company';
import LicitacaoDetails from '@pages/Details';
import DocumentsTab from '@pages/Documents';
import Documents from '@pages/Documents/Documents';
import Following from '@pages/Following';
import FollowingList from '@pages/Following/Following';
import Login from '@pages/Login';
import MinhasLicitacoes from '@pages/MyLicitacoes';
import FavoriteLicitacoes from '@pages/MyLicitacoes/Favorite';
import NotFound from '@pages/NotFound';
import Search from '@pages/Search';
import Settings from '@pages/Settings';

import Loading from '@components/Layout/Loading';
import SuspenseLoading from '@components/Layout/SuspenseLoading';
import PrivateRoute from '@components/PrivateRoute';
import { AuthProvider, useAuth } from '@contexts/AuthContext';
import { NotificationProvider } from '@contexts/NotificationContext';
import { ProfileProvider } from '@contexts/ProfileContext';
import { TourProvider } from '@contexts/TourContext';
import { useAnalytics } from '@hooks/useAnalytics';
import AppTheme from '@theme/AppTheme';
import { ptBR } from 'date-fns/locale/pt-BR';

// Error fallback component
const ChunkErrorFallback = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
  >
    <Typography variant="h6" gutterBottom>
      Erro ao carregar o conteúdo
    </Typography>
    <Button variant="contained" onClick={() => window.location.reload()}>
      Recarregar página
    </Button>
  </Box>
);

// Retry logic for lazy loading
const retryLoadComponent = (componentImport: () => Promise<any>) =>
  retry({
    operation: () => componentImport(),
    maxRetries: 3,
    delay: (attempt: number) => Math.pow(1000, attempt) * 1000,
    onRetry: (attempt, error) => {
      console.error(`Erro ao carregar o componente: ${error.message}. Tentativa ${attempt}`);
    },
  });

// Lazy loaded components (heavy or less frequently used)
const FollowingAgenda = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "following-agenda" */ '@pages/Following/Agenda'),
  ),
);

const FollowingKanban = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "following-kanban" */ '@pages/Following/Kanban'),
  ),
);

const RecommendedLicitacoes = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "recommended" */ '@pages/MyLicitacoes/Recommended'),
  ),
);

const Companies = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "companies" */ '@pages/Company/Companies')),
);

const Payments = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "payments" */ '@pages/Settings/Payments')),
);

const Notifications = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "notifications" */ '@pages/Settings/Notifications'),
  ),
);

const UserProfile = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "user-profile" */ '@pages/Settings/UserProfile'),
  ),
);

const CompanyProfile = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "company-profile" */ '@pages/Company/Profile'),
  ),
);

const Preferences = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "preferences" */ '@pages/Company/Preferences'),
  ),
);

const Declarations = lazy(() =>
  retryLoadComponent(
    () => import(/* webpackChunkName: "declarations" */ '@pages/Documents/Declarations'),
  ),
);

const Proposal = lazy(() =>
  retryLoadComponent(() => import(/* webpackChunkName: "proposal" */ '@pages/Documents/Proposal')),
);

function AppContent() {
  const { user, isLoading, signInWithToken, getUser } = useAuth();
  const [isProcessingToken, setIsProcessingToken] = useState(false);
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const processedTokenRef = useRef(false);

  useEffect(() => {
    const handleSignInWithToken = async () => {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      const shouldStartTour =
        urlParams.get('onboarding') === 'true' || urlParams.get('tour') === '1';

      if (token && !isProcessingToken && !processedTokenRef.current) {
        setIsProcessingToken(true);
        processedTokenRef.current = true;

        try {
          await signInWithToken(token);
          trackEvent('user_sign_in', { method: 'token' });

          if (shouldStartTour) {
            trackEvent('start_tour');
            const searchParams = new URLSearchParams();
            searchParams.set('tour', '1');
            window.location.href = `/busca?${searchParams.toString()}`;
          } else {
            const newUrl =
              window.location.pathname +
              (location.search ? '?' + location.search.replace(/[?&]token=[^&]+/, '') : '');
            window.history.replaceState({}, '', newUrl);
          }
        } catch (error) {
          trackEvent('sign_in_error', { error: (error as Error).message });
          console.error('Error signing in with token:', error);
        } finally {
          setIsProcessingToken(false);
        }
      }
    };

    handleSignInWithToken();
  }, [location.search, signInWithToken, isProcessingToken, trackEvent]);

  if (isLoading || isProcessingToken) {
    return <Loading withHeader={true} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ChunkErrorFallback}>
      <Routes>
        <Route path="/login" element={user ? <Navigate replace to="/busca" /> : <Login />} />

        <Route
          path="/empresa"
          element={
            <PrivateRoute>
              <Company />
            </PrivateRoute>
          }
        >
          <Route
            path="perfil"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <CompanyProfile />
              </Suspense>
            }
          />
          <Route
            path="preferencias"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <Preferences />
              </Suspense>
            }
          />
          <Route
            path="multi"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <Companies />
              </Suspense>
            }
          />
          <Route index element={<Navigate replace to="perfil" />} />
        </Route>

        <Route
          path="/configuracoes"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        >
          <Route
            path="pagamento"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <Payments />
              </Suspense>
            }
          />
          <Route
            path="perfil"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <UserProfile />
              </Suspense>
            }
          />
          <Route
            path="notificacoes"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <Notifications />
              </Suspense>
            }
          />
          <Route index element={<Navigate replace to="pagamento" />} />
        </Route>

        <Route
          path="/documentos"
          element={
            <PrivateRoute>
              <DocumentsTab />
            </PrivateRoute>
          }
        >
          <Route path="documentos" element={<Documents />} />
          <Route
            path="declaracoes"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <Declarations />
              </Suspense>
            }
          />
          <Route
            path="proposta"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <Proposal />
              </Suspense>
            }
          />
          <Route index element={<Navigate replace to="documentos" />} />
        </Route>

        <Route
          path="/busca"
          element={
            <PrivateRoute>
              <Search />
            </PrivateRoute>
          }
        />

        <Route
          path="/recomendadas"
          element={
            <PrivateRoute>
              <Suspense fallback={<SuspenseLoading />}>
                <RecommendedLicitacoes />
              </Suspense>
            </PrivateRoute>
          }
        />

        <Route
          path="/minhas-licitacoes"
          element={
            <PrivateRoute>
              <MinhasLicitacoes />
            </PrivateRoute>
          }
        >
          <Route
            path="recomendadas"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <RecommendedLicitacoes />
              </Suspense>
            }
          />
          <Route path="favoritas" element={<FavoriteLicitacoes />} />
          <Route index element={<Navigate replace to="recomendadas" />} />
        </Route>

        <Route
          path="/licitacao/:id_licitacao"
          element={
            <PrivateRoute>
              <LicitacaoDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/boletins"
          element={
            <PrivateRoute>
              <Bulletin />
            </PrivateRoute>
          }
        />
        <Route
          path="/boletins/:bulletinId"
          element={
            <PrivateRoute>
              <Bulletin />
            </PrivateRoute>
          }
        />

        <Route
          path="/acompanhando"
          element={
            <PrivateRoute>
              <Following />
            </PrivateRoute>
          }
        >
          <Route path="lista" element={<FollowingList />} />
          <Route
            path="kanban"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <FollowingKanban />
              </Suspense>
            }
          />
          <Route
            path="agenda"
            element={
              <Suspense fallback={<SuspenseLoading />}>
                <FollowingAgenda />
              </Suspense>
            }
          />
          <Route index element={<Navigate replace to="lista" />} />
        </Route>

        <Route
          path="/"
          element={
            isProcessingToken ? (
              <SuspenseLoading />
            ) : (
              <Navigate replace to={getUser() ? '/busca' : '/login'} />
            )
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </ErrorBoundary>
  );
}

function AppWithRouter() {
  const { mode } = useColorScheme();
  const location = useLocation();

  const isLoginPage = location.pathname === '/login';
  const themeMode = isLoginPage ? 'dark' : mode;

  return (
    <AppTheme mode={themeMode}>
      <AppContent />
    </AppTheme>
  );
}

function App() {
  return (
    <HelmetProvider>
      <Helmet defaultTitle="Licitou" titleTemplate="%s - Licitou">
        <meta name="description" content="Plataforma de licitações" />
      </Helmet>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
        <Router>
          <AuthProvider>
            <ProfileProvider>
              <TourProvider>
                <NotificationProvider>
                  <AppWithRouter />
                </NotificationProvider>
              </TourProvider>
            </ProfileProvider>
          </AuthProvider>
        </Router>
      </LocalizationProvider>
    </HelmetProvider>
  );
}

export default App;
