import CustomButton from '@components/Input/CustomButton';
import { getModalidadeName } from '@constants';
import { useAnalytics } from '@hooks/useAnalytics';
import { Event as EventIcon } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { Licitacao, RecommendedLicitacao } from '@types';
import React, { useState } from 'react';
type CombinedLicitacao = Licitacao | RecommendedLicitacao;

interface CalendarButtonProps {
  licitacao: CombinedLicitacao;
  dataTour?: string;
}

const getGoogleCalendarLink = (licitacao: CombinedLicitacao) => {
  const text = encodeURIComponent(`Licitação: ${licitacao.titulo}`);
  const details = encodeURIComponent(
    `Órgão: ${licitacao.orgao}\nModalidade: ${licitacao.tipo || getModalidadeName(licitacao.id_tipo)}\nObjeto: ${licitacao.objeto}`,
  );

  let dates = '';
  if (licitacao.abertura_datetime) {
    try {
      const date = new Date(licitacao.abertura_datetime);
      if (!isNaN(date.getTime())) {
        const formattedDate = date.toISOString().replace(/-|:|\.\d\d\d/g, '');
        dates = encodeURIComponent(`${formattedDate}/${formattedDate}`);
      }
    } catch (error) {
      console.error('Error parsing abertura_datetime:', error);
    }
  }

  const location = encodeURIComponent(`${licitacao.municipio} - ${licitacao.uf}`);
  return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${text}&details=${details}&dates=${dates}&location=${location}`;
};

const getOutlookCalendarLink = (licitacao: CombinedLicitacao) => {
  const startDate = new Date(licitacao.abertura_datetime || new Date());
  const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // Assume 1 hour duration
  const subject = encodeURIComponent(`Licitação: ${licitacao.titulo}`);
  const body = encodeURIComponent(`Detalhes da licitação: ${window.location.href}`);
  const location = encodeURIComponent(`${licitacao.municipio} - ${licitacao.uf}`);

  return `https://outlook.live.com/calendar/0/deeplink/compose?subject=${subject}&body=${body}&startdt=${startDate.toISOString()}&enddt=${endDate.toISOString()}&location=${location}&path=/calendar/action/compose&rru=addevent`;
};

const CalendarButton: React.FC<CalendarButtonProps> = ({ licitacao, dataTour }) => {
  const { trackEvent } = useAnalytics();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { addFollowing, isFollowing } = useFollowingStore();
  const { addFavorite, isFavorite } = useFavoritesStore();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    trackEvent('calendar_menu_opened', {
      licitacao_id: licitacao.id,
      portal: licitacao.portal,
      is_following: isFollowing(licitacao.id),
      is_favorite: isFavorite(licitacao.id),
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    trackEvent('calendar_menu_closed', {
      licitacao_id: licitacao.id,
      was_action_taken: false,
    });
    setAnchorEl(null);
  };

  const handleAddToCalendar = async () => {
    try {
      if (!isFavorite(licitacao.id)) {
        await addFavorite(licitacao.id);
        trackEvent('licitacao_favorited', {
          licitacao_id: licitacao.id,
          from_calendar: true,
        });
      }
      if (!isFollowing(licitacao.id)) {
        await addFollowing(licitacao.id);
        trackEvent('licitacao_followed', {
          licitacao_id: licitacao.id,
          from_calendar: true,
        });
      }
      trackEvent('added_to_licitou_calendar', {
        licitacao_id: licitacao.id,
        was_favorite: isFavorite(licitacao.id),
        was_following: isFollowing(licitacao.id),
      });
      handleClose();
    } catch (error) {
      trackEvent('calendar_action_error', {
        licitacao_id: licitacao.id,
        error_message: error instanceof Error ? error.message : String(error),
        action: 'licitou_calendar',
      });
    }
  };

  const handleGoogleCalendar = () => {
    trackEvent('added_to_google_calendar', {
      licitacao_id: licitacao.id,
      has_datetime: Boolean(licitacao.abertura_datetime),
    });
    window.open(getGoogleCalendarLink(licitacao), '_blank');
    handleClose();
  };

  const handleOutlookCalendar = () => {
    trackEvent('added_to_outlook_calendar', {
      licitacao_id: licitacao.id,
      has_datetime: Boolean(licitacao.abertura_datetime),
    });
    window.open(getOutlookCalendarLink(licitacao), '_blank');
    handleClose();
  };

  return (
    <>
      <CustomButton
        dataTour={dataTour}
        icon={<EventIcon />}
        text="Adicionar à agenda"
        onClick={handleClick}
        backgroundColor="#0288d1"
        hoverColor="#01579b"
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleGoogleCalendar}>Agenda do Google</MenuItem>
        <MenuItem onClick={handleOutlookCalendar}>Agenda do Outlook</MenuItem>
        <MenuItem onClick={handleAddToCalendar} disabled={isFollowing(licitacao.id)}>
          Agenda do Licitou (Favoritar e Acompanhar)
        </MenuItem>
      </Menu>
    </>
  );
};

export default CalendarButton;
