import CurrencyInput from '@components/Input/CurrencyInput';
import {
  Add as AddIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import { Licitacao } from '@types';
import { formatCurrency } from '@utils';
import React, { useState } from 'react';
import { AddCustomItemModal } from './AddCustomItemModal';
import { FollowingItemsAutocomplete } from './FollowingItemsAutocomplete';

interface FollowingItemsListProps {
  licitacao: Licitacao;
}

const calculateItemTotal = (price: number | undefined, quantity: number) => {
  return price ? price * quantity : 0;
};

interface FieldUpdate {
  price?: string;
  marca?: string;
  fabricante?: string;
}

export const FollowingItemsList: React.FC<FollowingItemsListProps> = ({ licitacao }) => {
  const { getSelectedItems, updateItem, addItemToFollowing, removeItemFromFollowing } =
    useFollowingStore();
  const [localFields, setLocalFields] = useState<Record<string, FieldUpdate>>({});
  const [savingStates, setSavingStates] = useState<{
    [itemId: string]: 'saving' | 'success' | 'error' | null;
  }>({});
  const [isCustomItemModalOpen, setIsCustomItemModalOpen] = useState(false);

  const selectedItems = getSelectedItems(licitacao.id);

  const handleFieldChange = (itemId: string, field: keyof FieldUpdate, value: string) => {
    setLocalFields((prev) => ({
      ...prev,
      [itemId]: { ...(prev[itemId] || {}), [field]: value },
    }));
    setSavingStates((prev) => ({ ...prev, [itemId]: null }));
  };

  const handleFieldBlur = async (itemId: string, field: keyof FieldUpdate) => {
    const value = localFields[itemId]?.[field];
    if (!value) return;

    setSavingStates((prev) => ({ ...prev, [itemId]: 'saving' }));
    try {
      const updateData = {
        customPrice: field === 'price' ? parseFloat(value) : undefined,
        customMarca: field === 'marca' ? value : undefined,
        customFabricante: field === 'fabricante' ? value : undefined,
      };

      await updateItem(licitacao.id, itemId, updateData);
      setSavingStates((prev) => ({ ...prev, [itemId]: 'success' }));
      setTimeout(() => {
        setSavingStates((prev) => ({ ...prev, [itemId]: null }));
      }, 2000);
    } catch {
      setSavingStates((prev) => ({ ...prev, [itemId]: 'error' }));
      setTimeout(() => {
        setSavingStates((prev) => ({ ...prev, [itemId]: null }));
      }, 3000);
    }
  };

  const grandTotal = selectedItems.reduce((total, item) => {
    const price = localFields[item.id]?.price
      ? parseFloat(localFields[item.id]?.price || '')
      : item.customPrice;
    return total + calculateItemTotal(price, item.quantidade);
  }, 0);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      {licitacao.items?.length === 0 ? (
        <Paper
          elevation={3}
          sx={{
            p: 3,
            backgroundColor: 'background.paper',
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <InfoIcon color="info" sx={{ fontSize: 48 }} />
            <Typography variant="h6" color="info.main">
              Esta licitação não possui itens cadastrados
            </Typography>
            <Typography color="text.secondary">
              Não encontramos itens cadastrados para esta licitação no portal.
            </Typography>
            <Typography color="text.secondary">
              Você pode adicionar itens manualmente para acompanhar seus lances.
            </Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setIsCustomItemModalOpen(true)}
              variant="contained"
              color="primary"
              sx={{ mt: 1 }}
            >
              Adicionar Item Personalizado
            </Button>
          </Box>
        </Paper>
      ) : (
        <>
          {/* Search Section */}
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
              <FollowingItemsAutocomplete
                items={licitacao.items || []}
                selectedItems={selectedItems}
                onItemSelect={(item) => addItemToFollowing(licitacao.id, item)}
              />
            </Box>
            <Typography variant="caption" color="text.primary">
              {' '}
              Ou{' '}
            </Typography>
            <Button
              startIcon={<AddIcon />}
              onClick={() => setIsCustomItemModalOpen(true)}
              variant="outlined"
              size="medium"
            >
              Adicionar Item Personalizado
            </Button>
          </Box>

          {/* Selected Items Section */}
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="h6">Itens Selecionados ({selectedItems.length})</Typography>
              <Typography variant="h6">Total: {formatCurrency(grandTotal)}</Typography>
            </Box>

            {selectedItems.length > 0 ? (
              <List>
                {selectedItems.map((item) => (
                  <ListItem
                    key={item.id}
                    sx={{
                      bgcolor: 'background.paper',
                      mb: 1,
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'divider',
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'row',
                      p: 2,
                      gap: 2,
                    }}
                  >
                    <IconButton
                      size="small"
                      onClick={() => removeItemFromFollowing(licitacao.id, item.id)}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'error.main',
                        zIndex: 1,
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>

                    <Box sx={{ width: '50%' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        Item {item.numeroItem}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          display: '-webkit-box',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          mb: 1,
                        }}
                      >
                        {item.descricao}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Quantidade: {item.quantidade} {item.unidadeMedida}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Valor unitário estimado: {formatCurrency(item.valorUnitarioEstimado)}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Valor total estimado:{' '}
                        {formatCurrency(item.valorUnitarioEstimado * item.quantidade)}
                      </Typography>
                    </Box>

                    {/* Right Column - Custom Fields */}
                    <Box
                      sx={{
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        position: 'relative',
                        pr: 3,
                      }}
                    >
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                          size="small"
                          label="Marca"
                          value={localFields[item.id]?.marca || item.customMarca || ''}
                          onChange={(e) => handleFieldChange(item.id, 'marca', e.target.value)}
                          onBlur={() => handleFieldBlur(item.id, 'marca')}
                          sx={{ flex: 1 }}
                        />

                        <TextField
                          size="small"
                          label="Fabricante"
                          value={localFields[item.id]?.fabricante || item.customFabricante || ''}
                          onChange={(e) => handleFieldChange(item.id, 'fabricante', e.target.value)}
                          onBlur={() => handleFieldBlur(item.id, 'fabricante')}
                          sx={{ flex: 1 }}
                        />
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <CurrencyInput
                              size="small"
                              label="Seu lance (unit.)"
                              value={
                                localFields[item.id]?.price ?? item.customPrice?.toString() ?? ''
                              }
                              onChange={(value) => handleFieldChange(item.id, 'price', value)}
                              onBlur={() => handleFieldBlur(item.id, 'price')}
                              sx={{ width: '150px' }}
                            />
                            <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 20 }}>
                              {savingStates[item.id] === 'saving' && <CircularProgress size={16} />}
                              {savingStates[item.id] === 'success' && (
                                <CheckCircleIcon color="success" fontSize="small" />
                              )}
                              {savingStates[item.id] === 'error' && (
                                <ErrorIcon color="error" fontSize="small" />
                              )}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                              flex: 1,
                            }}
                          >
                            <Typography variant="caption" color="text.secondary">
                              Valor total do seu lance
                            </Typography>
                            <Typography variant="subtitle2" color="primary" fontWeight="medium">
                              {formatCurrency(
                                calculateItemTotal(
                                  localFields[item.id]?.price
                                    ? parseFloat(localFields[item.id]?.price || '')
                                    : item.customPrice,
                                  item.quantidade,
                                ),
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  backgroundColor: 'background.paper',
                  borderRadius: 2,
                  textAlign: 'center',
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                  <InfoIcon color="info" sx={{ fontSize: 48 }} />
                  <Typography variant="h6" color="info.main">
                    Nenhum item selecionado
                  </Typography>
                  <Typography color="text.secondary">
                    Você ainda não selecionou nenhum item para acompanhar nesta licitação.
                  </Typography>
                  <Typography color="text.secondary">
                    Selecione itens da licitação ou adicione itens personalizados para começar a
                    acompanhar.
                  </Typography>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => setIsCustomItemModalOpen(true)}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1 }}
                  >
                    Adicionar Item Personalizado
                  </Button>
                </Box>
              </Paper>
            )}
          </Box>
        </>
      )}

      <AddCustomItemModal
        open={isCustomItemModalOpen}
        onClose={() => setIsCustomItemModalOpen(false)}
        licitacaoId={licitacao.id}
      />
    </Box>
  );
};
