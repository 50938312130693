import DocumentEditModal from '@components/Documents/DocumentEditModal';
import { TIME_ZONE } from '@constants';
import { DECLARATIONS } from '@constants/templates';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useDocumentsStore } from '@store/documentsStore';
import { DocumentStatus, Document as DocumentType, SectionDocument } from '@types';
import { DOCUMENT_SECTIONS } from 'constants/documents';
import { differenceInDays, isAfter, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

interface DocumentSectionsProps {
  documents: DocumentType[];
  isLoading: boolean;
  handleDownloadSelected: (selectedDocuments: string[]) => void;
  handleDownloadAll: (sectionId?: string) => void;
  handleDownload: (documentId: string, documentName: string, childId?: string) => void;
  handleDelete: (documentId: string) => void;
}

const DocumentSections: React.FC<DocumentSectionsProps> = ({
  documents,
  isLoading,
  handleDownloadSelected,
  handleDownloadAll,
  handleDownload,
  handleDelete,
}) => {
  const {
    uploadDocument,
    updateDocument,
    groupDocumentsBySection,
    uploadChildDocuments,
    deleteChildDocument,
    updateChildDocument,
  } = useDocumentsStore();
  const { trackEvent } = useAnalytics();
  const { showNotification } = useNotification();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<SectionDocument['modalLinks'] | null>(null);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingDocument, setEditingDocument] = useState<{
    id: string;
    customName: string;
    documentNumber: string;
    generateDate: Date | null;
    expireDate: Date | null;
    documents?: Array<{ id: string; name: string; customName?: string }>;
  } | null>(null);

  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<Set<string>>(new Set());

  const [editingChildId, setEditingChildId] = useState<string | null>(null);
  const [editingChildName, setEditingChildName] = useState('');

  const groupedDocuments = useMemo(() => groupDocumentsBySection(), [documents]);

  const handleUpload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, type: string, section: string) => {
      if (event.target.files && event.target.files[0]) {
        try {
          const uploadedDoc = await uploadDocument(event.target.files[0], type, section);
          trackEvent('document_uploaded', {
            document_type: type,
            section,
            file_size: event.target.files[0].size,
            file_type: event.target.files[0].type,
          });
          showNotification('Documento enviado com sucesso', 'success');
          handleOpenEditModal(uploadedDoc as unknown as DocumentType);
        } catch (error) {
          trackEvent('document_upload_error', {
            document_type: type,
            section,
            error_message: error instanceof Error ? error.message : String(error),
          });
          showNotification('Erro ao enviar documento', 'error');
        }
      }
    },
    [uploadDocument],
  );

  const getStatusColor = useCallback((status: DocumentStatus | null, expireDate: string | null) => {
    if (!status || status === DocumentStatus.AUSENTE) return 'default';
    if (!expireDate) return 'success';

    const today = new Date();
    const expirationDate = parseISO(expireDate);

    if (isAfter(today, expirationDate)) return 'error';
    const daysUntilExpiration = differenceInDays(expirationDate, today);
    if (daysUntilExpiration <= 10) return 'warning';
    return 'success';
  }, []);

  const getStatusLabel = useCallback((status: DocumentStatus | null, expireDate: string | null) => {
    if (!status || status === DocumentStatus.AUSENTE) return 'Ausente';
    if (!expireDate) return 'Válido';

    const today = new Date();
    const expirationDate = parseISO(expireDate);

    if (isAfter(today, expirationDate)) return 'Vencido';
    const daysUntilExpiration = differenceInDays(expirationDate, today);
    if (daysUntilExpiration <= 10) return 'Vencendo';
    return 'Válido';
  }, []);

  const handleOpenModal = (content: SectionDocument['modalLinks'] | undefined) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenEditModal = (document: DocumentType) => {
    setEditingDocument({
      id: document.id,
      customName: document.customName || '',
      documentNumber: document.documentNumber || '',
      generateDate: document.generateDate ? parseISO(document.generateDate) : null,
      expireDate: document.expireDate ? parseISO(document.expireDate) : null,
      documents: document.documents || [],
    });
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setEditingDocument(null);
  };

  const handleSaveEdit = async () => {
    if (editingDocument) {
      try {
        await updateDocument(editingDocument.id, {
          customName: editingDocument.customName,
          documentNumber: editingDocument.documentNumber,
          generateDate: editingDocument.generateDate
            ? format(new Date(editingDocument.generateDate.setHours(12)), 'yyyy-MM-dd')
            : null,
          expireDate: editingDocument.expireDate
            ? format(new Date(editingDocument.expireDate.setHours(12)), 'yyyy-MM-dd')
            : null,
        });
        showNotification('Documento atualizado com sucesso', 'success');
        handleCloseEditModal();
      } catch (error) {
        console.error('Error updating document:', error);
        showNotification('Erro ao atualizar documento', 'error');
      }
    }
  };

  const handleUploadChild = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>, parentId: string) => {
      if (event.target.files && event.target.files.length > 0) {
        try {
          const files = Array.from(event.target.files);
          const newChildDocs = await uploadChildDocuments(parentId, files);

          setEditingDocument((prev) =>
            prev
              ? {
                  ...prev,
                  documents: [...(prev.documents || []), ...newChildDocs],
                }
              : prev,
          );

          showNotification('Anexos enviados com sucesso', 'success');
        } catch (error) {
          console.error('Error uploading child documents:', error);
          showNotification('Erro ao enviar anexos', 'error');
        }
      }
    },
    [uploadChildDocuments],
  );

  const handleDeleteChild = useCallback(
    async (parentId: string, childId: string) => {
      try {
        await deleteChildDocument(parentId, childId);

        setEditingDocument((prev) =>
          prev
            ? {
                ...prev,
                documents: prev.documents?.filter((doc) => doc.id !== childId) || [],
              }
            : prev,
        );

        showNotification('Documento filho excluído com sucesso', 'success');
      } catch (error) {
        console.error('Error deleting child document:', error);
        showNotification('Erro ao excluir documento filho', 'error');
      }
    },
    [deleteChildDocument],
  );

  const handleExpandRow = useCallback((documentId: string) => {
    setExpandedRows((prev) => {
      const next = new Set(prev);
      if (next.has(documentId)) {
        next.delete(documentId);
      } else {
        next.add(documentId);
      }
      trackEvent('document_row_expanded', {
        document_id: documentId,
        is_expanded: !prev.has(documentId),
      });
      return next;
    });
  }, []);

  const toggleSelectionMode = () => {
    setSelectionMode((prev) => !prev);
    setSelectedDocuments(new Set());
  };

  const handleSelectDocument = useCallback((documentId: string) => {
    setSelectedDocuments((prev) => {
      const next = new Set(prev);
      if (next.has(documentId)) {
        next.delete(documentId);
      } else {
        next.add(documentId);
      }
      trackEvent('document_selected', {
        document_id: documentId,
        is_selected: !prev.has(documentId),
        total_selected: next.size,
      });
      return next;
    });
  }, []);

  const handleUpdateChildName = useCallback(
    async (documentId: string, childId: string) => {
      if (!editingChildName.trim()) {
        showNotification('O nome do documento não pode estar vazio', 'error');
        return;
      }

      try {
        await updateChildDocument(documentId, childId, { customName: editingChildName });
        trackEvent('document_child_renamed', {
          parent_id: documentId,
          child_id: childId,
          new_name_length: editingChildName.length,
        });
        setEditingChildId(null);
        setEditingChildName('');
        showNotification('Nome do documento atualizado com sucesso', 'success');
      } catch (error) {
        showNotification('Erro ao atualizar nome do documento', 'error');
        trackEvent('document_child_rename_error', {
          parent_id: documentId,
          child_id: childId,
          error_message: error instanceof Error ? error.message : String(error),
        });
      }
    },
    [editingChildName, updateChildDocument, showNotification],
  );

  const handleSelectSection = useCallback(
    (sectionId: string) => {
      const sectionDocs = groupedDocuments[sectionId] || [];
      const allSelected = sectionDocs.every((doc) => selectedDocuments.has(doc.id));

      setSelectedDocuments((prev) => {
        const next = new Set(prev);
        sectionDocs.forEach((doc) => {
          if (allSelected) {
            next.delete(doc.id);
          } else {
            next.add(doc.id);
          }
        });
        return next;
      });
    },
    [groupedDocuments, selectedDocuments],
  );
  return (
    <Box>
      <Box
        className="documents-header"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" component="h1" color="primary">
          Meus Documentos
        </Typography>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            onClick={toggleSelectionMode}
            startIcon={selectionMode ? <CloseIcon /> : <CheckBoxIcon />}
          >
            {selectionMode ? 'Cancelar Seleção' : 'Selecionar Documentos'}
          </Button>
          {selectionMode ? (
            <Button
              variant="contained"
              startIcon={<CloudDownloadIcon />}
              onClick={() => {
                handleDownloadSelected(Array.from(selectedDocuments));
                setSelectionMode(false);
                setSelectedDocuments(new Set());
              }}
              disabled={selectedDocuments.size === 0}
            >
              Baixar Selecionados ({selectedDocuments.size})
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<CloudDownloadIcon />}
              onClick={() => handleDownloadAll()}
              disabled={isLoading || documents.length === 0}
            >
              Baixar todos os documentos
            </Button>
          )}
        </Box>
      </Box>
      {DOCUMENT_SECTIONS.map((section, index) => {
        const sectionDocuments = groupedDocuments[section.id] || [];
        return (
          <Accordion key={section.id} className="documents-section" defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography variant="h6">{section.title}</Typography>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<CloudDownloadIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadAll(section.id);
                  }}
                  disabled={isLoading || sectionDocuments.length === 0}
                  sx={{ mr: 2 }}
                >
                  Baixar Todos
                </Button>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {selectionMode && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={groupedDocuments[section.id]?.every((doc) =>
                              selectedDocuments.has(doc.id),
                            )}
                            indeterminate={
                              groupedDocuments[section.id]?.some((doc) =>
                                selectedDocuments.has(doc.id),
                              ) &&
                              !groupedDocuments[section.id]?.every((doc) =>
                                selectedDocuments.has(doc.id),
                              )
                            }
                            onChange={() => handleSelectSection(section.id)}
                          />
                        </TableCell>
                      )}
                      <TableCell width="30%">Documento</TableCell>
                      <TableCell width="15%">Situação</TableCell>
                      <TableCell width="20%"></TableCell>
                      <TableCell width="15%">Fonte Oficial</TableCell>
                      <TableCell width="20%">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {section.documents.map((doc) => {
                      const uploadedDoc = groupedDocuments[section.id].find(
                        (d) => d.type === doc.id,
                      );
                      return (
                        <>
                          <TableRow key={doc.id}>
                            {selectionMode && (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedDocuments.has(uploadedDoc?.id || '')}
                                  onChange={() =>
                                    uploadedDoc && handleSelectDocument(uploadedDoc.id)
                                  }
                                  disabled={!uploadedDoc}
                                />
                              </TableCell>
                            )}
                            <TableCell>
                              {doc.name}
                              {uploadedDoc &&
                                uploadedDoc?.documents &&
                                uploadedDoc?.documents?.length > 0 && (
                                  <IconButton
                                    size="small"
                                    onClick={() => handleExpandRow(uploadedDoc.id)}
                                    sx={{ ml: 1 }}
                                  >
                                    {expandedRows.has(uploadedDoc.id) ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                )}
                            </TableCell>
                            <TableCell className="document-status">
                              <Chip
                                label={getStatusLabel(
                                  (uploadedDoc?.status as DocumentStatus) || DocumentStatus.AUSENTE,
                                  uploadedDoc?.expireDate || null,
                                )}
                                color={getStatusColor(
                                  (uploadedDoc?.status as DocumentStatus) || DocumentStatus.AUSENTE,
                                  uploadedDoc?.expireDate || null,
                                )}
                                size="small"
                              />
                            </TableCell>
                            <TableCell className="document-dates">
                              {uploadedDoc?.generateDate && (
                                <Typography variant="caption" display="block">
                                  Emissão:{' '}
                                  {format(
                                    new Date(uploadedDoc.generateDate + 'T12:00:00'),
                                    'dd/MM/yyyy',
                                  )}
                                </Typography>
                              )}
                              {uploadedDoc?.expireDate && (
                                <Typography variant="caption" display="block" sx={{ mt: 0.5 }}>
                                  Validade:{' '}
                                  {format(
                                    new Date(uploadedDoc.expireDate + 'T12:00:00'),
                                    'dd/MM/yyyy',
                                  )}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell className="document-external">
                              {doc.link ? (
                                <Button
                                  href={doc.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  startIcon={<OpenInNewIcon />}
                                  size="small"
                                >
                                  Acessar
                                </Button>
                              ) : (section.id === 'outros_documentos' ||
                                  section.id === 'outras_declaracoes') &&
                                DECLARATIONS.some((d) => d.id === doc.id) ? (
                                <Button
                                  component={Link}
                                  to={`/documentos/declaracoes?declaration=${doc.id}`}
                                  variant="outlined"
                                  size="small"
                                  startIcon={<OpenInNewIcon />}
                                >
                                  Criar Declaração
                                </Button>
                              ) : doc.modalLinks ? (
                                <Button
                                  onClick={() => handleOpenModal(doc.modalLinks)}
                                  variant="outlined"
                                  size="small"
                                  startIcon={<OpenInNewIcon />}
                                >
                                  Ver Links
                                </Button>
                              ) : (
                                'Não disponível'
                              )}
                            </TableCell>
                            <TableCell className="document-actions">
                              {uploadedDoc ? (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <IconButton
                                    className="document-download"
                                    onClick={() => handleDownload(uploadedDoc.id, uploadedDoc.name)}
                                    disabled={isLoading}
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                  <IconButton
                                    className="document-edit"
                                    onClick={() => handleOpenEditModal(uploadedDoc)}
                                    disabled={isLoading}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => handleDelete(uploadedDoc.id)}
                                    disabled={isLoading}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              ) : (
                                <Button
                                  className="document-upload-button"
                                  variant="outlined"
                                  size="small"
                                  onClick={() =>
                                    document.getElementById(`upload-${doc.id}`)?.click()
                                  }
                                  disabled={isLoading}
                                >
                                  + Adicionar arquivo
                                </Button>
                              )}
                              <input
                                id={`upload-${doc.id}`}
                                type="file"
                                hidden
                                onChange={(e) => handleUpload(e, doc.id, section.id)}
                              />
                            </TableCell>
                          </TableRow>
                          {uploadedDoc &&
                            expandedRows.has(uploadedDoc.id) &&
                            uploadedDoc.documents &&
                            uploadedDoc.documents.length > 0 && (
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={selectionMode ? 6 : 5}
                                >
                                  <Box sx={{ margin: 1 }}>
                                    <Table size="small">
                                      <TableBody>
                                        {uploadedDoc.documents.map((childDoc) => (
                                          <TableRow key={childDoc.id}>
                                            <TableCell sx={{ pl: 4 }}>
                                              {editingChildId === childDoc.id ? (
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    gap: 1,
                                                    alignItems: 'center',
                                                  }}
                                                >
                                                  <TextField
                                                    size="small"
                                                    value={editingChildName}
                                                    onChange={(e) =>
                                                      setEditingChildName(e.target.value)
                                                    }
                                                    onKeyPress={(e) => {
                                                      if (e.key === 'Enter') {
                                                        handleUpdateChildName(
                                                          uploadedDoc.id,
                                                          childDoc.id,
                                                        );
                                                      }
                                                    }}
                                                    autoFocus
                                                  />
                                                  <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                      handleUpdateChildName(
                                                        uploadedDoc.id,
                                                        childDoc.id,
                                                      )
                                                    }
                                                  >
                                                    <CheckIcon />
                                                  </IconButton>
                                                  <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                      setEditingChildId(null);
                                                      setEditingChildName('');
                                                    }}
                                                  >
                                                    <CloseIcon />
                                                  </IconButton>
                                                </Box>
                                              ) : (
                                                childDoc.customName || childDoc.name
                                              )}
                                            </TableCell>
                                            <TableCell align="right">
                                              <Box
                                                sx={{
                                                  display: 'flex',
                                                  gap: 1,
                                                  justifyContent: 'flex-end',
                                                }}
                                              >
                                                <IconButton
                                                  size="small"
                                                  onClick={() => {
                                                    setEditingChildId(childDoc.id);
                                                    setEditingChildName(
                                                      childDoc.customName || childDoc.name,
                                                    );
                                                  }}
                                                >
                                                  <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                  size="small"
                                                  onClick={() =>
                                                    handleDownload(
                                                      uploadedDoc.id,
                                                      childDoc.name,
                                                      childDoc.id,
                                                    )
                                                  }
                                                >
                                                  <DownloadIcon />
                                                </IconButton>
                                                <IconButton
                                                  size="small"
                                                  onClick={() =>
                                                    handleDeleteChild(uploadedDoc.id, childDoc.id)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Box>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                        </>
                      );
                    })}
                    {sectionDocuments
                      .filter((doc) => doc.type === 'custom')
                      .map((customDoc) => (
                        <TableRow key={customDoc.id}>
                          <TableCell>{customDoc.customName || customDoc.name}</TableCell>
                          <TableCell>
                            <Chip
                              label={getStatusLabel(
                                customDoc.status as DocumentStatus,
                                customDoc.expireDate,
                              )}
                              color={getStatusColor(
                                customDoc.status as DocumentStatus,
                                customDoc.expireDate,
                              )}
                              size="small"
                            />
                          </TableCell>
                          <TableCell>
                            {customDoc.generateDate && (
                              <Typography variant="caption" display="block">
                                Emissão:{' '}
                                {format(customDoc.generateDate, 'dd/MM/yyyy', {
                                  timeZone: TIME_ZONE,
                                })}
                              </Typography>
                            )}
                            {customDoc.expireDate && (
                              <Typography variant="caption" display="block" sx={{ mt: 0.5 }}>
                                Validade:{' '}
                                {format(customDoc.expireDate, 'dd/MM/yyyy', {
                                  timeZone: TIME_ZONE,
                                })}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell>Documento Personalizado</TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                              <IconButton
                                onClick={() => handleDownload(customDoc.id, customDoc.name)}
                                disabled={isLoading}
                              >
                                <DownloadIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleOpenEditModal(customDoc)}
                                disabled={isLoading}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDelete(customDoc.id)}
                                disabled={isLoading}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() =>
                            document.getElementById(`upload-custom-${section.id}`)?.click()
                          }
                          disabled={isLoading}
                        >
                          + Adicionar documento personalizado
                        </Button>
                        <input
                          id={`upload-custom-${section.id}`}
                          type="file"
                          hidden
                          onChange={(e) => handleUpload(e, 'custom', section.id)}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>{modalContent?.title}</DialogTitle>
        <DialogContent>
          <List>
            {modalContent?.links.map((link, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  component="a"
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItemText primary={link.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>

      <DocumentEditModal
        document={editingDocument}
        isOpen={editModalOpen}
        isLoading={isLoading}
        editingChildId={editingChildId}
        editingChildName={editingChildName}
        onClose={handleCloseEditModal}
        onSave={handleSaveEdit}
        onUpdateDocument={(field, value) =>
          setEditingDocument((prev) => (prev ? { ...prev, [field]: value } : null))
        }
        onEditChildStart={(id, name) => {
          setEditingChildId(id);
          setEditingChildName(name);
        }}
        onEditChildCancel={() => {
          setEditingChildId(null);
          setEditingChildName('');
        }}
        onEditChildSave={handleUpdateChildName}
        onEditChildNameChange={setEditingChildName}
        onDownloadChild={(parentId, name, childId) => handleDownload(parentId, name, childId)}
        onDeleteChild={(parentId, childId) => handleDeleteChild(parentId, childId)}
        onUploadChild={handleUploadChild}
      />
    </Box>
  );
};

export default DocumentSections;
