import { useAnalytics } from '@hooks/useAnalytics';
import { Close, InfoOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { useBulletinStore } from '@store/bulletinStore';
import { Bulletin, LicitacoesSearchParams } from '@types';
import React, { useEffect, useState } from 'react';
import BulletinSearchParams from './BulletinSearchFilters';

interface BulletinModalProps {
  open: boolean;
  onClose: () => void;
  onCreateBulletin?: (name: string, searchParams: LicitacoesSearchParams) => Promise<void>;
  onEditBulletin?: (
    bulletinId: string,
    name: string,
    searchParams: LicitacoesSearchParams,
  ) => Promise<void>;
  usePreferences?: boolean;
  bulletin?: Bulletin;
}

const BulletinModal: React.FC<BulletinModalProps> = ({
  open,
  onClose,
  onCreateBulletin = undefined,
  onEditBulletin = undefined,
  usePreferences = false,
  bulletin = undefined,
}) => {
  const [name, setName] = useState('');
  const [editingBulletin, setEditingBulletin] = useState<Bulletin | undefined>(bulletin);
  const [searchParams, setSearchParams] = useState<LicitacoesSearchParams>({});
  const { isLoadingBulletins } = useBulletinStore();
  const [error, setError] = useState('');
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (open) {
      const defaultName = usePreferences ? 'Boletim de Preferências' : bulletin?.name || '';
      setName(defaultName);
      setEditingBulletin(bulletin);
      setSearchParams(bulletin?.searchParams || {});
    }
  }, [open, bulletin, usePreferences]);

  const handleSubmit = async (isCreate: boolean) => {
    if (name === '') {
      setError('O nome do boletim é obrigatório');
      trackEvent('bulletin_validation_error', {
        error_type: 'empty_name',
        is_editing: !isCreate,
      });
      return;
    }

    try {
      if (isCreate && onCreateBulletin) {
        await onCreateBulletin(name, searchParams);
        trackEvent('bulletin_created', {
          name_length: name.length,
          params_count: Object.keys(searchParams).length,
        });
      } else if (onEditBulletin && editingBulletin?.id) {
        await onEditBulletin(editingBulletin?.id, name, searchParams);
        trackEvent('bulletin_edited', {
          bulletin_id: editingBulletin.id,
          name_changed: name !== editingBulletin.name,
          params_changed:
            JSON.stringify(searchParams) !== JSON.stringify(editingBulletin.searchParams),
        });
      }
      handleOnClose();
    } catch (error) {
      trackEvent('bulletin_submit_error', {
        is_create: isCreate,
        error_message: error instanceof Error ? error.message : String(error),
      });
    }
  };

  const handleOnClose = () => {
    setName('');
    setEditingBulletin(undefined);
    onClose();
  };

  const handleParamsChange = (newParams: LicitacoesSearchParams) => {
    setSearchParams(newParams);
  };

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="md" fullWidth>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle>{editingBulletin ? 'Editar Boletim' : 'Criar Novo Boletim'}</DialogTitle>
        <IconButton onClick={handleOnClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Nome do Boletim"
          fullWidth
          value={name}
          error={error !== ''}
          helperText={error}
          onChange={(e) => setName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Alert severity="success" icon={<InfoOutlined />} sx={{ mb: 3 }}>
          Os campos de busca abaixo ajudarão a filtrar as licitações relevantes para o seu boletim.
          Preencha os campos desejados para personalizar suas buscas e receber notificações
          específicas.
        </Alert>
        <BulletinSearchParams
          onParamsChange={handleParamsChange}
          usePreferences={usePreferences}
          editingBulletin={editingBulletin?.searchParams}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Cancelar</Button>
        <Button
          onClick={() => handleSubmit(editingBulletin === undefined)}
          variant="contained"
          color="primary"
          disabled={isLoadingBulletins}
        >
          {isLoadingBulletins
            ? editingBulletin
              ? 'Editando...'
              : 'Criando...'
            : editingBulletin
              ? 'Salvar'
              : 'Criar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulletinModal;
