import { useNotification } from '@contexts/NotificationContext';
import { Assessment, Check, Close } from '@mui/icons-material';
import { Box, Chip, Menu, MenuItem, useTheme } from '@mui/material';
import { useTagsStore } from '@store/tagsStore';
import { TagType } from '@types';
import React, { useState } from 'react';

interface TagButtonProps {
  licitacaoId: string;
  dataTour?: string;
}

const TagButton: React.FC<TagButtonProps> = ({ licitacaoId, dataTour }) => {
  const theme = useTheme();
  const { addTag, removeTag, getTag } = useTagsStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const currentTag = getTag(licitacaoId);
  const { showNotification } = useNotification();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_event?: {}) => {
    setAnchorEl(null);
  };

  const handleTagSelect = async (event: React.MouseEvent, type: TagType | null) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      if (type === null) {
        await removeTag(licitacaoId);
      } else {
        await addTag(licitacaoId, type);
      }
    } catch (error) {
      showNotification('Erro ao atualizar a tag', 'error');
    }
    handleClose();
  };

  const getTagColor = (type: TagType | null) => {
    switch (type) {
      case 'interested':
        return theme.palette.success;
      case 'not_interested':
        return theme.palette.error;
      default:
        return {
          main: theme.palette.grey[500],
          light: theme.palette.grey[300],
          dark: theme.palette.grey[700],
          contrastText: theme.palette.getContrastText(theme.palette.grey[500]),
        };
    }
  };

  const getTagLabel = (type: TagType | null) => {
    switch (type) {
      case 'interested':
        return 'Interessado';
      case 'not_interested':
        return 'Não tenho interesse';
      default:
        return 'Avaliar';
    }
  };

  const getTagIcon = (type: TagType | null) => {
    switch (type) {
      case 'interested':
        return <Check fontSize="small" />;
      case 'not_interested':
        return <Close fontSize="small" />;
      default:
        return <Assessment fontSize="small" />;
    }
  };

  return (
    <>
      <Chip
        icon={getTagIcon(currentTag)}
        label={getTagLabel(currentTag)}
        size="small"
        color={
          currentTag === 'not_interested'
            ? 'error'
            : currentTag === 'interested'
              ? 'success'
              : 'default'
        }
        variant={currentTag ? 'filled' : 'outlined'}
        onClick={handleClick}
        onDelete={
          currentTag
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                handleTagSelect(e, null);
              }
            : undefined
        }
        sx={{
          height: 32,
          minWidth: 100,
          '& .MuiChip-label': {
            px: 1,
          },
          cursor: 'pointer',
          '&:hover': {
            backgroundColor:
              currentTag === 'not_interested'
                ? theme.palette.error.light
                : currentTag === 'interested'
                  ? theme.palette.success.light
                  : theme.palette.action.hover,
          },
        }}
        data-tour={dataTour}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <MenuItem
          onClick={(e) => handleTagSelect(e, 'interested')}
          selected={currentTag === 'interested'}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Check fontSize="small" sx={{ color: getTagColor('interested').main }} />
            Interessado
          </Box>
        </MenuItem>
        <MenuItem
          onClick={(e) => handleTagSelect(e, 'not_interested')}
          selected={currentTag === 'not_interested'}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Close fontSize="small" sx={{ color: getTagColor('not_interested').main }} />
            Não tenho interesse
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TagButton;
