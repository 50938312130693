import { format, isToday, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const TIMEZONE = 'America/Sao_Paulo';

export const formatDateTime = (dateString: string | null | undefined) => {
  if (!dateString) return 'N/A';
  try {
    return format(new Date(dateString), 'dd/MM/yyyy HH:mm');
  } catch (error) {
    console.error('Error formatting date:', dateString, error);
    return 'Invalid Date';
  }
};

export const formatIsoDate = (
  dateString: string | null | undefined,
  outputFormat: string = 'dd/MM/yyyy HH:mm',
  timezone: string | null = null,
) => {
  if (!dateString) return '';
  try {
    // Extract date and time parts from the ISO string
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    const [hour, minute] = timePart.split(':');

    // Construct a date string in a format that won't be adjusted
    const dateTimeString = `${year}-${month}-${day} ${hour}:${minute}`;

    // Parse the date without timezone conversion
    const date = new Date(dateTimeString);

    // Format the date
    return format(timezone ? toZonedTime(date, timezone) : date, outputFormat);
  } catch (error) {
    console.error('Error formatting date:', dateString, error);
    return 'Invalid Date';
  }
};

export const isNew = (date: string) => {
  return isToday(parseISO(date));
};
