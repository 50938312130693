import UpgradePrompt from '@components/Common/UpgradePrompt';
import { DOCUMENT_SECTIONS } from '@constants/documents';
import { useAnalytics } from '@hooks/useAnalytics';
import { Cancel, CheckCircle, Close as CloseIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAIChatStore } from '@store/aiChatStore';
import { useDocumentsStore } from '@store/documentsStore';
import { useProfileStore } from '@store/profileStore';
import { DocumentStatus, FeatureId, Licitacao } from '@types';
import { differenceInDays, isAfter, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';

interface DocumentExtractionModalProps {
  open: boolean;
  onClose: () => void;
  licitacao: Licitacao;
}

const DocumentsRequiredModal: React.FC<DocumentExtractionModalProps> = ({
  open,
  onClose,
  licitacao,
}) => {
  const { extractDocuments, isExtracting, documentsRequired, seguroGarantia } = useAIChatStore();
  const { documents, fetchDocuments } = useDocumentsStore();
  const [showOnlyRequired, setShowOnlyRequired] = useState(true);
  const { trackEvent } = useAnalytics();
  const { checkFeatureAccess } = useProfileStore();
  const featureAccess = checkFeatureAccess(FeatureId.DOC_VERIFICATION);

  useEffect(() => {
    if (open) {
      trackEvent('documents_required_modal_opened', {
        licitacao_id: licitacao.id,
        portal: licitacao.portal,
        has_documents: Boolean(documents.length),
      });

      extractDocuments(licitacao);
      if (!documents.length) {
        fetchDocuments();
      }
    }
  }, [open, documents.length, fetchDocuments]);

  useEffect(() => {
    if (documentsRequired) {
      const requiredCount = Object.values(documentsRequired).filter((value) =>
        typeof value === 'boolean' ? value : value.required,
      ).length;

      trackEvent('documents_analysis_complete', {
        licitacao_id: licitacao.id,
        total_documents: Object.keys(documentsRequired).length,
        required_documents: requiredCount,
        has_seguro_garantia: seguroGarantia,
        extraction_success: Boolean(documentsRequired),
      });
    }
  }, [documentsRequired]);

  const handleFilterChange = (checked: boolean) => {
    trackEvent('documents_filter_changed', {
      licitacao_id: licitacao.id,
      show_only_required: checked,
      total_visible: checked
        ? Object.values(documentsRequired || {}).filter((v) =>
            typeof v === 'boolean' ? v : v.required,
          ).length
        : Object.keys(documentsRequired || {}).length,
    });
    setShowOnlyRequired(checked);
  };

  const handleSectionExpand = (sectionId: string, isExpanded: boolean) => {
    trackEvent('documents_section_toggled', {
      licitacao_id: licitacao.id,
      section_id: sectionId,
      is_expanded: isExpanded,
      total_documents: organizeDocumentsBySection()[sectionId]?.length || 0,
    });
  };

  const organizeDocumentsBySection = () => {
    if (!documentsRequired) return {};

    const organized: Record<
      string,
      { id: string; required: boolean; name: string; description?: string }[]
    > = {};
    const additionalDocs: { id: string; required: boolean; name: string; description?: string }[] =
      [];

    Object.entries(documentsRequired).forEach(([docId, value]) => {
      if (typeof value === 'object') {
        // Handle additional document
        if (docId.startsWith('additional_doc_') && (!showOnlyRequired || value.required)) {
          additionalDocs.push({
            id: docId,
            required: value.required,
            name: value.name,
            description: value.description,
          });
        }
      } else {
        // Handle predefined document
        if (showOnlyRequired && !value) return;

        for (const section of DOCUMENT_SECTIONS) {
          const document = section.documents.find((doc) => doc.id === docId);
          if (document) {
            if (!organized[section.id]) {
              organized[section.id] = [];
            }
            organized[section.id].push({
              id: docId,
              required: value,
              name: document.name,
            });
            break;
          }
        }
      }
    });

    if (additionalDocs.length > 0) {
      organized['additional_documents'] = additionalDocs;
    }

    return organized;
  };

  const getDocumentStatus = (docId: string): DocumentStatus | null => {
    const doc = documents.find((d) => d.type === docId);
    if (!doc) return null;

    if (!doc.expireDate) return DocumentStatus.REGULAR;

    const today = new Date();
    const expirationDate = parseISO(doc.expireDate);

    if (isAfter(today, expirationDate)) return DocumentStatus.VENCIDO;
    const daysUntilExpiration = differenceInDays(expirationDate, today);
    if (daysUntilExpiration <= 10) return DocumentStatus.VENCENDO;
    return DocumentStatus.REGULAR;
  };

  const getStatusInfo = (docId: string, required: boolean) => {
    const status = getDocumentStatus(docId);

    if (!required) {
      return {
        icon: <Cancel fontSize="small" />,
        tooltip: 'Documento não necessário',
        color: '#d32f2f',
      };
    }

    switch (status) {
      case DocumentStatus.REGULAR:
        return {
          icon: <CheckCircle fontSize="small" />,
          tooltip: 'Documento necessário e válido',
          color: '#2e7d32',
        };
      case DocumentStatus.VENCENDO:
        return {
          icon: <CheckCircle fontSize="small" />,
          tooltip: 'Documento necessário e próximo ao vencimento',
          color: '#ed6c02',
        };
      case DocumentStatus.VENCIDO:
        return {
          icon: <CheckCircle fontSize="small" />,
          tooltip: 'Documento necessário e vencido',
          color: '#d32f2f',
        };
      default:
        return {
          icon: <Cancel fontSize="small" />,
          tooltip: 'Documento necessário e não encontrado',
          color: '#757575',
        };
    }
  };

  // If no access, show upgrade dialog instead
  if (!featureAccess.hasAccess) {
    trackEvent('documents_required_not_available', {
      licitacao_id: licitacao.id,
      reason: featureAccess.reason,
    });
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Documentos Necessários</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <UpgradePrompt
            message="Verificação automática de documentos não disponível no seu plano"
            reason={featureAccess.reason}
          />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        trackEvent('documents_required_modal_closed', {
          licitacao_id: licitacao.id,
          total_sections_viewed: Object.keys(organizeDocumentsBySection()).length,
          filter_state: showOnlyRequired,
        });
        onClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Documentos Necessários</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="caption"
          sx={{
            display: 'block',
            mb: 2,
            fontStyle: 'italic',
            color: 'text.secondary',
          }}
        >
          Importante: esta é uma análise automatizada dos documentos necessários. Recomenda-se a
          leitura completa do edital para confirmar todos os requisitos e evitar a inabilitação do
          licitante.
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="subtitle1">Licitação: {licitacao.titulo}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={showOnlyRequired}
                onChange={(e) => handleFilterChange(e.target.checked)}
                size="small"
              />
            }
            label="Mostrar apenas documentos necessários"
          />
        </Box>

        {isExtracting ? (
          <Box display="flex" justifyContent="center" alignItems="center" my={4}>
            <CircularProgress color="primary" />
            <Typography variant="body1" sx={{ ml: 2 }}>
              Analisando documentos...
            </Typography>
          </Box>
        ) : (
          documentsRequired && (
            <Box mt={3}>
              <Paper sx={{ p: 2, mb: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 'auto' }}>
                    {seguroGarantia ? (
                      <CheckCircle color="success" fontSize="small" />
                    ) : (
                      <Cancel color="error" fontSize="small" />
                    )}
                  </ListItemIcon>
                  <Typography variant="body2" sx={{ flex: 1 }}>
                    Seguro Garantia
                  </Typography>
                  <Typography
                    variant="body2"
                    color={seguroGarantia ? 'success.main' : 'error.main'}
                    sx={{ minWidth: 100, textAlign: 'right' }}
                  >
                    {seguroGarantia ? 'Necessário' : 'Não necessário'}
                  </Typography>
                </Box>
              </Paper>

              {DOCUMENT_SECTIONS.concat([
                {
                  id: 'additional_documents',
                  title: 'Documentos Adicionais',
                  documents: [],
                },
              ]).map((section) => {
                const sectionDocs = organizeDocumentsBySection()[section.id];
                if (!sectionDocs?.length) return null;

                return (
                  <Accordion
                    key={section.id}
                    defaultExpanded
                    onChange={(_, expanded) => handleSectionExpand(section.id, expanded)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle1" color="primary">
                        {section.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List disablePadding>
                        {sectionDocs.map((doc) => (
                          <ListItem
                            key={doc.id}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              py: 1,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 'auto',
                                color: getStatusInfo(doc.id, doc.required).color,
                              }}
                            >
                              <Tooltip title={getStatusInfo(doc.id, doc.required).tooltip}>
                                {getStatusInfo(doc.id, doc.required).icon}
                              </Tooltip>
                            </ListItemIcon>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="body2">{doc.name}</Typography>
                              {doc.description && (
                                <Typography variant="caption" color="text.secondary">
                                  {doc.description}
                                </Typography>
                              )}
                            </Box>
                            <Typography
                              variant="body2"
                              color={getStatusInfo(doc.id, doc.required).color}
                              sx={{ minWidth: 100, textAlign: 'right' }}
                            >
                              {doc.required ? 'Necessário' : 'Não necessário'}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          )
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentsRequiredModal;
