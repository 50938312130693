import { LicitacaoStatus } from '@types';

export const STATUS_LABELS: Record<LicitacaoStatus, string> = {
  [LicitacaoStatus.DRAFT]: 'Rascunho',
  [LicitacaoStatus.PUBLISHED]: 'Publicado',
  [LicitacaoStatus.RECEIVING_PROPOSALS]: 'Recebendo Propostas',
  [LicitacaoStatus.IN_PROGRESS]: 'Em Andamento',
  [LicitacaoStatus.SUSPENDED]: 'Suspenso',
  [LicitacaoStatus.CANCELLED]: 'Cancelado',
  [LicitacaoStatus.DESERTED]: 'Deserto',
  [LicitacaoStatus.FAILED]: 'Fracassado',
  [LicitacaoStatus.AWARDED]: 'Adjudicado',
  [LicitacaoStatus.HOMOLOGATED]: 'Homologado',
  [LicitacaoStatus.UNKNOWN]: 'Desconhecido',
  [LicitacaoStatus.CLOSED]: 'Fechado',
};

export const STATUS_COLORS: Record<LicitacaoStatus, string> = {
  [LicitacaoStatus.DRAFT]: '#6B7280', // Gray
  [LicitacaoStatus.PUBLISHED]: '#3B82F6', // Blue
  [LicitacaoStatus.RECEIVING_PROPOSALS]: '#10B981', // Green
  [LicitacaoStatus.IN_PROGRESS]: '#F59E0B', // Yellow
  [LicitacaoStatus.SUSPENDED]: '#EF4444', // Red
  [LicitacaoStatus.CANCELLED]: '#DC2626', // Dark Red
  [LicitacaoStatus.DESERTED]: '#6B7280', // Gray
  [LicitacaoStatus.FAILED]: '#DC2626', // Dark Red
  [LicitacaoStatus.AWARDED]: '#059669', // Dark Green
  [LicitacaoStatus.HOMOLOGATED]: '#047857', // Darker Green
  [LicitacaoStatus.UNKNOWN]: '#6B7280', // Gray
  [LicitacaoStatus.CLOSED]: '#1F2937', // Dark Gray
};
