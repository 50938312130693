import { Box, Typography } from '@mui/material';
import {
  BbmnesLicitacao,
  BllBncLicitacao,
  ComprasbrDispensaLicitacao,
  ComprasbrLicitacao,
  ComprasnetLicitacao,
  Licitacao,
  LicitaconLicitacao,
  LicitanetLicitacao,
  LicitarDigitalLicitacao,
  PcpLicitacao,
  PncpLicitacao,
  Portal,
  PortalService,
  ProdespLicitacao,
} from '@types';
import { isFuture, parseISO } from 'date-fns';
import { ReactNode } from 'react';

export abstract class BasePortalService implements PortalService {
  protected licitacao: Licitacao;
  protected portalData:
    | LicitanetLicitacao
    | ProdespLicitacao
    | ComprasbrLicitacao
    | PcpLicitacao
    | ComprasnetLicitacao
    | BllBncLicitacao
    | PncpLicitacao
    | LicitaconLicitacao
    | BbmnesLicitacao
    | LicitarDigitalLicitacao
    | ComprasbrDispensaLicitacao
    | null;

  constructor(licitacao: Licitacao) {
    this.licitacao = licitacao;
    this.portalData = licitacao.portalData;
  }

  abstract name: string;
  abstract logo: string;
  abstract portal: Portal;
  getLinkBaseUrl(): string {
    return 'https://api.licitou.com.br/api/link/download';
  }

  protected ensureHttps(url: string | null): string | null {
    if (!url) return null;

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }

    return url;
  }

  getEditalUrl(): string | null {
    if (this.licitacao.documents && this.licitacao.documents.length > 0) {
      return this.ensureHttps(
        this.licitacao.documents[0].downloadUrl || this.licitacao.documents[0].url || null,
      );
    }
    return this.ensureHttps(this.licitacao.linkEdital);
  }

  getLoginUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getRegisterUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getImpugnacaoUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getEsclarecimentosUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  public getItemsTipsText(): ReactNode {
    return (
      <Box>
        <Typography variant="h6" color="primary" gutterBottom>
          Seleção de Itens
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Pontos de Análise
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>Especificações técnicas detalhadas</li>
              <li>Quantidades e unidades de medida</li>
              <li>Prazos de entrega</li>
              <li>Locais de entrega</li>
              <li>Garantias exigidas</li>
              <li>Critérios de aceitação</li>
            </ul>
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Como Selecionar
          </Typography>
          <Typography variant="body2">
            <ul>
              <li>Analise cada item individualmente</li>
              <li>Verifique sua capacidade de fornecimento</li>
              <li>Considere custos logísticos</li>
              <li>Avalie prazos de entrega</li>
              <li>Confirme atendimento às especificações</li>
            </ul>
          </Typography>
        </Box>
      </Box>
    );
  }

  isPastAbertura(): boolean {
    if (!this.licitacao.abertura_datetime) return false;
    const aberturaDate = parseISO(this.licitacao.abertura_datetime);
    return !isFuture(aberturaDate);
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getFollowUpUrl(): string | null {
    return this.ensureHttps(this.getPortalUrl());
  }
}
