import { FollowingItemsList } from '@components/Following/FollowingItemsList';
import { Box, Typography } from '@mui/material';
import { Licitacao, PortalService } from '@types';

interface ItemsStepProps {
  licitacao: Licitacao;
  portalService: PortalService;
}

export const ItemsStep: React.FC<ItemsStepProps> = ({ licitacao }) => {
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ mb: 1 }}>
        <Typography variant="h6" color="primary" sx={{ fontSize: '1rem', mb: 0.5 }}>
          Seleção de Itens
        </Typography>
        <Typography variant="body2" sx={{ mb: 0.5 }}>
          Analise e selecione os itens que sua empresa tem interesse e capacidade de fornecer. Use a
          barra de busca para encontrar e adicionar itens à sua seleção.
        </Typography>
      </Box>

      <FollowingItemsList licitacao={licitacao} />
    </Box>
  );
};
