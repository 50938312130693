import { Licitacao, Portal, ProdespLicitacao } from '@types';
import { BasePortalService } from './BasePortal';

export class ProdespPortalService extends BasePortalService {
  name = 'PRODESP';
  logo = '/images/portals/prodesp.png';
  portal = Portal.PRODESP;
  portalData: ProdespLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as ProdespLicitacao;
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getFollowUpUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getEsclarecimentosUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getImpugnacaoUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getLoginUrl(): string | null {
    return 'https://www.prodesp.sp.gov.br/fornecedores/editais-de-licitacao/';
  }

  getRegisterUrl(): string | null {
    return 'https://www.prodesp.sp.gov.br/fornecedores/editais-de-licitacao/';
  }
}
