import { CNPJ_MASK, CPF_MASK, PHONE_MASK, RG_MASK } from '@utils/masks';

export const DECLARATIONS = [
  {
    id: 'termo_aceitacao_condicoes_edital',
    name: 'Termo de Aceitação às Condições do Edital',
    fields: [
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'qualificacao', label: 'Qualificação', type: 'text' },
      { name: 'representanteCredenciado', label: 'Representante Credenciado', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cidade: 'endereco.municipio',
      qualificacao: 'naturezaJuridica.descricao',
      representanteCredenciado: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      cpfRepresentante: 'representanteLegal.cpf',
    },
    template: `
    Prezados Senhores,

    Pelo presente, {{razaoSocial}}, {{qualificacao}}, por meio de seu(s) REPRESENTANTE(S) CREDENCIADO(S), declara, sob as penas da legislação aplicável, que se sujeita a todas as condições do EDITAL, tendo pleno conhecimento do objeto da CONCESSÃO, conforme especificado no EDITAL, bem como suas respectivas condições, e a complexidade e características das obras a serem realizadas no âmbito do CONTRATO.

    Declara, ainda, que responde pela veracidade de todas as informações constantes da documentação e da PROPOSTA COMERCIAL apresentadas e declara que recebeu todos os elementos componentes do presente EDITAL e que tomou conhecimento de todas as informações e das condições para o cumprimento das obrigações OBJETO da LICITAÇÃO, tendo considerado suficientes as informações recebidas para a elaboração da sua PROPOSTA COMERCIAL.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    Por: {{representanteCredenciado}}
    RG nº {{rgRepresentante}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'pedido_esclarecimentos',
    name: 'Pedido de Esclarecimentos',
    fields: [
      { name: 'municipioDestino', label: 'Município de Destino', type: 'text' },
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      {
        name: 'questoes',
        label: 'Questões',
        type: 'array',
        fields: [
          { name: 'numero', label: 'Número da questão', type: 'number' },
          { name: 'item', label: 'Item do documento', type: 'text' },
          { name: 'esclarecimento', label: 'Esclarecimento solicitado', type: 'textarea' },
        ],
      },
      { name: 'cidade', label: 'Cidade', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      cpfRepresentante: 'representanteLegal.cpf',
    },
    template: `
    AO MUNICÍPIO DE {{municipioDestino}},

    REF.: {{referencia}}

    Prezados Senhores,

    A {{razaoSocial}}, por seu(s) representante(s) legal(is), apresenta a seguinte solicitação de esclarecimentos relativa ao EDITAL:

    {{#each questoes}}
    Número da questão formulada: {{numero}}
    Item do documento a que se refere a dúvida: {{item}}
    Esclarecimento solicitado: {{esclarecimento}}

    {{/each}}

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    Por: {{representanteLegal}}
    RG nº {{rgRepresentante}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_inexistencia_processo_falimentar',
    name: 'Declaração de Inexistência de Processo Falimentar',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE INEXISTÊNCIA DE PROCESSO FALIMENTAR

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da legislação aplicável, que não se encontra em processo de falência, liquidação judicial ou extrajudicial, insolvência, administração especial temporária ou sob intervenção do órgão fiscalizador competente.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_micro_empresa_pequeno_porte',
    name: 'Declaração de Micro Empresa ou Empresa de Pequeno Porte',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE MICRO EMPRESA OU EMPRESA DE PEQUENO PORTE

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob a as penas da lei que não ultrapassou o limite de faturamento e cumpre os requisitos estabelecidos no art. 3º da Lei Complementar nº 123, de 14 de dezembro de 2006, sendo apta a usufruir o tratamento favorecido estabelecido nos arts. 42º ao 49º da referida Lei Complementar.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'atestado_visita_tecnica',
    name: 'Atestado de Visita Técnica Facultativa',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'dataVisita', label: 'Data da Visita', type: 'date' },
      { name: 'tecnicoResponsavel', label: 'Técnico Responsável', type: 'text' },
      { name: 'rgTecnico', label: 'RG do Técnico', type: 'text', mask: RG_MASK },
      { name: 'cpfTecnico', label: 'CPF do Técnico', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cidade: 'endereco.municipio',
    },
    template: `
    ATESTADO DE VISITA TÉCNICA FACULTATIVA

    Ref.: {{referencia}}

    Relativamente à CONCORRÊNCIA NACIONAL Nº {{referencia}}, pelo presente instrumento, atestamos que {{razaoSocial}} realizou a visita técnica facultativa realizada no dia {{dataVisita}}, com a finalidade de conhecer e verificar a infraestrutura existente que será assumida pela futura CONCESSIONÁRIA, nas condições físico-operacionais em que se encontram com o objetivo de permitir a verificação das condições locais, para avaliação própria da quantidade e natureza dos trabalhos, materiais e equipamentos necessários à realização do OBJETO da CONCESSÃO, forma e condições de suprimento, meios de acesso ao local e obtenção de quaisquer outros dados que julgarem necessários para a adequada prestação do OBJETO do CONTRATO, estando ciente dos riscos e consequências envolvidos para a realização da CONCESSÃO.

    {{cidade}}, {{date}}

    _______________________________________
    {{tecnicoResponsavel}}
    Responsável Técnico
    RG nº {{rgTecnico}}
    CPF/ME nº {{cpfTecnico}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_nao_realizacao_visita_tecnica',
    name: 'Declaração de Não Realização de Visita Técnica',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      cpfRepresentante: 'representanteLegal.cpf',
    },
    template: `
    Prezados Senhores,

    Relativamente à CONCORRÊNCIA NACIONAL Nº {{referencia}}, pelo presente instrumento, declaramos que {{razaoSocial}}, por seu(s) representante(s) legal(is):

    1. decidiu não realizar a visita técnica facultativa com a finalidade de conhecer e verificar a infraestrutura existente que será assumida pela futura CONCESSIONÁRIA, nas condições físico-operacionais em que se encontram com o objetivo de permitir a verificação das condições locais, para avaliação própria da quantidade e natureza dos trabalhos, materiais e equipamentos necessários à realização do OBJETO da CONCESSÃO, forma e condições de suprimento, meios de acesso ao local e obtenção de quaisquer outros dados que julgarem necessários para a adequada prestação do OBJETO; e

    2. declara possuir ciência da possibilidade de realizar a visita técnica e tomar conhecimento dos ESPAÇOS, mas que, ciente dos riscos e consequências envolvidos, optou por formular a PROPOSTA COMERCIAL sem realizar a visita técnica.

    {{cidade}}, {{date}}

    {{representanteLegal}}
    RG nº {{rgRepresentante}}
    CPF/ME sob o nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'carta_credenciamento',
    name: 'Carta de Credenciamento',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      {
        name: 'orgaoExpedidorRepresentante',
        label: 'Órgão Expedidor do Representante',
        type: 'text',
      },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'nomeCredenciado', label: 'Nome do Credenciado', type: 'text' },
      { name: 'rgCredenciado', label: 'RG do Credenciado', type: 'text', mask: RG_MASK },
      {
        name: 'orgaoExpedidorCredenciado',
        label: 'Órgão Expedidor do Credenciado',
        type: 'text',
      },
      { name: 'cpfCredenciado', label: 'CPF do Credenciado', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidorRepresentante: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      nomeCredenciado: 'representanteLegal.nome',
      rgCredenciado: 'representanteLegal.rg',
      orgaoExpedidorCredenciado: 'representanteLegal.orgaoEmissor',
      cpfCredenciado: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    CARTA DE CREDENCIAMENTO

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}}, Órgão Expedidor {{orgaoExpedidorRepresentante}}, e do C.P.F nº {{cpfRepresentante}}, pela presente CREDENCIA o Sr. {{nomeCredenciado}}, portador da carteira de Identidade nº {{rgCredenciado}}, Órgão Expedidor {{orgaoExpedidorCredenciado}}, e CPF nº {{cpfCredenciado}}, para representá-la na Licitação em epígrafe supra mencionada, outorgando-lhe poderes para concordar, desistir, renunciar, transigir, firmar recibos, assinar Atas e outros documentos, acompanhar todo o processo Licitatório até o seu final, tomar ciência de outras propostas, podendo para tanto, praticar todos os atos necessários para o bom e fiel cumprimento deste mandato.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidorRepresentante}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_cumprimento_inciso_xxxiii',
    name: 'Declaração de Cumprimento do Inciso XXXIII do Artigo 7º da Constituição Federal',
    fields: [
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'qualificacao', label: 'Qualificação', type: 'text' },
      { name: 'representanteCredenciado', label: 'Representante Credenciado', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cidade: 'endereco.municipio',
      qualificacao: 'naturezaJuridica.descricao',
      representanteCredenciado: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      cpfRepresentante: 'representanteLegal.cpf',
    },
    template: `
    DECLARAÇÃO DE CUMPRIMENTO DO INCISO XXXIII DO ARTIGO 7º DA CONSTITUIÇÃO FEDERAL

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}}, por meio de seu(s) REPRESENTANTE(S) CREDENCIADO(S), declara, para fins do disposto no inciso VI, do artigo 68, da Lei Federal nº 14.133/2021, sob as penas da legislação aplicável, que não emprega menores de 18 (dezoito) anos em trabalho noturno, perigoso ou insalubre e não emprega menor de dezesseis anos, salvo aqueles em contrato de aprendiz, maiores de quatorze anos.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    Por: {{representanteCredenciado}}
    RG nº {{rgRepresentante}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_inexistencia_fato_impeditivo',
    name: 'Declaração de Inexistência de Fato Impeditivo',
    fields: [
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'qualificacao', label: 'Qualificação', type: 'text' },
      { name: 'representanteCredenciado', label: 'Representante Credenciado', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cidade: 'endereco.municipio',
      qualificacao: 'naturezaJuridica.descricao',
      representanteCredenciado: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      cpfRepresentante: 'representanteLegal.cpf',
    },
    template: `
    DECLARAÇÃO DE INEXISTÊNCIA DE FATO IMPEDITIVO

    Ref.: {{referencia}}

    {{razaoSocial}}, {{qualificacao}}, por meio de seu(s) REPRESENTANTE(S) CREDENCIADO(S), declara, sob as penas da legislação aplicável, que:

    1. Não foi declarada inidônea por qualquer esfera federativa, não estando proibida de licitar ou contratar com a ADMINISTRAÇÃO PÚBLICA por estar incluída no Cadastro Nacional de Empresas Punidas - CNEP e no Cadastro Nacional de Empresas Inidôneas e Suspensas - CEIS, ambos do Governo Federal e no Cadastro Estadual de Empresas Punidas - CEEP do Estado de São Paulo, instituído nos termos do artigo 5º, do Decreto Estadual nº 60.106/2014;

    2. Não está em cumprimento de pena de suspensão temporária de contratar com a Administração Pública Direta ou Indireta do Município de {{cidade}};

    3. Se compromete a comunicar a ocorrência de quaisquer fatos supervenientes relacionados com o objeto desta declaração; e

    4. Não está condenada, por sentença transitada em julgado, à pena de interdição de direitos devido à prática de crimes ambientais, conforme disciplinado no artigo 10, da Lei Federal nº 9.605/1998.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    Por: {{representanteCredenciado}}
    RG nº {{rgRepresentante}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_compromisso_contratacao_garantia_execucao',
    name: 'Declaração de Compromisso de Contratação de Garantia de Execução',
    fields: [
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'qualificacao', label: 'Qualificação', type: 'text' },
      { name: 'representanteCredenciado', label: 'Representante Credenciado', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cidade: 'endereco.municipio',
      qualificacao: 'naturezaJuridica.descricao',
      representanteCredenciado: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      cpfRepresentante: 'representanteLegal.cpf',
    },
    template: `
    DECLARAÇÃO DE COMPROMISSO DE CONTRATAÇÃO DE GARANTIA DE EXECUÇÃO

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da legislação aplicável, que se obriga a contratar e renovar anualmente, no caso de se sagrar vencedora da LICITAÇÃO, GARANTIA DE EXECUÇÃO, que pode ser contratada em quaisquer modalidades previstas no CONTRATO, visando a garantir a execução dos investimentos necessários à consecução do OBJETO do CONTRATO, além de todas as demais obrigações contratuais, conforme estabelecem os termos do CONTRATO.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'termo_ciencia_notificacao',
    name: 'Termo de Ciência e de Notificação',
    fields: [
      { name: 'contratante', label: 'Contratante', type: 'text' },
      { name: 'contratado', label: 'Contratado', type: 'text' },
      { name: 'contratoNumero', label: 'Número do Contrato', type: 'text' },
      { name: 'objeto', label: 'Objeto', type: 'text' },
      { name: 'advogado', label: 'Advogado', type: 'text' },
      { name: 'oabNumero', label: 'Número OAB', type: 'text' },
      {
        name: 'gestorOrgao',
        label: 'Gestor do Órgão/Entidade',
        type: 'object',
        fields: [
          { name: 'nome', label: 'Nome', type: 'text' },
          { name: 'cargo', label: 'Cargo', type: 'text' },
          { name: 'cpf', label: 'CPF', type: 'text', mask: CPF_MASK },
          { name: 'rg', label: 'RG', type: 'text', mask: RG_MASK },
          { name: 'dataNascimento', label: 'Data de Nascimento', type: 'date' },
          { name: 'endereco', label: 'Endereço residencial completo', type: 'text' },
          { name: 'emailInstitucional', label: 'E-mail institucional', type: 'email' },
          { name: 'emailPessoal', label: 'E-mail pessoal', type: 'email' },
          { name: 'telefone', label: 'Telefone(s)', type: 'text', mask: PHONE_MASK },
        ],
      },
      {
        name: 'responsavelContratante',
        label: 'Responsável pelo Contratante',
        type: 'object',
        fields: [
          { name: 'nome', label: 'Nome', type: 'text' },
          { name: 'cargo', label: 'Cargo', type: 'text' },
          { name: 'cpf', label: 'CPF', type: 'text', mask: CPF_MASK },
          { name: 'rg', label: 'RG', type: 'text', mask: RG_MASK },
          { name: 'dataNascimento', label: 'Data de Nascimento', type: 'date' },
          { name: 'endereco', label: 'Endereço residencial completo', type: 'text' },
          { name: 'emailInstitucional', label: 'E-mail institucional', type: 'email' },
          { name: 'emailPessoal', label: 'E-mail pessoal', type: 'email' },
          { name: 'telefone', label: 'Telefone(s)', type: 'text', mask: PHONE_MASK },
        ],
      },
      {
        name: 'responsavelContratada',
        label: 'Responsável pela Contratada',
        type: 'object',
        fields: [
          { name: 'nome', label: 'Nome', type: 'text' },
          { name: 'cargo', label: 'Cargo', type: 'text' },
          { name: 'cpf', label: 'CPF', type: 'text', mask: CPF_MASK },
          { name: 'rg', label: 'RG', type: 'text', mask: RG_MASK },
          { name: 'dataNascimento', label: 'Data de Nascimento', type: 'date' },
          { name: 'endereco', label: 'Endereço residencial completo', type: 'text' },
          { name: 'emailInstitucional', label: 'E-mail institucional', type: 'email' },
          { name: 'emailPessoal', label: 'E-mail pessoal', type: 'email' },
          { name: 'telefone', label: 'Telefone(s)', type: 'text', mask: PHONE_MASK },
        ],
      },
      { name: 'cidade', label: 'Cidade', type: 'text' },
    ],
    companyFields: {
      cidade: 'endereco.municipio',
      contratado: 'razaoSocial',
      'gestorOrgao.nome': 'representanteLegal.nome',
      'gestorOrgao.cargo': 'representanteLegal.qual',
      'gestorOrgao.cpf': 'representanteLegal.cpf',
      'gestorOrgao.rg': 'representanteLegal.rg',
      'gestorOrgao.endereco': 'endereco.logradouro',
      'gestorOrgao.emailInstitucional': 'contato.email',
      'gestorOrgao.telefone': 'contato.telefone',
      'responsavelContratada.nome': 'representanteLegal.nome',
      'responsavelContratada.cargo': 'representanteLegal.qual',
      'responsavelContratada.cpf': 'representanteLegal.cpf',
      'responsavelContratada.rg': 'representanteLegal.rg',
      'responsavelContratada.endereco': 'endereco.logradouro',
      'responsavelContratada.emailInstitucional': 'contato.email',
      'responsavelContratada.telefone': 'contato.telefone',
    },
    template: `
    TERMO DE CIÊNCIA E DE NOTIFICAÇÃO

    Ref.: {{contratoNumero}}

    CONTRATANTE: {{contratante}}
    CONTRATADO: {{contratado}}
    CONTRATO N° (DE ORIGEM): {{contratoNumero}}
    OBJETO: {{objeto}}
    ADVOGADO (S) /N° OAB: (*) {{advogado}} / {{oabNumero}}

    Pelo presente Termo de Ciência e Notificação, nós, abaixo identificados:

    1. Estamos CIENTES de que:
    a) O ajuste acima referido estará sujeito a análise e julgamento pelo Tribunal de Contas do Estado de São Paulo, cujo trâmite processual ocorrerá pelo sistema eletrônico;
    b) Poderemos ter acesso ao processo, tendo vista e extraindo cópias das manifestações de interesse, Despachos e Decisões, mediante regular cadastramento no Sistema de Processo Eletrônico, conforme dados abaixo indicados, em consonância com o estabelecido na Resolução n° 01/2011 do TCE/SP;
    c) Além de disponíveis no processo eletrônico, todos os Despachos e Decisões que vierem a ser tomados, relativamente ao aludido processo, serão publicados no Diário Oficial do Estado, Caderno do Poder Legislativo, parte do Tribunal de Contas do Estado de São Paulo, em conformidade com o artigo 90 da Lei Complementar Estadual n° 709, de 14 de janeiro de 1993, iniciando-se, a partir de então, a contagem dos prazos processuais, conforme regras do Código de Processo Civil;
    d) Qualquer alteração de endereço – residencial ou eletrônico – ou telefones de contato deverá ser comunicada pelo interessado, peticionando no processo.

    2. Damos-nos por NOTIFICADOS para:
    a) O acompanhamento dos atos do processo até seu julgamento final e consequente publicação;
    b) Se for o caso e de nosso interesse, nos prazos e nas formas legais e regimentais, exercer o direito de defesa, interpor recursos e o que mais couber.

    LOCAL e DATA: {{cidade}}, {{date}}

    _______________________________________
    GESTOR DO ÓRGÃO/ENTIDADE
    Nome: {{gestorOrgao.nome}}
    Cargo: {{gestorOrgao.cargo}}
    CPF: {{gestorOrgao.cpf}}
    RG: {{gestorOrgao.rg}}
    Data Nasc: {{gestorOrgao.dataNascimento}}
    End: {{gestorOrgao.endereco}}
    E-mail Institucional: {{gestorOrgao.emailInstitucional}}
    E-mail Pessoal: {{gestorOrgao.emailPessoal}}
    Telefone: {{gestorOrgao.telefone}}

    _______________________________________
    PELO CONTRATANTE
    Nome: {{responsavelContratante.nome}}
    Cargo: {{responsavelContratante.cargo}}
    CPF: {{responsavelContratante.cpf}}
    RG: {{responsavelContratante.rg}}
    Data Nasc: {{responsavelContratante.dataNascimento}}
    End: {{responsavelContratante.endereco}}
    E-mail Institucional: {{responsavelContratante.emailInstitucional}}
    E-mail Pessoal: {{responsavelContratante.emailPessoal}}
    Telefone: {{responsavelContratante.telefone}}

    _______________________________________
    PELA CONTRATADA
    Nome: {{responsavelContratada.nome}}
    Cargo: {{responsavelContratada.cargo}}
    CPF: {{responsavelContratada.cpf}}
    RG: {{responsavelContratada.rg}}
    Data Nasc: {{responsavelContratada.dataNascimento}}
    End: {{responsavelContratada.endereco}}
    E-mail Institucional: {{responsavelContratada.emailInstitucional}}
    E-mail Pessoal: {{responsavelContratada.emailPessoal}}
    Telefone: {{responsavelContratada.telefone}}
    `,
  },
  {
    id: 'declaracao_elaboracao_independente_proposta',
    name: 'Declaração de Elaboração Independente de Proposta',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE ELABORAÇÃO INDEPENDENTE DE PROPOSTA

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da lei, em especial o art. 299 do Código Penal Brasileiro que:

    a) a proposta apresentada para participar da licitação em epígrafe foi elaborada de maneira independente pelo Licitante, e o conteúdo da proposta não foi, no todo ou em parte, direta ou indiretamente, informado, discutido ou recebido de qualquer outro participante potencial ou de fato da licitação em epígrafe, por qualquer meio ou por qualquer pessoa;

    b) a intenção de apresentar a proposta elaborada para participar da licitação em epígrafe não foi informada, discutida ou recebida de qualquer outro participante potencial ou de fato da licitação em epígrafe, por qualquer meio ou por qualquer pessoa;

    c) que não tentou, por qualquer meio ou por qualquer pessoa, influir na decisão de qualquer outro participante potencial ou de fato da licitação em epígrafe quanto a participar ou não da referida licitação;

    d) que o conteúdo da proposta apresentada para participar da licitação em epígrafe não será, no todo ou em parte, direta ou indiretamente, comunicado ou discutido com qualquer outro participante potencial ou de fato da licitação em epígrafe antes da adjudicação do objeto da referida licitação;

    e) que o conteúdo da proposta apresentada para participar da licitação em epígrafe não foi, no todo ou em parte, direta ou indiretamente, informado, discutido ou recebido de qualquer integrante deste órgão antes da abertura oficial das propostas; e

    f) que está plenamente ciente do teor e da extensão desta declaração e que detém plenos poderes e informações para firmá-la.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_habilitacao',
    name: 'Declaração de Habilitação',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO CUMPRIMENTO DOS REQUISITOS DE HABILITAÇÃO

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}}, sediada em {{endereco}}, {{cidade}}, por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, que cumpre plenamente todos os requisitos e exigências de habilitação da licitação {{referencia}}. Declaramos também não estar temporariamente suspensa de participar em licitação e impedida de contratar com a Administração, bem como não ter sido declarada inidônea para licitar e contratar com a Administração Pública.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_inexistencia_fatos_impeditivos',
    name: 'Declaração de Inexistência de Fatos Impeditivos',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE INEXISTÊNCIA DE FATOS IMPEDITIVOS

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da lei, que até a presente data inexistem fatos impeditivos para a habilitação no presente processo licitatório, ciente da obrigatoriedade de declarar ocorrências posteriores.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_nao_trabalho_forcado_degradante',
    name: 'Declaração de Não Trabalho Forçado e Degradante',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE NÃO TRABALHO FORÇADO E DEGRADANTE

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, que não possui, em sua cadeia produtiva, empregados executando trabalho degradante ou forçado, observando o disposto nos incisos III e IV do art. 1º e no inc. III do art. 5º da Constituição Federal.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_renuncia_vistoria',
    name: 'Declaração de Renúncia de Vistoria',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE RENÚNCIA DE VISTORIA

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da lei que, optamos pela não realização de vistoria assumindo inteiramente a responsabilidade ou consequências por essa omissão, mantendo as garantias que vincularem nossa proposta ao presente processo licitatório.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_nao_emprega_menor',
    name: 'Declaração Menor/Aprendiz',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO QUE NÃO EMPREGA MENOR DE IDADE, SALVO NA CONDIÇÃO DE APRENDIZ

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, para fins do disposto no inciso XXXIII do Art. 7º da Constituição Federal, que não emprega menor de dezoito anos em trabalho noturno, perigoso ou insalubre e que não emprega menor de dezesseis anos.

    Ressalva: emprega menor, a partir de quatorze anos, na condição de aprendiz.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_idoneidade',
    name: 'Declaração de Idoneidade',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE IDONEIDADE

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}}, sediada em {{endereco}}, {{cidade}}, por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da lei e para fins de direito, em cumprimento ao instrumento convocatório da licitação supracitada, que:

    I - Não foi declarada inidônea por ato da Administração Pública;
    II - Não está suspensa de participar em licitações e impedida de contratar com o Município;
    III - Não foi apenada com rescisão de contrato, quer por deficiência dos serviços prestados, quer por outro motivo igualmente grave, no transcorrer dos últimos 02 (dois) anos;
    IV - Não incorre nas demais condições impeditivas previstas na Lei nº 14.133/21.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_capacidade_financeira',
    name: 'Declaração de Capacidade Financeira',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE CAPACIDADE FINANCEIRA

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da legislação aplicável, que dispõe de capacidade para obter recursos financeiros suficientes ao devido cumprimento das obrigações de aporte de recursos próprios e de terceiros, necessários à consecução do OBJETO da CONCESSÃO. Declara, além disso, que:

    I. Contratou ou tem capacidade de contratar todos os seguros e garantias necessários à consecução do OBJETO da CONCESSÃO; e
    II. Dispõe ou tem capacidade de obter os recursos para a integralização do capital social mínimo da SPE, conforme exigido pelo seu estatuto social.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_inexistencia_vinculo',
    name: 'Declaração de Inexistência de Vínculo',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE INEXISTÊNCIA DE VÍNCULO

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da lei, a inexistência no quadro da empresa, de sócios ou empregados com vínculo de parentesco em linha reta, colateral ou por afinidade até o terceiro grau, ou, ainda, que sejam cônjuges ou companheiros de ocupantes do quadro da Prefeitura Municipal, nos cargos de direção e chefia ou exercentes de função gratificada de mesma natureza, bem como de seus agentes políticos.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_enquadramento_lei_complementar_123',
    name: 'Declaração de Enquadramento na Lei Complementar nº 123/2006',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE ENQUADRAMENTO NA LEI COMPLEMENTAR Nº 123/2006

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da lei, que cumpre os requisitos legais para a qualificação como microempresa ou empresa de pequeno porte, e atesta a aptidão para usufruir do tratamento favorecido estabelecido nos arts. 42 a 49 da Lei Complementar federal n. 123/06, não possuindo nenhum dos impedimentos previstos no § 4º do artigo 3º da referida Lei.

    Nota: A falsidade desta DECLARAÇÃO, objetivando os benefícios da Lei Complementar nº 123/2006, caracterizará crime de que trata o Art. 299 do Código Penal, sem prejuízo do enquadramento em outras figuras penais e das penalidades previstas neste Edital.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_vinculo_parentesco',
    name: 'Declaração de Vínculo de Parentesco',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
    DECLARAÇÃO DE VÍNCULO DE PARENTESCO

    Ref.: {{referencia}}

    {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da Lei, que não mantém vínculo de natureza técnica, comercial, econômica, financeira, trabalhista ou civil com o Chefe do Poder Executivo Municipal ou com agente público municipal que desempenhe função na licitação ou atue na fiscalização ou na gestão do contrato, ou que deles seja cônjuge, companheiro ou parente em linha reta, colateral ou por afinidade, até o terceiro grau, nos termos do art. 9º, § 1º, da Lei Federal nº 14.133/2021.

    {{cidade}}, {{date}}

    _______________________________________
    {{razaoSocial}}
    {{representanteLegal}} - {{cargo}}
    RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
    CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_direitos_trabalhistas',
    name: 'Declaração de Direitos Trabalhistas',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
      DECLARAÇÃO DE DIREITOS TRABALHISTAS

      Ref.: {{referencia}}

      {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da Lei, que as propostas econômicas compreendem a integralidade dos custos para atendimento dos direitos trabalhistas assegurados na Constituição Federal, nas leis trabalhistas, nas normas infralegais, nas convenções coletivas de trabalho e nos termos de ajustamento de conduta vigentes na data de entrega das propostas; (inciso IV § 1º do art. 63 da Lei 14.133/21).

      {{cidade}}, {{date}}

      _______________________________________
      {{razaoSocial}}
      {{representanteLegal}} - {{cargo}}
      RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
      CPF/ME nº {{cpfRepresentante}}
      `,
  },
  {
    id: 'declaracao_servidor_publico',
    name: 'Declaração Servidor Público',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
      DECLARAÇÃO SERVIDOR PÚBLICO

      Ref.: {{referencia}}

      {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da lei, de que não possui em seu quadro de pessoal ou societário servidor do Poder Executivo Municipal nos termos do art. 9º, § 1º, da Lei Federal nº 14.133/2021.

      {{cidade}}, {{date}}

      _______________________________________
      {{razaoSocial}}
      {{representanteLegal}} - {{cargo}}
      RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
      CPF/ME nº {{cpfRepresentante}}
      `,
  },
  {
    id: 'declaracao_condenacao_5_anos',
    name: 'Declaração Condenação 5 Anos',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
      DECLARAÇÃO CONDENAÇÃO 5 ANOS

      Ref.: {{referencia}}

      {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da Lei, que nos 5 (cinco) anos anteriores à divulgação do edital, não foi condenada judicialmente, com trânsito em julgado, por exploração de trabalho infantil, por submissão de trabalhadores a condições análogas às de escravo ou por contratação de adolescentes nos casos vedados pela legislação trabalhista.

      {{cidade}}, {{date}}

      _______________________________________
      {{razaoSocial}}
      {{representanteLegal}} - {{cargo}}
      RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
      CPF/ME nº {{cpfRepresentante}}
      `,
  },
  {
    id: 'declaracao_sancoes_vigentes',
    name: 'Declaração Sanções Vigentes',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
    },
    template: `
      DECLARAÇÃO SANÇÕES VIGENTES

      Ref.: {{referencia}}

      {{razaoSocial}} inscrita no CNPJ sob nº {{cnpj}} por intermédio de seu representante legal, Sr.(a) {{representanteLegal}}, portador(a) da Carteira de Identidade nº {{rgRepresentante}} Órgão Expedidor {{orgaoExpedidor}} e do C.P.F nº {{cpfRepresentante}}, DECLARA, sob as penas da Lei, não há sanções vigentes que legalmente o proíbam de licitar e/ou contratar com o órgão ou entidade contratante.

      {{cidade}}, {{date}}

      _______________________________________
      {{razaoSocial}}
      {{representanteLegal}} - {{cargo}}
      RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
      CPF/ME nº {{cpfRepresentante}}
    `,
  },
  {
    id: 'declaracao_unificada',
    name: 'Declaração Unificada',
    fields: [
      { name: 'referencia', label: 'Edital', type: 'text' },
      { name: 'razaoSocial', label: 'Razão Social', type: 'text' },
      { name: 'cnpj', label: 'CNPJ', type: 'text', mask: CNPJ_MASK },
      { name: 'representanteLegal', label: 'Representante Legal', type: 'text' },
      { name: 'rgRepresentante', label: 'RG do Representante', type: 'text', mask: RG_MASK },
      { name: 'orgaoExpedidor', label: 'Órgão Expedidor', type: 'text' },
      { name: 'cpfRepresentante', label: 'CPF do Representante', type: 'text', mask: CPF_MASK },
      { name: 'cidade', label: 'Cidade', type: 'text' },
      { name: 'cargo', label: 'Cargo', type: 'text' },
      { name: 'endereco', label: 'Endereço', type: 'text' },
    ],
    companyFields: {
      razaoSocial: 'razaoSocial',
      cnpj: 'cnpj',
      cidade: 'endereco.municipio',
      representanteLegal: 'representanteLegal.nome',
      rgRepresentante: 'representanteLegal.rg',
      orgaoExpedidor: 'representanteLegal.orgaoEmissor',
      cpfRepresentante: 'representanteLegal.cpf',
      cargo: 'representanteLegal.qual',
      endereco: 'endereco.logradouro',
    },
    template: `
      DECLARAÇÃO UNIFICADA

      Ref.: {{referencia}}

      Eu, {{representanteLegal}}, {{cpfRepresentante}}, na qualidade de {{cargo}}, declaro, sob as penas da lei, que a {{razaoSocial}}, inscrita no CNPJ sob nº {{cnpj}}, com sede na {{endereco}}, {{cidade}}, para fins de comprovação {{referencia}}:

      1. Não foi declarada INIDÔNEA para contratar com a Administração Pública, nos termos do inciso IV, do art. 156 da Lei Federal nº 14.133/2021 e de suas alterações posteriores, bem como não foi suspenso e limitado o direito de licitar e contratar com qualquer ente da federação incluindo autarquias, fundações, dentre outros;

      2. Que não realizou doação em dinheiro, ou bem estimável em dinheiro, para partido político ou campanha eleitoral de candidato a cargo eletivo, nos termos da Lei Municipal 11.925/2015;

      3. Que não possui em seu quadro funcional menores de dezoito anos em trabalho noturno, perigoso e/ou insalubre, e nem menores de dezesseis anos em qualquer atividade, salvo na condição de aprendiz a partir de 14 anos, nos termos da Lei n.° 9.854/99, regulamentada pelo Decreto n.° 4.358, de 05/09/2002, observando o disposto no inciso XXXIII do artigo 7° da Constituição Federal;

      4. Que não possui, em sua cadeia produtiva, empregados executando trabalho degradante ou forçado, observando o disposto nos incisos III e IV do artigo 1° e no inciso III do artigo 5° da Constituição Federal;

      5. Que não possui em seu quadro societário servidor público ativo da Administração Pública Municipal;

      6. Ter conhecimento e estar de pleno acordo com as normas desta contratação, bem como que comunicarei à Administração Municipal qualquer fato ou evento superveniente à entrega dos documentos de habilitação, que venha alterar a atual situação quanto à capacidade jurídica, técnica, regularidade fiscal e idoneidade financeira.

      {{cidade}}, {{date}}

      _______________________________________
      {{razaoSocial}}
      {{representanteLegal}} - {{cargo}}
      RG nº {{rgRepresentante}} - {{orgaoExpedidor}}
      CPF/ME nº {{cpfRepresentante}}
      `,
  },
];
