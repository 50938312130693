import UpgradePrompt from '@components/Common/UpgradePrompt';
import { AI_CHAT_EXAMPLE_QUESTIONS } from '@constants';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import {
  Autorenew as AutorenewIcon,
  Close as CloseIcon,
  Feedback as FeedbackIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextareaAutosize,
  Typography,
  useTheme,
} from '@mui/material';
import { useAIChatStore } from '@store/aiChatStore';
import { useProfileStore } from '@store/profileStore';
import { FeatureId, Licitacao } from '@types';
import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import AIChatFeedback from './AIChatFeedback';

interface AIChatModalProps {
  open: boolean;
  onClose: () => void;
  licitacao: Licitacao;
}

const MAX_RANDOM_QUESTIONS = 5;

// Lazy load components with chunk names
const ReactMarkdown = lazy(() => import(/* webpackChunkName: "react-markdown" */ 'react-markdown'));

const AIChatModal: React.FC<AIChatModalProps> = ({ open, onClose, licitacao }) => {
  const theme = useTheme();
  const [question, setQuestion] = useState('');
  const { messages, isLoading, error, sendMessage, clearMessages, setError } = useAIChatStore();
  const [randomQuestions, setRandomQuestions] = useState<string[]>([]);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const { trackEvent } = useAnalytics();
  const { checkFeatureAccess } = useProfileStore();
  const featureAccess = checkFeatureAccess(FeatureId.AI_ANALYSIS);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (open) {
      trackEvent('ai_chat_opened', {
        licitacao_id: licitacao.id,
        portal: licitacao.portal,
        random_questions: randomQuestions.length,
      });

      const shuffled = [...AI_CHAT_EXAMPLE_QUESTIONS].sort(() => 0.5 - Math.random());
      setRandomQuestions(shuffled.slice(0, MAX_RANDOM_QUESTIONS));
    } else {
      trackEvent('ai_chat_closed', {
        licitacao_id: licitacao.id,
        total_messages: messages.length,
        has_error: Boolean(error),
      });
      clearMessages();
    }
  }, [open]);

  useEffect(() => {
    if (error) {
      trackEvent('ai_chat_error', {
        licitacao_id: licitacao.id,
        error_message: error,
        total_messages: messages.length,
      });
      showNotification('Erro ao processar a pergunta. Por favor, tente novamente.', 'error');
    }
  }, [error]);

  const handleQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(event.target.value);
  };

  const handleSubmit = async () => {
    if (!question.trim()) return;

    trackEvent('ai_chat_question_sent', {
      licitacao_id: licitacao.id,
      question_length: question.length,
      total_messages: messages.length,
      is_example: false,
    });

    await sendMessage(licitacao.id, question);
    setQuestion('');
  };

  const handleExampleQuestionClick = (question: string) => {
    trackEvent('ai_chat_example_clicked', {
      licitacao_id: licitacao.id,
      question,
      total_messages: messages.length,
    });

    sendMessage(licitacao.id, question);
    setQuestion('');
  };

  const handleFeedbackOpen = () => {
    trackEvent('ai_chat_feedback_opened', {
      licitacao_id: licitacao.id,
      total_messages: messages.length,
      has_error: Boolean(error),
    });
    setFeedbackOpen(true);
  };

  const handleClose = () => {
    trackEvent('ai_chat_closed', {
      licitacao_id: licitacao.id,
      total_messages: messages.length,
      has_error: Boolean(error),
    });
    setError(null);
    onClose();
  };

  const darkModeBgColor = '#1e1e1e';

  const formatMessage = (content: string) => {
    const sections = content.split('\n\n');
    return (
      <Suspense fallback={<div>Carregando o chat ...</div>}>
        {sections.map((section, index) => {
          if (section.startsWith('*')) {
            const [title, ...items] = section.split('\n');
            return (
              <Box key={index} mb={2}>
                <Typography variant="subtitle1">{title.replace('* ', '')}</Typography>
                <List dense>
                  {items.map((item, itemIndex) => (
                    <ListItem key={itemIndex}>
                      <ListItemText
                        primary={
                          <ReactMarkdown
                            components={{
                              p: ({ children }) => (
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: theme.palette.getContrastText(
                                      theme.palette.mode === 'dark'
                                        ? darkModeBgColor
                                        : theme.palette.background.paper,
                                    ),
                                  }}
                                >
                                  {children}
                                </Typography>
                              ),
                            }}
                          >
                            {item.trim()}
                          </ReactMarkdown>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            );
          } else {
            return (
              <Typography
                key={index}
                variant="body1"
                paragraph
                sx={{
                  color: theme.palette.getContrastText(
                    theme.palette.mode === 'dark'
                      ? darkModeBgColor
                      : theme.palette.background.paper,
                  ),
                }}
              >
                <ReactMarkdown>{section}</ReactMarkdown>
              </Typography>
            );
          }
        })}
      </Suspense>
    );
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (question.trim() && !isLoading) {
        handleSubmit();
      }
    }
  };

  const renderWelcomeMessage = useMemo(
    () => (
      <Box mt={2} sx={{ fontSize: '0.875rem', '& .MuiTypography-root': { fontSize: '0.875rem' } }}>
        <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem !important' }}>
          Bem-vindo! Eu sou a LicitAI! 👋
        </Typography>

        <Typography variant="body2" paragraph sx={{ fontSize: '0.75rem !important' }}>
          Sou uma inteligência artificial especializada em análise de licitações, desenvolvida para
          ajudar você a compreender melhor este processo licitatório.
        </Typography>

        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
          <Box>
            <Typography
              variant="subtitle2"
              gutterBottom
              color="primary"
              sx={{ fontSize: '0.8rem !important' }}
            >
              Análise do Edital
            </Typography>
            <List dense sx={{ py: 0 }}>
              <ListItem sx={{ py: 0.25 }}>
                <ListItemText
                  primary="Requisitos técnicos"
                  primaryTypographyProps={{ fontSize: '0.75rem' }}
                />
              </ListItem>
              <ListItem sx={{ py: 0.25 }}>
                <ListItemText
                  primary="Critérios de habilitação"
                  primaryTypographyProps={{ fontSize: '0.75rem' }}
                />
              </ListItem>
              <ListItem sx={{ py: 0.25 }}>
                <ListItemText
                  primary="Prazos importantes"
                  primaryTypographyProps={{ fontSize: '0.75rem' }}
                />
              </ListItem>
            </List>
          </Box>

          <Box>
            <Typography
              variant="subtitle2"
              gutterBottom
              color="primary"
              sx={{ fontSize: '0.8rem !important' }}
            >
              Documentação
            </Typography>
            <List dense sx={{ py: 0 }}>
              <ListItem sx={{ py: 0.25 }}>
                <ListItemText
                  primary="Documentos necessários"
                  primaryTypographyProps={{ fontSize: '0.75rem' }}
                />
              </ListItem>
              <ListItem sx={{ py: 0.25 }}>
                <ListItemText
                  primary="Exigências específicas"
                  primaryTypographyProps={{ fontSize: '0.75rem' }}
                />
              </ListItem>
              <ListItem sx={{ py: 0.25 }}>
                <ListItemText
                  primary="Certificações"
                  primaryTypographyProps={{ fontSize: '0.75rem' }}
                />
              </ListItem>
            </List>
          </Box>
        </Box>

        <Box mt={1} sx={{ backgroundColor: 'action.hover', p: 0.5, borderRadius: 1 }}>
          <Typography variant="caption" color="text.secondary" display="block" mb={0.5}>
            Dicas:
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={0.5}>
            <Chip
              size="small"
              label="Seja específico"
              variant="outlined"
              sx={{ height: '20px', '& .MuiChip-label': { fontSize: '0.7rem', px: 1 } }}
            />
            <Chip
              size="small"
              label="Forneça contexto"
              variant="outlined"
              sx={{ height: '20px', '& .MuiChip-label': { fontSize: '0.7rem', px: 1 } }}
            />
            <Chip
              size="small"
              label="Evite perguntas genéricas"
              variant="outlined"
              sx={{ height: '20px', '& .MuiChip-label': { fontSize: '0.7rem', px: 1 } }}
            />
          </Box>
        </Box>

        <Box mt={1}>
          <Typography variant="caption" color="text.secondary" display="block" mb={0.5}>
            Experimente:
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={0.5}>
            {randomQuestions.map((q, index) => (
              <Chip
                key={index}
                label={q}
                onClick={() => handleExampleQuestionClick(q)}
                color="primary"
                variant="outlined"
                size="small"
                sx={{
                  height: '20px',
                  '& .MuiChip-label': {
                    fontSize: '0.7rem',
                    px: 1,
                  },
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    ),
    [randomQuestions, handleExampleQuestionClick],
  );

  if (!featureAccess.hasAccess) {
    trackEvent('ai_chat_not_available', {
      licitacao_id: licitacao.id,
      reason: featureAccess.reason,
    });
    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Análise com IA</Typography>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <UpgradePrompt
            message="Análise de editais com IA não disponível no seu plano"
            reason={featureAccess.reason}
          />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            bgcolor:
              theme.palette.mode === 'dark' ? darkModeBgColor : theme.palette.background.paper,
          },
        }}
      >
        <DialogTitle
          sx={{
            color: theme.palette.getContrastText(
              theme.palette.mode === 'dark' ? darkModeBgColor : theme.palette.background.paper,
            ),
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Pergunte à LicitAI</Typography>
            <Box>
              <Button
                startIcon={<FeedbackIcon />}
                onClick={handleFeedbackOpen}
                size="small"
                sx={{ mr: 1, textTransform: 'none' }}
                color="primary"
              >
                O que achou da LicitAI?
              </Button>
              <IconButton onClick={handleClose} size="small">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              color: theme.palette.getContrastText(
                theme.palette.mode === 'dark' ? darkModeBgColor : theme.palette.background.paper,
              ),
            }}
          >
            Licitação: {licitacao.titulo}
          </Typography>
          {messages.length > 0 ? (
            <Box mt={2} maxHeight={400} overflow="auto">
              {messages.map((message) => (
                <Box key={message.id} mb={2} textAlign={message.isUser ? 'right' : 'left'}>
                  <Box
                    sx={{
                      bgcolor: message.isUser
                        ? theme.palette.primary.main
                        : theme.palette.mode === 'dark'
                          ? 'rgba(255, 255, 255, 0.05)' // Slightly lighter than the dark background
                          : theme.palette.grey[200],
                      color: message.isUser
                        ? theme.palette.primary.contrastText
                        : theme.palette.getContrastText(
                            theme.palette.mode === 'dark'
                              ? 'rgba(255, 255, 255, 0.05)'
                              : theme.palette.grey[200],
                          ),
                      p: 2,
                      borderRadius: 2,
                      display: 'inline-block',
                      maxWidth: '80%',
                      wordBreak: 'break-word',
                    }}
                  >
                    {message.isUser ? (
                      <Typography variant="body1">{message.content}</Typography>
                    ) : (
                      formatMessage(message.content)
                    )}
                  </Box>
                </Box>
              ))}
              {isLoading && (
                <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                  <AutorenewIcon sx={{ animation: 'spin 2s linear infinite', mr: 1 }} />
                  <Typography variant="body2" color="text.secondary">
                    Processando sua pergunta... Isso pode levar alguns segundos.
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            renderWelcomeMessage
          )}
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="caption"
              sx={{
                display: 'block',
                mb: 1,
                color: theme.palette.warning.main,
                fontStyle: 'italic',
                textAlign: 'center',
              }}
            >
              ⚠️ Tenha em mente que este chat está sujeito a imprecisões; é fundamental verificar as
              informações no Edital
            </Typography>
            <TextareaAutosize
              minRows={3}
              placeholder="Digite sua pergunta aqui... (Pressione Enter para enviar)"
              value={question}
              onChange={handleQuestionChange}
              onKeyDown={handleKeyPress}
              disabled={isLoading}
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderColor: theme.palette.divider,
                borderRadius: '4px',
                fontFamily: 'inherit',
                fontSize: 'inherit',
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
            disabled={!question.trim() || isLoading}
          >
            {isLoading ? 'Processando...' : 'Enviar'}
          </Button>
        </DialogActions>
      </Dialog>
      <AIChatFeedback open={feedbackOpen} onClose={() => setFeedbackOpen(false)} />
    </>
  );
};

export default AIChatModal;
