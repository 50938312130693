import { Licitacao, LicitanetLicitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class LicitanetPortalService extends BasePortalService {
  name = 'Licitanet';
  logo = '/images/portals/licitanet.png';
  portal = Portal.LicitaNET;
  portalData: LicitanetLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as LicitanetLicitacao;
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getFollowUpUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getEsclarecimentosUrl(): string | null {
    return this.portalData.linkClarify;
  }

  getImpugnacaoUrl(): string | null {
    return this.portalData.linkImpeachment;
  }

  getLoginUrl(): string | null {
    return 'https://portal.licitanet.com.br/login';
  }

  getRegisterUrl(): string | null {
    return 'https://portal.licitanet.com.br/fornecedor-publico';
  }
}
