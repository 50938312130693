import { Licitacao, PcpLicitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class PcpPortalService extends BasePortalService {
  name = 'Portal de Compras Públicas';
  logo = '/images/portals/pcp.png';
  portal = Portal.PortalDeComprasPublicas;
  portalData: PcpLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as PcpLicitacao;
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getEsclarecimentosUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }
  getImpugnacaoUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getFollowUpUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getLoginUrl(): string | null {
    return 'https://operacao.portaldecompraspublicas.com.br/18/LoginExt/';
  }

  getRegisterUrl(): string | null {
    return 'https://www.portaldecompraspublicas.com.br/adesao/fornecedor';
  }
}
