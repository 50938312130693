import { FirebaseError } from '@firebase/app';
import {
  GoogleAuthProvider,
  User,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from '@firebase/auth';
import { auth } from '@services/firebase';
import { resetAllStores } from '@utils/resetAllStores';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthState {
  user: User | null;
  isLoading: boolean;
  error: Error | FirebaseError | null;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<string>;
  signInWithToken: (token: string) => Promise<void>;
  logout: () => Promise<void>;
  clearError: () => void;
  getUser: () => User | null;
  setUser: (user: User | null) => void;
  resetPassword: (email: string) => Promise<void>;
  resetPasswordError: string | null;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      user: null,
      isLoading: false,
      error: null,
      getUser: () => {
        const state = get();
        return state.user as User | null;
      },
      signInWithToken: async (token: string) => {
        set({ isLoading: true, error: null });
        try {
          console.log('Attempting to sign in with token');
          await signOut(auth);
          localStorage.removeItem('authToken');
          resetAllStores();

          const userCredential = await signInWithCustomToken(auth, token);
          set({ user: userCredential.user, isLoading: false });
        } catch (error) {
          console.error('Error signing in with token:', error);
          const errorMessage =
            error instanceof Error ? error.message : 'Erro ao fazer login com token';
          set({ error: new Error(errorMessage), isLoading: false });
          throw error;
        }
      },
      signIn: async (email: string, password: string) => {
        set({ isLoading: true, error: null });
        try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          const token = await userCredential.user.getIdToken();
          localStorage.setItem('authToken', token);
          set({ user: userCredential.user, isLoading: false });
        } catch (error) {
          console.error('Error signing in:', error);
          let finalError: Error;
          if (error instanceof FirebaseError) {
            finalError = error;
          } else if (error instanceof Error) {
            finalError = error;
          } else {
            finalError = new Error('Erro ao fazer login');
          }
          set({ error: finalError, isLoading: false });
          throw error;
        }
      },

      signUp: async (email: string, password: string) => {
        set({ isLoading: true, error: null });
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const token = await userCredential.user.getIdToken();
          localStorage.setItem('authToken', token);
          set({ user: userCredential.user, isLoading: false });
        } catch (error) {
          console.error('Error signing up:', error);
          let finalError: Error;
          if (error instanceof FirebaseError) {
            finalError = error;
          } else if (error instanceof Error) {
            finalError = error;
          } else {
            finalError = new Error('Erro ao criar conta');
          }
          set({ error: finalError, isLoading: false });
          throw error;
        }
      },
      signInWithGoogle: async () => {
        set({ isLoading: true, error: null });
        try {
          const provider = new GoogleAuthProvider();
          const result = await signInWithPopup(auth, provider);
          const token = await result.user.getIdToken();
          localStorage.setItem('authToken', token);
          set({ user: result.user, isLoading: false });
          return token;
        } catch (error) {
          console.error('Error signing in with Google:', error);
          let finalError: Error;
          if (error instanceof FirebaseError) {
            finalError = error;
          } else if (error instanceof Error) {
            finalError = error;
          } else {
            finalError = new Error('Erro ao fazer login com Google');
          }
          set({ error: finalError, isLoading: false });
          throw error;
        }
      },
      logout: async () => {
        set({ isLoading: true, error: null });
        try {
          await signOut(auth);
          localStorage.removeItem('authToken');
          localStorage.removeItem('currentProfileId');
          localStorage.removeItem('completed-tours');
          resetAllStores();
          set({ user: null, isLoading: false });
        } catch (error) {
          console.error('Error logging out:', error);
          let finalError: Error;
          if (error instanceof FirebaseError) {
            finalError = error;
          } else if (error instanceof Error) {
            finalError = error;
          } else {
            finalError = new Error('Erro ao fazer logout');
          }
          set({ error: finalError, isLoading: false });
          throw error;
        }
      },

      clearError: () => set({ error: null }),

      setUser: (user) => set({ user }),

      resetPasswordError: null,
      resetPassword: async (email: string) => {
        set({ isLoading: true, resetPasswordError: null });
        try {
          await sendPasswordResetEmail(auth, email);
          set({ isLoading: false });
        } catch (error) {
          console.error('Error resetting password:', error);
          let errorMessage = 'Ocorreu um erro ao enviar o email de recuperação.';
          if (error instanceof FirebaseError) {
            switch (error.code) {
              case 'auth/user-not-found':
                errorMessage = 'Não existe conta com este e-mail.';
                break;
              case 'auth/invalid-email':
                errorMessage = 'E-mail inválido.';
                break;
              case 'auth/too-many-requests':
                errorMessage = 'Muitas tentativas. Tente novamente mais tarde.';
                break;
            }
          }
          set({ resetPasswordError: errorMessage, isLoading: false });
          throw error;
        }
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ user: state.user }),
    },
  ),
);
