import Api from '@services/api';
import { DailyCount } from '@types';
import { create } from 'zustand';

interface StatsState {
  dailyCounts: DailyCount[];
  isLoading: boolean;
  error: string | null;
  totalLicitacoes: number;
  averageDailyLicitacoes: number;
  hasFetched: boolean;
  lastFetchTime: number | null;
  fetchDailyCounts: (days?: number) => Promise<void>;
  refreshInterval: number | null;
  startAutoRefresh: () => void;
  stopAutoRefresh: () => void;
}

const REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes in milliseconds
const AUTO_REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes in milliseconds

export const useStatsStore = create<StatsState>()((set, get) => ({
  dailyCounts: [],
  isLoading: false,
  error: null,
  totalLicitacoes: 0,
  averageDailyLicitacoes: 0,
  hasFetched: false,
  lastFetchTime: null,
  refreshInterval: null,

  fetchDailyCounts: async (days = 30) => {
    const now = Date.now();
    const lastFetch = get().lastFetchTime;
    const shouldRefetch = !lastFetch || now - lastFetch > REFRESH_INTERVAL;

    if (get().hasFetched && !shouldRefetch) return;

    set({ isLoading: true, error: null });
    try {
      const counts = await Api.getDailyLicitacoesCount(days);
      const total = counts.reduce((sum, day) => sum + day.count, 0);
      const average = counts.length > 0 ? Math.round(total / counts.length) : 0;

      set({
        dailyCounts: counts,
        totalLicitacoes: total,
        averageDailyLicitacoes: average,
        isLoading: false,
        hasFetched: true,
        lastFetchTime: now,
      });

      get().startAutoRefresh();
    } catch (error: any) {
      console.error('Error fetching daily counts:', error);
      const errorMessage = error.message || 'Falha ao buscar contagem diária';
      set({ error: errorMessage, isLoading: false });
      throw error;
    }
  },

  startAutoRefresh: () => {
    const { refreshInterval, fetchDailyCounts } = get();
    if (refreshInterval) return;

    const interval = window.setInterval(() => {
      fetchDailyCounts(30);
    }, AUTO_REFRESH_INTERVAL);

    set({ refreshInterval: interval });
  },

  stopAutoRefresh: () => {
    const { refreshInterval } = get();
    if (refreshInterval) {
      clearInterval(refreshInterval);
      set({ refreshInterval: null });
    }
  },
}));
