import CustomButton from '@components/Input/CustomButton';
import { getModalidadeName } from '@constants';
import { useAnalytics } from '@hooks/useAnalytics';
import { WhatsApp } from '@mui/icons-material';
import { Licitacao, RecommendedLicitacao } from '@types';
import React from 'react';

type CombinedLicitacao = Licitacao | RecommendedLicitacao;

interface WhatsAppButtonProps {
  licitacao: CombinedLicitacao;
  dataTour?: string;
}

const WhatsAppButton: React.FC<WhatsAppButtonProps> = ({ licitacao, dataTour }) => {
  const { trackEvent } = useAnalytics();

  const getWhatsAppShareLink = () => {
    const abertura = new Date(licitacao.abertura_datetime || new Date());
    const text = encodeURIComponent(
      `Confira esta licitação:\n\nTítulo: ${licitacao.titulo}\nÓrgão: ${licitacao.orgao}\nModalidade: ${licitacao.tipo || getModalidadeName(licitacao.id_tipo)}\nData de Abertura: ${abertura.toLocaleString()}\n\nMais detalhes: ${window.location.href}`,
    );
    return `https://api.whatsapp.com/send?text=${text}`;
  };

  const handleShare = () => {
    trackEvent('whatsapp_share_clicked', {
      licitacao_id: licitacao.id,
      portal: licitacao.portal,
      has_datetime: Boolean(licitacao.abertura_datetime),
      modalidade: licitacao.tipo || getModalidadeName(licitacao.id_tipo),
      url_length: window.location.href.length,
      text_length: decodeURIComponent(getWhatsAppShareLink().split('text=')[1]).length,
    });
  };

  return (
    <CustomButton
      data-tour={dataTour}
      href={getWhatsAppShareLink()}
      icon={<WhatsApp sx={{ fontSize: '1.25rem' }} />}
      text="Compartilhar via WhatsApp"
      backgroundColor="#25D366"
      hoverColor="#1da750"
      onClick={handleShare}
    />
  );
};

export default WhatsAppButton;
