import { Box, SxProps, useTheme } from '@mui/material';
import React from 'react';

interface CustomButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  icon: React.ReactNode;
  text: string;
  backgroundColor?: string;
  hoverColor?: string;
  href?: string;
  dataTour?: string;
  disabled?: boolean;
  sx?: SxProps;
  title?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  icon,
  text,
  backgroundColor = '#1976d2',
  hoverColor = '#1565c0',
  href,
  dataTour,
  disabled = false,
  title,
  sx = {},
}) => {
  const theme = useTheme();

  return (
    <Box
      data-tour={dataTour}
      title={title}
      component={href ? 'a' : 'button'}
      onClick={disabled ? undefined : onClick}
      href={disabled ? undefined : href}
      target={href && !disabled ? '_blank' : undefined}
      rel={href && !disabled ? 'noopener noreferrer' : undefined}
      sx={{
        ...sx,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: disabled ? 'not-allowed' : 'pointer',
        userSelect: 'none',
        verticalAlign: 'middle',
        appearance: 'none',
        fontFamily: theme.typography.button.fontFamily,
        lineHeight: theme.typography.button.lineHeight,
        fontSize: '0.875rem',
        fontWeight: 600,
        minWidth: 64,
        padding: '6px 16px',
        borderRadius: '8px',
        border: 0,
        color: '#fff',
        textDecoration: 'none',
        backgroundColor: backgroundColor,
        opacity: disabled ? 0.5 : 1,
        transition: theme.transitions.create(['opacity', 'box-shadow'], { duration: 250 }),
        '&:hover': {
          backgroundColor: hoverColor,
          opacity: disabled ? 0.5 : 1,
        },
        '& svg': {
          opacity: disabled ? 0.7 : 1,
        },
      }}
    >
      <Box sx={{ marginRight: 1, display: 'flex', alignItems: 'center' }}>{icon}</Box>
      {text}
    </Box>
  );
};

export default CustomButton;
