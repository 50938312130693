import AddToCalendarButton from '@components/Licitacao/AddToCalendarButton';
import {
  AddTask as AddTaskIcon,
  CheckCircle as CheckCircleIcon,
  Event as EventIcon,
} from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useKanbanStore } from '@store/kanbanStore';
import { Licitacao, PortalService } from '@types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface DoneStepProps {
  portalService: PortalService;
  licitacao: Licitacao;
  portalName: string;
}

export const DoneStep: React.FC<DoneStepProps> = ({ licitacao }) => {
  const navigate = useNavigate();
  const { addKanbanLicitacoes } = useKanbanStore();
  const [isAddingToKanban, setIsAddingToKanban] = useState(false);

  const handleAddToKanban = async () => {
    setIsAddingToKanban(true);
    try {
      await addKanbanLicitacoes('oportunidade', [licitacao.id], {});
      navigate('/acompanhando/kanban');
    } catch (error) {
      console.error('Failed to add to kanban:', error);
    } finally {
      setIsAddingToKanban(false);
    }
  };

  const formattedDate = licitacao.abertura_datetime
    ? format(new Date(licitacao.abertura_datetime), "dd 'de' MMMM 'de' yyyy 'às' HH:mm", {
        locale: ptBR,
      })
    : 'Data não definida';

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
      {/* Success Message */}
      <Box
        sx={{
          bgcolor: 'success.light',
          border: 1,
          borderColor: 'success.main',
          p: 2,
          borderRadius: 1,
          mb: 2,
          width: '100%',
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <CheckCircleIcon color="success" />
          <Typography variant="subtitle2" color="success.dark" sx={{ fontSize: '0.875rem' }}>
            Você completou todos os passos para participar da licitação! Mantenha-se conectado ao
            portal para prosseguir com o processo.
          </Typography>
        </Stack>
      </Box>

      {/* Add to Kanban Button */}
      <Box sx={{ mb: 2, width: '100%' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddTaskIcon />}
          onClick={handleAddToKanban}
          disabled={isAddingToKanban}
          fullWidth
        >
          {isAddingToKanban ? 'Adicionando ao Kanban...' : 'Adicionar ao Kanban'}
        </Button>
      </Box>

      {/* Content */}
      <Box sx={{ display: 'flex', flex: 1, gap: 2, width: '100%' }}>
        {/* Left Column */}
        <Box
          sx={{
            flex: 1,
            width: '50%',
            '& .MuiTypography-body1, & .MuiTypography-body2': { fontSize: '0.875rem' },
          }}
        >
          <Typography variant="h6" color="primary" gutterBottom sx={{ fontSize: '1rem' }}>
            Próximos Passos
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Typography
              variant="subtitle1"
              color="primary"
              gutterBottom
              sx={{ fontSize: '0.875rem' }}
            >
              Sessão Pública
            </Typography>
            <Stack spacing={2}>
              <Stack direction="row" spacing={1} alignItems="center">
                <EventIcon color="primary" fontSize="small" />
                <Typography variant="body2">Data de abertura: {formattedDate}</Typography>
              </Stack>
              <AddToCalendarButton licitacao={licitacao} />
            </Stack>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography
              variant="subtitle1"
              color="primary"
              gutterBottom
              sx={{ fontSize: '0.875rem' }}
            >
              Durante a Sessão
            </Typography>
            <Typography variant="body2" component="div">
              <ul>
                <li>Mantenha-se conectado ao portal durante toda a sessão</li>
                <li>Acompanhe as mensagens do pregoeiro no chat</li>
                <li>Esteja preparado para a fase de lances</li>
                <li>Responda prontamente às solicitações de negociação</li>
                <li>Tenha os documentos complementares em mãos</li>
              </ul>
            </Typography>
          </Box>
        </Box>

        {/* Right Column */}
        <Box
          sx={{
            flex: 1,
            width: '50%',
            '& .MuiTypography-body1, & .MuiTypography-body2': { fontSize: '0.875rem' },
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              color="primary"
              gutterBottom
              sx={{ fontSize: '0.875rem' }}
            >
              Após a Sessão
            </Typography>
            <Typography variant="body2" component="div">
              <ul>
                <li>Monitore o resultado da licitação</li>
                <li>Acompanhe o prazo para recursos</li>
                <li>Verifique eventuais convocações para envio de documentos</li>
                <li>Prepare-se para assinatura do contrato se vencedor</li>
              </ul>
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography
              variant="subtitle1"
              color="primary"
              gutterBottom
              sx={{ fontSize: '0.875rem' }}
            >
              Em Caso de Vitória
            </Typography>
            <Typography variant="body2" component="div">
              <ul>
                <li>Envie a documentação original no prazo estabelecido</li>
                <li>Providencie a garantia contratual, se exigida</li>
                <li>Assine o contrato dentro do prazo</li>
                <li>Prepare a logística para o fornecimento</li>
                <li>Mantenha contato com o fiscal do contrato</li>
              </ul>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
