import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { ProdespLicitacao } from '../../types';

interface ProdespDetailsProps {
  details: ProdespLicitacao;
}

const ProdespDetails: React.FC<ProdespDetailsProps> = ({ details }) => {
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="primary">
            Informações adicionais do portal
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Organização:</strong> {details.organizacao}
            </Typography>
            <Typography>
              <strong>UO:</strong> {details.uo}
            </Typography>
            <Typography>
              <strong>UGO:</strong> {details.ugo}
            </Typography>
            <Typography>
              <strong>UGE:</strong> {details.uge}
            </Typography>
            <Typography>
              <strong>Órgão:</strong> {details.orgao}
            </Typography>
            <Typography>
              <strong>Modalidade:</strong> {details.modalidade}
            </Typography>
            <Typography>
              <strong>Número:</strong> {details.numero}
            </Typography>
            <Typography>
              <strong>Processo:</strong> {details.processo}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Publicado em:</strong> {details.publicadoEm}
            </Typography>
            <Typography>
              <strong>Local de Execução:</strong> {details.localExecucao}
            </Typography>
            <Typography>
              <strong>Abertura:</strong> {details.abertura}
            </Typography>
            <Typography>
              <strong>Área:</strong> {details.area}
            </Typography>
            <Typography>
              <strong>Subárea:</strong> {details.subarea}
            </Typography>
            <Typography>
              <strong>Registro de Preço:</strong> {details.registroPreco}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>Objeto da Licitação:</strong> {details.objetoLicitacao}
            </Typography>
          </Grid>
          {details.eventos.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom style={{ marginTop: '1rem' }}>
                Eventos
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Evento</TableCell>
                      <TableCell>Data de Publicação</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {details.eventos.map((evento, index) => (
                      <TableRow key={index}>
                        <TableCell>{evento.evento}</TableCell>
                        <TableCell>{evento.dataPublicacao}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProdespDetails;
