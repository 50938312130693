import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(`/configuracoes/${newValue}`);
  };

  const value = location.pathname.split('/').pop() || 'pagamento';

  return (
    <>
      <Helmet>
        <title>Configurações - Licitou</title>
        <meta name="description" content="Configure seu perfil e preferências de pagamento" />
      </Helmet>
      <Box sx={{ my: 4 }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider' }}
        >
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            sx={{ width: '20%', flexShrink: 0 }}
          >
            Configurações
          </Typography>
          <Tabs value={value} onChange={handleChange} aria-label="settings tabs" sx={{ flex: 1 }}>
            <Tab label="Pagamento" value="pagamento" />
            <Tab label="Perfil do Usuário" value="perfil" />
            <Tab label="Notificações" value="notificacoes" />
          </Tabs>
        </Box>
        <Box sx={{ p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Settings;
