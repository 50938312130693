import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Box, Chip, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { Bulletin } from '@types';

interface BulletinCardProps {
  bulletin: Bulletin;
  isSelected: boolean;
  onBulletinClick: (id: string) => void;
  onMenuOpen: (event: React.MouseEvent<HTMLElement>, id: string) => void;
}

const BulletinCard: React.FC<BulletinCardProps> = ({
  bulletin,
  isSelected,
  onBulletinClick,
  onMenuOpen,
}) => {
  const theme = useTheme();

  return (
    <Paper
      className="bulletin-card"
      elevation={3}
      sx={{
        p: 2,
        backgroundColor: isSelected
          ? theme.palette.action.selected
          : theme.palette.background.paper,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography
          variant="h6"
          onClick={() => onBulletinClick(bulletin.id)}
          sx={{ cursor: 'pointer' }}
        >
          {bulletin.name}
        </Typography>
        <IconButton onClick={(e) => onMenuOpen(e, bulletin.id)} size="small">
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box onClick={() => onBulletinClick(bulletin.id)} sx={{ cursor: 'pointer' }}>
        <Chip label={`${bulletin.licitacoesCount} licitações`} color="primary" size="small" />
      </Box>
    </Paper>
  );
};

export default BulletinCard;
