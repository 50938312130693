import { Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class GenericPortalService extends BasePortalService {
  name = 'Portal Genérico';
  logo = '/images/portals/generic.png';
  portal = Portal.DemaisPortais;

  getLoginStepImage(): string {
    return '/images/portals/generic-portal-login.png';
  }
}
