import { BanrisulLicitacao, Licitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class BanrisulPortalService extends BasePortalService {
  name = 'Banrisul';
  logo = '/images/portals/banrisul.png';
  portal = Portal.Banrisul;
  portalData: BanrisulLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as BanrisulLicitacao;
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getFollowUpUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getLoginUrl(): string | null {
    return 'https://portaldofornecedor.rs.gov.br/fornecedor#/home';
  }

  getRegisterUrl(): string | null {
    return 'https://portaldofornecedor.rs.gov.br/fornecedor#/home';
  }
}
