import { AttachMoney, Email, Phone } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { BllBncLicitacao, Licitacao } from '../../types';

interface BncDetailsProps {
  details: BllBncLicitacao;
  licitacao: Licitacao;
}

const BncDetails: React.FC<BncDetailsProps> = ({ details, licitacao }) => {
  function getProposalLink() {
    try {
      const url = new URL(licitacao.linkExterno);

      // if (details.processId) {
      //   return `${url.origin}/Proposal/Proposal?param1=${details.processId}`;
      // }
      return `${url.origin}/Participant/ProcessSearch?param1=0`;
    } catch (error) {
      console.error('Invalid URL:', licitacao.linkExterno, error);
    }
    return '#';
  }
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="primary">
            Informações adicionais do portal
          </Typography>
          {/* {portalLink && (
            <Button
              href={portalLink}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="secondary"
              startIcon={<OpenInNew />}
            >
              Acompanhar no portal
            </Button>
          )} */}
          <Button
            href={getProposalLink()}
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="primary"
            startIcon={<AttachMoney />}
          >
            Cadastrar Proposta
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Promotor:</strong> {details.promotor}
            </Typography>
            <Typography>
              <strong>Nº Edital:</strong> {details.nEdital}
            </Typography>
            <Typography>
              <strong>Nº Proc. Adm.:</strong> {details.nProcAdm}
            </Typography>
            <Typography>
              <strong>Modalidade:</strong> {details.modalidade}
            </Typography>
            <Typography>
              <strong>Fase:</strong> {details.fase}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Condutor:</strong> {details.condutor}
            </Typography>
            <Typography>
              <strong>Autoridade:</strong> {details.autoridade}
            </Typography>
            <Typography>
              <strong>Tipo Contrato:</strong> {details.tipoContrato}
            </Typography>
            <Typography>
              <strong>Publicação:</strong> {details.publicacao}
            </Typography>
            <Typography>
              <strong>Valor Total:</strong> {details.valorTotalDoProcesso}
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography><strong>Objeto:</strong> {details.objeto}</Typography>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Início Rec. Proposta:</strong> {details.inicioRecProposta}
            </Typography>
            <Typography>
              <strong>Fim Rec. Proposta:</strong> {details.fimRecProposta}
            </Typography>
            <Typography>
              <strong>Início Disputa:</strong> {details.inicioDisputa}
            </Typography>
            <Typography>
              <strong>Fim Impugnação:</strong> {details.fimImpugnacao}
            </Typography>
            <Typography>
              <strong>Fim Esclarecimentos:</strong> {details.fimEsclarecimentos}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Receb. Recursos:</strong> {details.recebRecursos}
            </Typography>
            <Typography>
              <strong>Receb. Contra Razão:</strong> {details.recebContraRazao}
            </Typography>
            <Typography>
              <strong>Manif. Recursos:</strong> {details.manifRecursos}
            </Typography>
            <Typography>
              <strong>Regulamento:</strong> {details.regulamento}
            </Typography>
            <Typography>
              <strong>Validade:</strong> {details.validade}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Tipo de Lance:</strong> {details.tipoDeLance}
            </Typography>
            <Typography>
              <strong>Modo de Disputa:</strong> {details.modoDeDisputa}
            </Typography>
            <Typography>
              <strong>Tempo Inicial:</strong> {details.tempoInicial}
            </Typography>
            <Typography>
              <strong>Tempo Final:</strong> {details.tempoFinal}
            </Typography>
            <Typography>
              <strong>Ano Referência:</strong> {details.anoReferencia}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Exclusivo ME:</strong> {details.exclusivoME}
            </Typography>
            <Typography>
              <strong>Exclusivo Regional:</strong> {details.exclusivoRegional}
            </Typography>
            <Typography>
              <strong>Exclusivo Local:</strong> {details.exclusivoLocal}
            </Typography>
            <Typography>
              <strong>Mensagens:</strong> {details.mensagens}
            </Typography>
            <Typography>
              <strong>Cadastro Reserva:</strong> {details.cadastroReserva}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Contato do Promotor
            </Typography>
            {details.fonePromotor && (
              <Typography>
                <Phone sx={{ verticalAlign: 'middle', mr: 1 }} />
                <Link href={`tel:${details.fonePromotor.replace(/\D/g, '')}`} color="primary">
                  {details.fonePromotor}
                </Link>
              </Typography>
            )}
            {details.emailPromotor && (
              <Typography>
                <Email sx={{ verticalAlign: 'middle', mr: 1 }} />
                <Link href={`mailto:${details.emailPromotor}`} color="primary">
                  {details.emailPromotor}
                </Link>
              </Typography>
            )}
          </Grid>
          {details.observacao && (
            <Grid item xs={12}>
              <Typography>
                <strong>Observação:</strong> {details.observacao}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BncDetails;
