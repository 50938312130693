import { Box, Paper, Skeleton } from '@mui/material';

const BulletinCardSkeleton: React.FC = () => {
  return (
    <Paper elevation={3} sx={{ p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Skeleton variant="text" width="70%" height={32} />
        <Skeleton variant="circular" width={24} height={24} />
      </Box>
      <Skeleton variant="rounded" width={120} height={24} />
    </Paper>
  );
};

export default BulletinCardSkeleton;
