import { Person } from '@mui/icons-material';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { PcpLicitacao } from '../../types';

interface PcpDetailsProps {
  details: PcpLicitacao;
}

const PcpDetails: React.FC<PcpDetailsProps> = ({ details }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary">
          Informações adicionais do portal
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Código:</strong> {details.codigoLicitacao}
            </Typography>
            <Typography>
              <strong>Número do Processo:</strong> {details.numeroProcesso}
            </Typography>
            <Typography>
              <strong>Comprador:</strong> {details.razaoSocialComprador}
            </Typography>
            <Typography>
              <strong>Cidade/Estado:</strong> {details.cidadeEstadoComprador || 'N/A'}
            </Typography>
            <Typography>
              <strong>Modalidade:</strong> {details.tipoLicitacao}
            </Typography>
            <Typography>
              <strong>Tipo de Pregão:</strong> {details.tipoPregao}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Status:</strong> {details.statusProcesso.descricao}
            </Typography>
            <Typography>
              <strong>Tipo de Julgamento:</strong> {details.tipoJulgamento}
            </Typography>
            <Typography>
              <strong>Tratamento de Lances:</strong> {details.tratamentoFasesLances}
            </Typography>
            <Typography>
              <strong>Operação:</strong> {details.operacao}
            </Typography>
            <Typography>
              <strong>Origem do Recurso:</strong> {details.origemRecurso}
            </Typography>
            <Typography>
              <strong>Lei Aplicável:</strong> {details.legislacaoAplicavel}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography>
              <strong>Resumo:</strong> {details.resumo}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Data de Abertura:</strong>{' '}
              {new Date(details.dataHoraAbertura).toLocaleString()}
            </Typography>
            <Typography>
              <strong>Data de Fechamento:</strong>{' '}
              {details.dataHoraFechamento
                ? new Date(details.dataHoraFechamento).toLocaleString()
                : 'N/A'}
            </Typography>
            <Typography>
              <strong>Início Recebimento Propostas:</strong>{' '}
              {new Date(details.dataHoraInicioRecebimentoPropostas).toLocaleString()}
            </Typography>
            <Typography>
              <strong>Fim Recebimento Propostas:</strong>{' '}
              {new Date(details.dataHoraFinalRecebimentoPropostas).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Limite Impugnações:</strong>{' '}
              {new Date(details.dataHoraLimiteImpugnacoes).toLocaleString()}
            </Typography>
            <Typography>
              <strong>Limite Esclarecimentos:</strong>{' '}
              {new Date(details.dataHoraLimiteEsclarecimentos).toLocaleString()}
            </Typography>
            <Typography>
              <strong>Data de Publicação:</strong>{' '}
              {new Date(details.dataHoraPublicacao).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Responsáveis
            </Typography>
            <Typography>
              <Person sx={{ verticalAlign: 'middle', mr: 1 }} /> <strong>Pregoeiro:</strong>{' '}
              {details.nomePregoeiro || 'N/A'}
            </Typography>
            <Typography>
              <Person sx={{ verticalAlign: 'middle', mr: 1 }} />{' '}
              <strong>Autoridade Competente:</strong> {details.nomeAutoridadeCompetente}
            </Typography>
            <Typography>
              <Person sx={{ verticalAlign: 'middle', mr: 1 }} /> <strong>Apoio:</strong>{' '}
              {details.nomeApoio.join(', ')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PcpDetails;
