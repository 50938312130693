import PromoteFavoritesModal from '@components/Following/PromoteFavoritesModal';
import { useNotification } from '@contexts/NotificationContext';
import { Add as AddIcon, CalendarMonth, List, ViewKanban } from '@mui/icons-material';
import { Box, Button, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Following: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { totalFollowing, isLoading, error } = useFollowingStore();
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
    }
  }, [error, showNotification]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(`/acompanhando/${newValue}`);
  };

  const value = location.pathname.split('/').pop() || 'lista';

  const TabLabel: React.FC<{ label: string; icon: React.ReactNode }> = ({ label, icon }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
      {React.cloneElement(icon as React.ReactElement, { sx: { mr: 1 } })}
      {label}
    </Box>
  );

  const handlePromoteSuccess = () => {
    showNotification('Licitações promovidas com sucesso', 'success');
  };

  const getViewTitle = () => {
    switch (value) {
      case 'lista':
        return 'Lista';
      case 'kanban':
        return 'Kanban';
      case 'agenda':
        return 'Agenda';
      default:
        return 'Lista';
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Acompanhando (${totalFollowing}) - ${getViewTitle()}`}</title>
        <meta
          name="description"
          content="Acompanhe suas licitações em diferentes visualizações: lista, kanban ou agenda"
        />
      </Helmet>
      <Box sx={{ my: 4 }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider' }}
        >
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            sx={{ width: '20%', flexShrink: 0 }}
          >
            Acompanhando ({totalFollowing})
            {isLoading && <CircularProgress size={20} sx={{ ml: 2 }} />}
          </Typography>
          <Box
            sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="following tabs"
              sx={{
                flex: 1,
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: 'medium',
                  fontSize: '1rem',
                  minHeight: '48px',
                  padding: '6px 16px',
                },
                '& .Mui-selected': {
                  fontWeight: 'bold',
                },
              }}
            >
              <Tab label={<TabLabel label="Lista" icon={<List />} />} value="lista" />
              <Tab label={<TabLabel label="Kanban" icon={<ViewKanban />} />} value="kanban" />
              <Tab label={<TabLabel label="Agenda" icon={<CalendarMonth />} />} value="agenda" />
            </Tabs>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setIsPromoteModalOpen(true)}
              sx={{ ml: 2 }}
            >
              Promover Favoritos
            </Button>
          </Box>
        </Box>
        <PromoteFavoritesModal
          open={isPromoteModalOpen}
          onClose={() => setIsPromoteModalOpen(false)}
          onSuccess={handlePromoteSuccess}
        />
        <Box sx={{ p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Following;
