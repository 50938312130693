import { Licitacao, PncpLicitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class PncpPortalService extends BasePortalService {
  name = 'PNCP';
  logo = '/images/portals/pncp.png';
  portal = Portal.PortalNacionalDeComprasPublicas;
  portalData: PncpLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as PncpLicitacao;
  }

  private getExternalLink(): string | null {
    if (!this.portalData) {
      return this.ensureHttps(this.licitacao.linkExterno);
    }
    return (
      this.portalData.linkProcessoEletronico ||
      this.portalData.linkSistemaOrigem ||
      this.licitacao.linkExterno
    );
  }

  getPortalUrl(): string | null {
    return this.getExternalLink();
  }

  getApplyUrl(): string | null {
    return this.getExternalLink();
  }

  getFollowUpUrl(): string | null {
    return this.getExternalLink();
  }

  getLoginUrl(): string | null {
    return this.getExternalLink();
  }

  getRegisterUrl(): string | null {
    return this.getExternalLink();
  }
}
