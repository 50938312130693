import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';

const LicitacaoCardSkeleton = () => {
  const InfoItemSkeleton = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Skeleton variant="circular" width={20} height={20} />
      <Box sx={{ flex: 1 }}>
        <Skeleton width={60} height={16} />
        <Skeleton width={100} height={20} />
      </Box>
    </Box>
  );

  return (
    <Card sx={{ mb: 2, boxShadow: 3, maxWidth: '1200px !important', width: '100%', mx: 'auto' }}>
      <CardContent>
        <Box mb={2}>
          <Skeleton width={200} height={24} />
          <Skeleton width={300} height={20} />
        </Box>

        <Grid container spacing={2}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={2} key={index}>
              <InfoItemSkeleton />
            </Grid>
          ))}
        </Grid>

        <Box mt={2}>
          <Skeleton width="100%" height={60} />
          <Skeleton width="90%" height={20} />
          <Skeleton width="95%" height={20} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default LicitacaoCardSkeleton;
