import Api from '@services/api';
import { create } from 'zustand';

interface AnnotationsState {
  annotations: { [licitacaoId: string]: string };
  isLoading: boolean;
  error: string | null;
  updateAnnotation: (licitacaoId: string, content: string) => Promise<void>;
  removeAnnotation: (licitacaoId: string) => Promise<void>;
  getAnnotation: (licitacaoId: string) => string;
  fetchAnnotations: (force?: boolean) => Promise<void>;
  lastFetch: number | null;
  resetStore: () => void;
}

export const useAnnotationsStore = create<AnnotationsState>()((set, get) => ({
  annotations: {},
  isLoading: false,
  error: null,
  lastFetch: null,
  fetchAnnotations: async (force = false) => {
    const now = Date.now();
    const lastFetch = get().lastFetch;
    const CACHE_DURATION = 5 * 60 * 1000;

    if (!force && lastFetch && now - lastFetch < CACHE_DURATION) {
      return;
    }

    set({ isLoading: true, error: null });
    try {
      const { annotations } = await Api.getAnnotations();
      const annotationsMap = annotations.reduce(
        (acc, { licitacaoId, content }) => {
          acc[licitacaoId] = content;
          return acc;
        },
        {} as { [key: string]: string },
      );

      set({ annotations: annotationsMap, isLoading: false, lastFetch: now });
    } catch (error: any) {
      console.error('Error fetching annotations:', error);
      const errorMessage = error.message || 'Erro ao carregar anotações';
      set({ error: errorMessage, isLoading: false });
      throw error;
    }
  },
  updateAnnotation: async (licitacaoId: string, content: string) => {
    const previousContent = get().annotations[licitacaoId] || '';
    set({ isLoading: true, error: null });
    try {
      await Api.upsertAnnotation(licitacaoId, content);
      set((state) => ({
        annotations: {
          ...state.annotations,
          [licitacaoId]: content,
        },
        isLoading: false,
      }));
    } catch (error: any) {
      console.error('Error updating annotation:', error);
      const errorMessage = error.message || 'Erro ao atualizar anotação';
      set((state) => ({
        annotations: {
          ...state.annotations,
          [licitacaoId]: previousContent,
        },
        error: errorMessage,
        isLoading: false,
      }));
      throw error;
    }
  },

  removeAnnotation: async (licitacaoId: string) => {
    const previousAnnotations = { ...get().annotations };
    set({ isLoading: true, error: null });
    try {
      await Api.removeAnnotation(licitacaoId);
      set((state) => {
        const newAnnotations = { ...state.annotations };
        delete newAnnotations[licitacaoId];
        return { annotations: newAnnotations, isLoading: false };
      });
    } catch (error) {
      console.error('Error removing annotation:', error);
      set({
        annotations: previousAnnotations,
        error: 'Erro ao remover anotação',
        isLoading: false,
      });
    }
  },

  getAnnotation: (licitacaoId: string) => {
    return get().annotations[licitacaoId] || '';
  },
  resetStore: () =>
    set({
      annotations: {},
      isLoading: false,
      error: null,
      lastFetch: null,
    }),
}));
