import { Description, OpenInNew } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { DouLicitacao, Licitacao } from '../../types';

interface DouDetailsProps {
  details: DouLicitacao;
  licitacao: Licitacao;
}

const DouDetails: React.FC<DouDetailsProps> = ({ details }) => {
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="primary">
            Informações adicionais do portal DOU
          </Typography>
          <Box>
            {details.versaoCertificadaLink && (
              <Button
                href={details.versaoCertificadaLink}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                color="primary"
                startIcon={<OpenInNew />}
                sx={{ mr: 1 }}
              >
                Versão Certificada
              </Button>
            )}
            {details.diarioCompletoLink && (
              <Button
                href={details.diarioCompletoLink}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                color="secondary"
                startIcon={<Description />}
              >
                Diário Completo
              </Button>
            )}
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              <strong>Título:</strong> {details.titulo || 'Não informado'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Número do Edital:</strong> {details.numeroEdital || 'Não informado'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Pregoeiro:</strong> {details.pregoeiro || 'Não informado'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Conteúdo da Publicação
            </Typography>
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              {details.conteudo.replace(/\n/g, '\n\n')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DouDetails;
