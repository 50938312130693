import { Licitacao, LicitaconLicitacao, Portal } from '@types';
import { BasePortalService } from './BasePortal';

export class LicitaconPortalService extends BasePortalService {
  name = 'Licitacon';
  logo = '/images/portals/licitacon.png';
  portal = Portal.LCON;
  portalData: LicitaconLicitacao;

  constructor(licitacao: Licitacao) {
    super(licitacao);
    this.portalData = licitacao.portalData as LicitaconLicitacao;
  }

  getPortalUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getApplyUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getFollowUpUrl(): string | null {
    return this.ensureHttps(this.licitacao.linkExterno);
  }

  getLoginUrl(): string | null {
    return 'https://auth.tce.rs.gov.br/app/tcelogin/login.action?id=100337705542887&sistema=115&url=https://portal.tce.rs.gov.br/aplicprod/f?p=50600:1::::::';
  }

  getRegisterUrl(): string | null {
    return 'https://tcers.tc.br/sistemas-de-controle-externo/?section=LICITACON';
  }
}
