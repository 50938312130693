import BanrisulDetails from '@components/Details/Banrisul';
import BllBncDetails from '@components/Details/BllBnc';
import ComprasbrDetails from '@components/Details/Comprasbr';
import ComprasbrDispensaDetails from '@components/Details/ComprasbrDispensa';
import ComprasnetDetails from '@components/Details/Comprasnet';
import DouDetails from '@components/Details/Dou';
import LicitanetDetails from '@components/Details/Licitanet';
import LicitarDigitalDetails from '@components/Details/Licitardigital';
import PcpDetails from '@components/Details/Pcp';
import PncpDetails from '@components/Details/Pncp';
import ProdespDetails from '@components/Details/Prodesp';
import { Licitacao, Portal } from '@types';
import React from 'react';
interface PortalDetailsProps {
  portalData: any;
  licitacao: Licitacao;
}

const PortalDetails: React.FC<PortalDetailsProps> = ({ portalData, licitacao }) => {
  const licitacaoId = licitacao.id_licitacao;
  const portalId = licitacao.id_portal.toString();
  switch (portalId) {
    case Portal.PortalNacionalDeComprasPublicas:
    case Portal.BBMNET:
    case Portal.PortalDeComprasPublicas:
      if (licitacaoId?.startsWith('PCP-')) {
        return <PcpDetails details={portalData} />;
      }
      return <PncpDetails details={portalData} licitacao={licitacao} />;
    case Portal.ComprasGovernamentais:
      if (licitacaoId?.startsWith('CN-') && !portalData?.comprasnetData) {
        return <ComprasnetDetails details={portalData} licitacao={licitacao} />;
      }
      return <PncpDetails details={portalData} licitacao={licitacao} />;
    case Portal.PRODESP:
      return <ProdespDetails details={portalData} />;
    case Portal.BLLCompras:
    case Portal.BNCCompras:
      return <BllBncDetails details={portalData} licitacao={licitacao} />;
    case Portal.LicitaNET:
      return <LicitanetDetails details={portalData} />;
    case Portal.ComprasBR:
      if (licitacaoId?.startsWith('CBR-D-')) {
        return <ComprasbrDispensaDetails details={portalData} />;
      }
      return <ComprasbrDetails details={portalData} />;
    case Portal.LicitacoesEBancoDoBrasil:
      // case Portal.BECSP:
      return null;
    case Portal.DemaisPortais:
    case Portal.DOU:
    case Portal.Banrisul:
    case Portal.LicitarDigital:
    default:
      if (licitacaoId?.startsWith('BRS-') || licitacaoId?.startsWith('RS-')) {
        return <BanrisulDetails details={portalData} licitacao={licitacao} />;
      }
      if (licitacaoId?.startsWith('LD-')) {
        return <LicitarDigitalDetails details={portalData} />;
      }
      if (licitacaoId?.startsWith('TCE-PR-')) {
        return <PncpDetails details={portalData} licitacao={licitacao} />;
      }

      if (licitacaoId?.startsWith('DOU-')) {
        return <DouDetails details={portalData} licitacao={licitacao} />;
      }
      return null;
  }
};

export default PortalDetails;
