import { Email, OpenInNew, Phone } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { LicitanetLicitacao } from '../../types';

interface LicitanetDetailsProps {
  details: LicitanetLicitacao;
}

const LicitanetDetails: React.FC<LicitanetDetailsProps> = ({ details }) => {
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="primary">
            Informações adicionais do portal
          </Typography>
          {details.allowedClarificationRequest && (
            <>
              <Button
                href={details.linkClarify}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                color="secondary"
                startIcon={<OpenInNew />}
              >
                Pedido de Esclarecimento
              </Button>
              <Button
                href={details.linkImpeachment}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                color="primary"
                startIcon={<OpenInNew />}
              >
                Pedido de Impugnação
              </Button>
            </>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Status:</strong> {details.status}
            </Typography>
            <Typography>
              <strong>Comprador:</strong> {details.buyer}
            </Typography>
            <Typography>
              <strong>Número:</strong> {details.number}
            </Typography>
            <Typography>
              <strong>Processo:</strong> {details.biddingProcess}
            </Typography>
            <Typography>
              <strong>Ano:</strong> {details.year}
            </Typography>
            <Typography>
              <strong>Modalidade:</strong> {details.disputeModeText}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Data de Publicação:</strong> {details.datPublication}
            </Typography>
            <Typography>
              <strong>Data de Início da Sessão:</strong> {details.datStartSession}
            </Typography>
            <Typography>
              <strong>Limite para Esclarecimentos:</strong> {details.datClarificationRequestLimit}
            </Typography>
            <Typography>
              <strong>Cidade:</strong> {details.city}
            </Typography>
            <Typography>
              <strong>UF:</strong> {details.uf}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography>
              <strong>Descrição:</strong> {details.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Contato do Comprador
            </Typography>
            {details.phone && (
              <Typography>
                <Phone sx={{ verticalAlign: 'middle', mr: 1 }} />
                <Link href={`tel:${details.phone.toString().replace(/\D/g, '')}`} color="primary">
                  {details.phone}
                </Link>
              </Typography>
            )}
            {details.email && (
              <Typography>
                <Email sx={{ verticalAlign: 'middle', mr: 1 }} />
                <Link href={`mailto:${details.email}`} color="primary">
                  {details.email}
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LicitanetDetails;
