import AIChatModal from '@components/Licitacao/AIChatModal';
import {
  Chat as ChatIcon,
  Gavel as GavelIcon,
  Help as HelpIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { FeatureId, Licitacao, PortalService } from '@types';
import { useState } from 'react';

interface DoubtsStepProps {
  licitacao: Licitacao;
  portalService: PortalService;
  portalName?: string;
}

export const DoubtsStep: React.FC<DoubtsStepProps> = ({
  licitacao,
  portalService,
  portalName = 'portal',
}) => {
  const [aiChatOpen, setAIChatOpen] = useState(false);
  const { checkFeatureAccess } = useProfileStore();
  const hasAiAccess = checkFeatureAccess(FeatureId.AI_ANALYSIS).hasAccess;

  return (
    <>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
        {/* Content */}
        <Box sx={{ display: 'flex', flex: 1, gap: 2, width: '100%' }}>
          {/* Left Column */}
          <Box
            sx={{
              flex: 1,
              width: '50%',
              '& .MuiTypography-body1, & .MuiTypography-body2': { fontSize: '0.875rem' },
            }}
          >
            <Typography variant="h6" color="primary" gutterBottom sx={{ fontSize: '1rem' }}>
              Esclarecimentos e Impugnações
            </Typography>

            <Typography variant="body1" gutterBottom>
              Pedidos de esclarecimentos e impugnações devem ser enviados através do site{' '}
              {portalName} ou conforme instruções no edital.
            </Typography>

            <Box sx={{ mt: 2 }}>
              <Typography
                variant="subtitle1"
                color="primary"
                gutterBottom
                sx={{ fontSize: '0.875rem' }}
              >
                Prazos Importantes
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
                <WarningIcon color="warning" fontSize="small" />
                <Typography variant="body2" color="text.secondary">
                  Fique atento aos prazos para envio de questionamentos:
                </Typography>
              </Stack>
              <Typography variant="body2" component="div">
                <ul>
                  <li>Esclarecimentos: até 3 dias úteis antes da abertura</li>
                  <li>Impugnações: até 3 dias úteis antes da abertura</li>
                  <li>Respostas: até 2 dias úteis do recebimento do pedido</li>
                </ul>
              </Typography>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography
                variant="subtitle1"
                color="primary"
                gutterBottom
                sx={{ fontSize: '0.875rem' }}
              >
                Antes de enviar questionamentos:
              </Typography>
              <Typography variant="body2" component="div">
                <ul>
                  <li>Leia atentamente todo o edital</li>
                  <li>Anote todas as dúvidas encontradas</li>
                  <li>Verifique se a dúvida já foi respondida</li>
                  <li>Agrupe questionamentos similares</li>
                  <li>Prepare as fundamentações legais</li>
                </ul>
              </Typography>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography
                variant="subtitle1"
                color="primary"
                gutterBottom
                sx={{ fontSize: '0.875rem' }}
              >
                Motivos para Impugnação
              </Typography>
              <Typography variant="body2" component="div">
                <ul>
                  <li>Exigências restritivas ou ilegais</li>
                  <li>Especificações técnicas inadequadas</li>
                  <li>Prazos inexequíveis</li>
                  <li>Critérios de julgamento obscuros</li>
                  <li>Outras irregularidades que limitem a competição</li>
                </ul>
              </Typography>
            </Box>
          </Box>

          {/* Right Column */}
          <Box sx={{ flex: 1, width: '50%', display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                Tire suas dúvidas sobre o edital usando nossa IA:
              </Typography>
              <Button
                variant="contained"
                color="warning"
                startIcon={<ChatIcon />}
                onClick={() => setAIChatOpen(true)}
                sx={{ width: 'fit-content' }}
                disabled={!hasAiAccess}
              >
                {hasAiAccess ? 'Pergunte à LicitAI' : 'Faça upgrade para usar a IA'}
              </Button>
            </Box>

            <Box>
              <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                Tire suas dúvidas sobre o edital nos canais do portal:
              </Typography>
              <Button
                variant="contained"
                color="info"
                startIcon={<HelpIcon />}
                href={portalService.getEsclarecimentosUrl() || '#'}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ width: 'fit-content' }}
              >
                Enviar Esclarecimento
              </Button>
            </Box>

            <Box>
              <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                Envie uma impugnação caso identifique irregularidades:
              </Typography>
              <Button
                variant="contained"
                color="error"
                startIcon={<GavelIcon />}
                href={portalService.getImpugnacaoUrl() || '#'}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ width: 'fit-content' }}
              >
                Enviar Impugnação
              </Button>
            </Box>

            <Box>
              <Typography
                variant="subtitle1"
                color="primary"
                gutterBottom
                sx={{ fontSize: '0.875rem' }}
              >
                Contatos Disponíveis
              </Typography>
              {licitacao.emailContato || licitacao.telefoneContato || licitacao.endereco ? (
                <Stack spacing={1}>
                  {licitacao.emailContato && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <InfoIcon color="info" fontSize="small" />
                      <Link href={`mailto:${licitacao.emailContato}`}>
                        {licitacao.emailContato}
                      </Link>
                    </Stack>
                  )}
                  {licitacao.telefoneContato && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <InfoIcon color="info" fontSize="small" />
                      <Link href={`tel:${licitacao.telefoneContato}`}>
                        {licitacao.telefoneContato}
                      </Link>
                    </Stack>
                  )}
                  {licitacao.endereco && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <InfoIcon color="info" fontSize="small" />
                      <Typography variant="body2">{licitacao.endereco}</Typography>
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Nenhum contato adicional disponível. Utilize os canais do portal.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {hasAiAccess && (
        <AIChatModal open={aiChatOpen} onClose={() => setAIChatOpen(false)} licitacao={licitacao} />
      )}
    </>
  );
};
