import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { ComprasbrDispensaLicitacao } from '../../types';

interface ComprasbrDispensaDetailsProps {
  details: ComprasbrDispensaLicitacao;
}

const ComprasbrDispensaDetails: React.FC<ComprasbrDispensaDetailsProps> = ({ details }) => {
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary">
          Informações adicionais do portal
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Número do Processo:</strong> {details.numProcesso}
            </Typography>
            <Typography>
              <strong>Número do Edital:</strong> {details.numeroEdital}
            </Typography>
            <Typography>
              <strong>Status:</strong> {details.status}
            </Typography>
            <Typography>
              <strong>Local:</strong> {`${details.municipio} - ${details.estado}`}
            </Typography>
            <Typography>
              <strong>Modalidade:</strong> {details.modalidade}
            </Typography>
            <Typography>
              <strong>Comprador:</strong> {details.comprador}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Tipo de Licitação:</strong> {details.tipoLicitacao}
            </Typography>
            <Typography>
              <strong>Tipo de Disputa:</strong> {details.tipoDisputa}
            </Typography>
            <Typography>
              <strong>Modo de Disputa:</strong> {details.modoDisputa}
            </Typography>
            <Typography>
              <strong>Pregoeiro:</strong> {details.pregoeiro}
            </Typography>
            <Typography>
              <strong>Criado Por:</strong> {details.criadoPor}
            </Typography>
            <Typography>
              <strong>Ano:</strong> {details.ano}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography>
              <strong>Objeto:</strong> {details.objeto}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Início Envio Proposta:</strong> {formatDate(details.dataIniEnvioProposta)}
            </Typography>
            <Typography>
              <strong>Fim Envio Proposta:</strong> {formatDate(details.dataFimEnvioProposta)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Abertura da Licitação:</strong> {formatDate(details.dataAbertura)}
            </Typography>
            <Typography>
              <strong>Encerramento:</strong> {formatDate(details.dataHoraEncerramento)}
            </Typography>
            <Typography>
              <strong>Publicação:</strong> {formatDate(details.dataHoraPublicacao)}
            </Typography>
          </Grid>
          {details.dataLimiteEsclarecimentoImpugnacao && (
            <Grid item xs={12}>
              <Typography>
                <strong>Data Limite Esclarecimento/Impugnação:</strong>{' '}
                {formatDate(details.dataLimiteEsclarecimentoImpugnacao)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ComprasbrDispensaDetails;
