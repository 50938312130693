import { AttachMoney, OpenInNew } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { BanrisulLicitacao, BanrisulLote, Licitacao } from '../../types';

interface BanrisulDetailsProps {
  details: BanrisulLicitacao;
  licitacao: Licitacao;
}

const BanrisulDetails: React.FC<BanrisulDetailsProps> = ({ details, licitacao }) => {
  const getLoteLink = (lote: BanrisulLote): string => {
    let edital = details.edital;
    if (!edital) {
      return '#';
    }
    const editalParts = edital.split('PNCP');

    if (editalParts.length > 1) {
      edital = editalParts[0].trim();
    }
    edital = edital.replace('/', '_');
    return 'https://pregaobanrisul.com.br/editais/' + edital + '/lote/' + lote.id;
  };

  // const formatDate = (timestamp: number): string => {
  //   if (!timestamp) return '';
  //   const date = new Date(timestamp);
  //   return format(date, 'dd/MM/yyyy HH:mm');
  // };

  // const mapProcessingType = (processingType: string): string => {
  //   const mapping: { [key: string]: string } = {
  //     WITHOUT_DISTINCTION: 'Sem distinção',
  //     EXCLUSIVE: 'Exclusivo',
  //     PREFERENTIAL: 'Preferencial',
  //   };
  //   return mapping[processingType] || processingType;
  // };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="primary">
            Informações adicionais do portal
          </Typography>
          {details.lotes && details.lotes.length <= 3 ? (
            details.lotes.slice(0, 3).map((lote: BanrisulLote, index: number) => (
              <Button
                key={index}
                href={getLoteLink(lote)}
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                color="primary"
                startIcon={<OpenInNew />}
                sx={{ mr: 1, mb: 1 }}
              >
                {lote.title}
              </Button>
            ))
          ) : (
            <Button
              key={1}
              href={licitacao.linkExterno + '#lotsTab'}
              target="_blank"
              rel="noopener noreferrer"
              variant="contained"
              color="primary"
              startIcon={<AttachMoney />}
              sx={{ mr: 1, mb: 1 }}
            >
              Cadastrar Proposta
            </Button>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Situação:</strong> {details.situacao}
            </Typography>
            <Typography>
              <strong>Central de Compras:</strong> {details.centralDeCompras}
            </Typography>
            <Typography>
              <strong>Processo:</strong> {details.processo}
            </Typography>
            <Typography>
              <strong>Edital:</strong> {details.edital}
            </Typography>
            <Typography>
              <strong>Modalidade:</strong> {details.modalidade}
            </Typography>
            <Typography>
              <strong>Data de Publicação:</strong> {details.dataPublicacao}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Critério de Julgamento:</strong> {details.criterioJulgamento}
            </Typography>
            <Typography>
              <strong>Modo de Disputa:</strong> {details.modoDisputa}
            </Typography>
            <Typography>
              <strong>Habilitação:</strong> {details.habilitacao}
            </Typography>
            <Typography>
              <strong>Recurso Administrativo:</strong> {details.recursoAdministrativo}
            </Typography>
            <Typography>
              <strong>Tipo de Objeto:</strong> {details.tipoObjeto}
            </Typography>
            <Typography>
              <strong>Planilha Orçamento Base:</strong> {details.planilhaOrcamentoBase}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography>
              <strong>Local:</strong> {details.local}
            </Typography>
            <Typography>
              <strong>Comissão de Licitação:</strong> {details.comissaoLicitacao}
            </Typography>
            <Typography>
              <strong>Objeto:</strong> {details.objeto}
            </Typography>
            <Typography>
              <strong>Participação:</strong> {details.participacao}
            </Typography>
            <Typography>
              <strong>Órgão Requisitante:</strong> {details.orgaoRequisitante}
            </Typography>
            <Typography>
              <strong>Transferência de Curso:</strong> {details.transferenciaDeCurso}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BanrisulDetails;
