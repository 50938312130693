import Api from '@services/api';
import { TagType } from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Tag {
  licitacaoId: string;
  type: TagType;
  createdAt: string;
}

interface TagsState {
  tags: Tag[];
  isLoading: boolean;
  error: string | null;
  lastFetch: number | null;
  fetchTags: (force?: boolean) => Promise<void>;
  addTag: (licitacaoId: string, type: TagType) => Promise<void>;
  removeTag: (licitacaoId: string) => Promise<void>;
  getTag: (licitacaoId: string) => TagType | null;
  hasTag: (licitacaoId: string) => boolean;
  resetStore: () => void;
}

export const useTagsStore = create<TagsState>()(
  persist(
    (set, get) => ({
      tags: [],
      isLoading: false,
      error: null,
      lastFetch: null,

      fetchTags: async (force = false) => {
        const now = Date.now();
        const lastFetch = get().lastFetch;
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

        if (!force && lastFetch && now - lastFetch < CACHE_DURATION) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const response = await Api.getTags();
          set({
            tags: response.tags,
            isLoading: false,
            lastFetch: now,
          });
        } catch (error: any) {
          console.error('Error fetching tags:', error);
          const errorMessage = error.message || 'Erro ao buscar tags';
          set({ error: errorMessage, isLoading: false, lastFetch: null });
          throw error;
        }
      },

      addTag: async (licitacaoId: string, type: TagType) => {
        const previousTags = [...get().tags];
        set({ isLoading: true, error: null });
        try {
          const newTag = await Api.addTag(licitacaoId, type);
          set((state) => ({
            tags: [...state.tags.filter((t) => t.licitacaoId !== licitacaoId), newTag],
            isLoading: false,
          }));
        } catch (error: any) {
          console.error('Error adding tag:', error);
          const errorMessage = error.message || 'Erro ao adicionar tag';
          set({
            tags: previousTags,
            error: errorMessage,
            isLoading: false,
          });
          throw error;
        }
      },

      removeTag: async (licitacaoId: string) => {
        const previousTags = [...get().tags];
        set({ isLoading: true, error: null });
        try {
          await Api.removeTag(licitacaoId);
          set((state) => ({
            tags: state.tags.filter((tag) => tag.licitacaoId !== licitacaoId),
            isLoading: false,
          }));
        } catch (error: any) {
          console.error('Error removing tag:', error);
          const errorMessage = error.message || 'Erro ao remover tag';
          set({
            tags: previousTags,
            error: errorMessage,
            isLoading: false,
          });
          throw error;
        }
      },

      getTag: (licitacaoId: string) => {
        const tag = get().tags.find((tag) => tag.licitacaoId === licitacaoId);
        return tag ? tag.type : null;
      },

      hasTag: (licitacaoId: string) => {
        return get().tags.some((tag) => tag.licitacaoId === licitacaoId);
      },

      resetStore: () =>
        set({
          tags: [],
          isLoading: false,
          error: null,
          lastFetch: null,
        }),
    }),
    {
      name: 'tags-storage',
      partialize: () => ({}),
    },
  ),
);
