import {
  Info as InfoIcon,
  Login as LoginIcon,
  PersonAdd as PersonAddIcon,
} from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PortalService } from '@types';

interface LoginStepProps {
  portalService: PortalService;
  portalName?: string;
}

export const LoginStep: React.FC<LoginStepProps> = ({ portalService, portalName }) => {
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
      {/* Header */}
      <Box sx={{ mb: 2, width: '100%' }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <InfoIcon color="info" fontSize="small" />
          <Typography variant="subtitle2" color="primary">
            O primeiro passo para participar de uma licitação é ter um cadastro válido no{' '}
            <strong>{portalName || portalService.name}</strong>.
          </Typography>
        </Stack>
      </Box>

      {/* Content */}
      <Box sx={{ display: 'flex', flex: 1, gap: 2, width: '100%' }}>
        {/* Left Column - Register */}
        <Box
          sx={{
            flex: 1,
            width: '50%',
            '& .MuiTypography-body1, & .MuiTypography-body2': { fontSize: '0.875rem' },
          }}
        >
          <Box>
            <Typography variant="h6" color="primary" gutterBottom sx={{ fontSize: '1rem' }}>
              Cadastro no Portal
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Acesse o portal para se cadastrar:
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<PersonAddIcon />}
              href={portalService.getRegisterUrl() || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Cadastre-se no portal
            </Button>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Requisitos para Cadastro
            </Typography>
            <Typography variant="body2">
              <ul>
                <li>CNPJ ativo e regular</li>
                <li>Certificado Digital válido</li>
                <li>Email corporativo válido</li>
              </ul>
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Documentos Necessários
            </Typography>
            <Typography variant="body2">
              Cada portal possui requisitos específicos para o cadastro. Os mais comuns são:
              <ul>
                <li>Contrato Social ou equivalente</li>
                <li>RG e CPF dos sócios</li>
                <li>Procuração (se aplicável)</li>
              </ul>
            </Typography>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Passos para Cadastro
            </Typography>
            <Typography variant="body2">
              <ol>
                <li>Acesse o portal através do botão "Cadastre-se"</li>
                <li>Localize a opção "Cadastro de Fornecedor"</li>
                <li>Preencha todos os dados da empresa</li>
                <li>Anexe a documentação solicitada</li>
                <li>Aguarde a validação do cadastro (o tempo pode variar dependendo do portal)</li>
              </ol>
            </Typography>
          </Box>
        </Box>

        {/* Right Column - Login */}
        <Box sx={{ flex: 1, width: '50%', display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box>
            <Typography variant="h6" color="primary" gutterBottom sx={{ fontSize: '1rem' }}>
              Acesso ao Portal
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Já possui cadastro? Acesse sua conta:
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<LoginIcon />}
              href={portalService.getLoginUrl() || '#'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Fazer login no portal
            </Button>
          </Box>

          <Box>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Checklist de Acesso
            </Typography>
            <Typography variant="body2">
              <ol>
                <li>Confira se o seu cadastro no portal está completo</li>
                <li>Cheque se a sua conta está ativa</li>
                <li>Verifique se sua conta possui pendências</li>
                <li>Resolva as pendências para continuar</li>
              </ol>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
