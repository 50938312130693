import CurrencyInput from '@components/Input/CurrencyInput';
import { useAnalytics } from '@hooks/useAnalytics';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useFollowingStore } from '@store/followingStore';
import React, { useEffect, useState } from 'react';

// Simple UUID generator function
const generateId = () => {
  const timestamp = Date.now().toString(36);
  const randomStr = Math.random().toString(36).substring(2, 8);
  return `${timestamp}-${randomStr}`;
};

interface AddCustomItemModalProps {
  open: boolean;
  onClose: () => void;
  licitacaoId: string;
}

export const AddCustomItemModal: React.FC<AddCustomItemModalProps> = ({
  open,
  onClose,
  licitacaoId,
}) => {
  const { trackEvent } = useAnalytics();
  const { addCustomItemToFollowing } = useFollowingStore();
  const [formData, setFormData] = useState({
    descricao: '',
    quantidade: '',
    customPrice: '',
    unidadeMedida: '',
  });

  useEffect(() => {
    if (open) {
      trackEvent('custom_item_modal_opened', {
        licitacao_id: licitacaoId,
      });
    }
  }, [open, licitacaoId]);

  const handleSubmit = async () => {
    const customItem = {
      id: `custom-${generateId()}`,
      descricao: formData.descricao,
      quantidade: Number(formData.quantidade),
      customPrice: Number(formData.customPrice),
      unidadeMedida: formData.unidadeMedida,
      isCustom: true as const,
    };

    try {
      trackEvent('custom_item_creation_initiated', {
        licitacao_id: licitacaoId,
        item_description_length: formData.descricao.length,
        has_quantity: Boolean(formData.quantidade),
        has_price: Boolean(formData.customPrice),
      });

      await addCustomItemToFollowing(licitacaoId, customItem);

      trackEvent('custom_item_created', {
        licitacao_id: licitacaoId,
        item_id: customItem.id,
        quantidade: customItem.quantidade,
        has_unidade_medida: Boolean(customItem.unidadeMedida),
      });

      onClose();
      setFormData({
        descricao: '',
        quantidade: '',
        customPrice: '',
        unidadeMedida: '',
      });
    } catch (error) {
      trackEvent('custom_item_creation_error', {
        licitacao_id: licitacaoId,
        error_message: error instanceof Error ? error.message : String(error),
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Adicionar Item Personalizado</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            label="Descrição"
            fullWidth
            value={formData.descricao}
            onChange={(e) => setFormData({ ...formData, descricao: e.target.value })}
          />
          <TextField
            label="Quantidade"
            type="number"
            fullWidth
            value={formData.quantidade}
            onChange={(e) => setFormData({ ...formData, quantidade: e.target.value })}
          />
          <TextField
            label="Unidade de Medida"
            fullWidth
            value={formData.unidadeMedida}
            onChange={(e) => setFormData({ ...formData, unidadeMedida: e.target.value })}
          />
          <CurrencyInput
            label="Valor Unitário"
            fullWidth
            value={formData.customPrice}
            onChange={(value) => setFormData({ ...formData, customPrice: value })}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            !formData.descricao ||
            !formData.quantidade ||
            !formData.customPrice ||
            !formData.unidadeMedida
          }
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
