import Api from '@services/api';
import { EmailPreferences, Preferences } from '@types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface PreferencesState {
  preferences: Preferences | null;
  isLoading: boolean;
  error: string | null;
  lastFetch: number | null;

  // Actions
  fetchPreferences: (force?: boolean) => Promise<void>;
  updateEmailPreferences: (emailPrefs: Partial<EmailPreferences>) => Promise<void>;
  resetStore: () => void;
}

const DEFAULT_PREFERENCES: Preferences = {
  email: {
    expiringDocuments: true,
    newRecommendations: true,
  },
};

export const usePreferencesStore = create<PreferencesState>()(
  persist(
    (set, get) => ({
      preferences: null,
      isLoading: false,
      error: null,
      lastFetch: null,

      fetchPreferences: async (force = false) => {
        const now = Date.now();
        const lastFetch = get().lastFetch;
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

        if (!force && lastFetch && now - lastFetch < CACHE_DURATION) {
          return;
        }

        set({ isLoading: true, error: null });
        try {
          const preferences = await Api.getPreferences();
          set({
            preferences: preferences || DEFAULT_PREFERENCES,
            isLoading: false,
            lastFetch: now,
          });
        } catch (error) {
          console.error('Error fetching preferences:', error);
          set({
            error: 'Erro ao carregar preferências',
            isLoading: false,
            lastFetch: null,
          });
        }
      },

      updateEmailPreferences: async (emailPrefs: Partial<EmailPreferences>) => {
        const currentPreferences = get().preferences;
        if (!currentPreferences) return;

        const previousPreferences = { ...currentPreferences };

        // Optimistic update
        set({
          preferences: {
            ...currentPreferences,
            email: {
              ...currentPreferences.email,
              ...emailPrefs,
            },
          },
          isLoading: true,
          error: null,
        });

        try {
          await Api.updatePreferences({
            email: {
              ...currentPreferences.email,
              ...emailPrefs,
            },
          });
          set({ isLoading: false });
        } catch (error) {
          console.error('Error updating email preferences:', error);

          // Rollback on error
          set({
            preferences: previousPreferences,
            error: 'Erro ao atualizar preferências de email',
            isLoading: false,
          });
          throw error;
        }
      },

      resetStore: () => {
        set({
          preferences: null,
          isLoading: false,
          error: null,
          lastFetch: null,
        });
      },
    }),
    {
      name: 'preferences-storage',
      partialize: (state) => ({
        preferences: state.preferences,
      }),
    },
  ),
);
