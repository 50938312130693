import { Search } from '@mui/icons-material';
import { Box, Link, SvgIconProps, Tooltip, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface InfoItemProps {
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  value: string | number;
  link?: string;
  copyable?: boolean;
  highlight?: boolean;
}

const InfoItem: React.FC<InfoItemProps> = ({
  icon: Icon,
  label,
  value,
  link,
  copyable,
  highlight,
}) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value.toString());
  };

  const content = (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Icon sx={{ mr: 1, color: 'primary.main' }} />
      <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
        {label}:
      </Typography>
      {link ? (
        <Tooltip title={`Buscar licitações do mesmo ${label.toLowerCase()}`}>
          <Link
            component={RouterLink}
            to={link}
            color="primary"
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography variant="body2">{value}</Typography>
            <Search sx={{ ml: 0.5, fontSize: '0.875rem' }} />
          </Link>
        </Tooltip>
      ) : copyable ? (
        <Tooltip title="Clique para copiar">
          <Typography
            variant="body2"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleCopy}
          >
            {value}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant="body2">{value}</Typography>
      )}
    </Box>
  );

  if (highlight) {
    return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? alpha(theme.palette.warning.main, 0.2)
              : alpha(theme.palette.warning.main, 0.1),
          borderRadius: 1,
          p: 1,
          transition: 'background-color 0.3s ease',
        }}
      >
        {content}
      </Box>
    );
  }

  return content;
};

export default InfoItem;
