import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { FeatureId } from '@types';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Company: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { checkFeatureAccess } = useProfileStore();
  const hasMultiCompanyAccess = checkFeatureAccess(FeatureId.MULTI_CNPJ).hasAccess;

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(`/empresa/${newValue}`);
  };

  const value = location.pathname.split('/').pop() || 'perfil';

  return (
    <>
      <Helmet>
        <title>Empresa - Licitou</title>
        <meta name="description" content="Gerencie as informações e preferências da sua empresa" />
      </Helmet>
      <Box sx={{ my: 4 }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider' }}
        >
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            sx={{ width: '20%', flexShrink: 0 }}
          >
            Empresa
          </Typography>
          <Tabs value={value} onChange={handleChange} aria-label="company tabs" sx={{ flex: 1 }}>
            <Tab label="Perfil da Empresa" value="perfil" />
            <Tab label="Preferências" value="preferencias" />
            <Tab
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  Painel Multi-Empresa
                  <Typography
                    component="span"
                    sx={{
                      fontSize: '0.6875rem',
                      backgroundColor: 'warning.main',
                      color: 'warning.contrastText',
                      px: 0.5,
                      py: 0.25,
                      borderRadius: 1,
                      ml: 0.5,
                    }}
                  >
                    BREVE
                  </Typography>
                </Box>
              }
              value="multi"
              disabled={!hasMultiCompanyAccess}
            />
          </Tabs>
        </Box>
        <Box sx={{ p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Company;
