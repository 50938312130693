import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Documents: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(`/documentos/${newValue}`);
  };

  const value = location.pathname.split('/').pop() || 'documentos';

  return (
    <>
      <Helmet>
        <title>
          {value === 'documentos'
            ? 'Documentos da Empresa - Licitou'
            : 'Modelos de Declarações - Licitou'}
        </title>
        <meta
          name="description"
          content="Gerencie documentos e declarações necessários para participar de licitações"
        />
      </Helmet>
      <Box sx={{ my: 4 }}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', borderBottom: 1, borderColor: 'divider' }}
        >
          <Typography
            variant="h4"
            component="h1"
            color="primary"
            sx={{ width: '20%', flexShrink: 0 }}
          >
            Documentos
          </Typography>
          <Tabs value={value} onChange={handleChange} aria-label="settings tabs" sx={{ flex: 1 }}>
            <Tab label="Documentos" value="documentos" />
            <Tab label="Modelos de declarações" value="declaracoes" />
            <Tab label="Gerador de Propostas" value="proposta" />
          </Tabs>
        </Box>
        <Box sx={{ p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Documents;
