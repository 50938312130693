import { alpha, Box, Chip, SxProps, TextField, Theme, useTheme } from '@mui/material';
import React, { useState } from 'react';

interface ChipInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string[];
  onChangeValue: (newValue: string[]) => void;
  placeholder?: string;
  className?: string;
  sx?: SxProps<Theme>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onInputChange?: (value: string) => void;
  onChipRemove?: (removedChip: string, index: number) => void;
  onEmptyEnter?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  error?: boolean;
}

const ChipInput: React.FC<ChipInputProps> = ({
  value,
  onChangeValue,
  placeholder,
  className,
  sx,
  onFocus,
  onInputChange,
  onChipRemove,
  onEmptyEnter,
  onKeyDown,
  error = false,
}) => {
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (onInputChange) {
      onInputChange(newValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (inputValue.trim()) {
        if (!value.includes(inputValue.trim())) {
          onChangeValue([...value, inputValue.trim()]);
        }
        setInputValue('');
      } else if (onEmptyEnter) {
        onEmptyEnter();
      }
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const handleRemoveChip = (chipToRemove: string) => {
    const chipIndex = value.indexOf(chipToRemove);
    const newValue = value.filter((chip) => chip !== chipToRemove);
    onChangeValue(newValue);
    if (onChipRemove) {
      onChipRemove(chipToRemove, chipIndex);
    }
  };

  return (
    <Box sx={sx}>
      <TextField
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        variant="outlined"
        className={className}
        onFocus={onFocus}
        error={error}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.background.paper,
            '& fieldset': {
              borderColor: error ? theme.palette.error.main : theme.palette.divider,
            },
            '&:hover fieldset': {
              borderColor: error ? theme.palette.error.light : theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
              borderColor: error ? theme.palette.error.dark : theme.palette.primary.main,
            },
          },
          '& .MuiInputBase-input': {
            color: theme.palette.text.primary,
          },
          '& .MuiInputLabel-root': {
            color: error ? theme.palette.error.main : theme.palette.text.secondary,
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: error ? theme.palette.error.main : theme.palette.primary.main,
          },
        }}
        InputProps={{
          style: { color: theme.palette.text.primary },
        }}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
        {value.map((chip) => (
          <Chip
            key={chip}
            label={chip}
            onDelete={() => handleRemoveChip(chip)}
            sx={{
              backgroundColor: error
                ? alpha(theme.palette.error.main, theme.palette.mode === 'light' ? 0.1 : 0.2)
                : theme.palette.mode === 'light'
                  ? alpha(theme.palette.primary.main, 0.1)
                  : theme.palette.primary.main,
              color: error
                ? theme.palette.error.main
                : theme.palette.mode === 'light'
                  ? theme.palette.primary.main
                  : theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: error
                  ? alpha(theme.palette.error.main, theme.palette.mode === 'light' ? 0.2 : 0.3)
                  : theme.palette.mode === 'light'
                    ? alpha(theme.palette.primary.main, 0.2)
                    : theme.palette.primary.dark,
              },
              '& .MuiChip-deleteIcon': {
                color: error
                  ? theme.palette.error.main
                  : theme.palette.mode === 'light'
                    ? theme.palette.primary.main
                    : theme.palette.primary.contrastText,
                '&:hover': {
                  color: error
                    ? theme.palette.error.dark
                    : theme.palette.mode === 'light'
                      ? theme.palette.primary.dark
                      : theme.palette.primary.light,
                },
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ChipInput;
