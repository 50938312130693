import { features, plans } from '@constants/plans';
import CNAES from '@data/CNAE.json';
import Api from '@services/api';
import { useAnnotationsStore } from '@store/annotationsStore';
import { useBulletinStore } from '@store/bulletinStore';
import { useDocumentsStore } from '@store/documentsStore';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { useKanbanStore } from '@store/kanbanStore';
import { useLicitacoesStore } from '@store/licitacoesStore';
import { useNotificationStore } from '@store/notificationStore';
import { usePreferencesStore } from '@store/preferencesStore';
import { useRecommendedStore } from '@store/recommendedStore';
import { useSavedSearchesStore } from '@store/savedSearchesStore';
import { useTagsStore } from '@store/tagsStore';
import {
  CNAE,
  Company,
  FeatureAccessResult,
  FeatureId,
  FirestoreUser,
  PlanId,
  ProfilesInfo,
  UserPreferences,
  UserProfile,
} from '@types';
import isEqual from '@utils/isEqual';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const cnaes: CNAE[] = CNAES;

interface ProfileState {
  userData: FirestoreUser | null;
  company: Company | null;
  error: string | null;
  userPreferences: UserPreferences | null;
  fetchCompany: (
    cnpj: string,
  ) => Promise<Company | { error: string; status?: number; details?: string }>;
  setCompany: (company: Company) => Promise<void>;
  setUserPreferences: (preferences: UserPreferences) => Promise<void>;
  saveUserCompany: (companyInfo: Company) => Promise<void>;
  saveUserPreferences: (preferences: UserPreferences) => Promise<void>;
  saveUser: (user: FirestoreUser) => Promise<void>;
  loadUserData: (profileId?: string) => Promise<FirestoreUser | null>;
  isLoading: boolean;
  resetStore: () => void;
  setOnboardingPreferences: (preferences: Partial<UserPreferences> | null) => void;
  setOnboardingComplete: () => Promise<void>;
  checkUserPlanHistory: () => Promise<void>;
  profiles: UserProfile[];
  currentProfileId: string | null;
  setCurrentProfile: (profileId: string, initialLoad?: boolean) => void;
  addProfile: (profile: UserProfile) => void;
  updateProfile: (profileId: string, updates: Partial<UserProfile>) => void;
  deleteProfile: (profileId: string) => void;
  setCurrentProfileId: (profileId: string | null) => void;
  getCurrentProfileId: () => string | null;
  checkFeatureAccess: (featureId: FeatureId, count?: number) => FeatureAccessResult;
  profilesInfo: ProfilesInfo | null;
  uploadCompanyLogo: (file: File) => Promise<string>;
  removeCompanyLogo: () => Promise<void>;
  uploadUserProfileImage: (file: File) => Promise<string>;
  removeUserProfileImage: () => Promise<void>;
}

const storage = {
  getItem: (name: string) => {
    const str = localStorage.getItem(name);
    if (str) return JSON.parse(str);
    return null;
  },
  setItem: (name: string, value: any) => {
    localStorage.setItem(name, JSON.stringify(value));
  },
  removeItem: (name: string) => localStorage.removeItem(name),
  setCurrentProfileId: (profileId: string | null) => {
    localStorage.setItem('currentProfileId', profileId || '');
  },
  getCurrentProfileId: (): string | null => {
    return localStorage.getItem('currentProfileId') || null;
  },
};

export const useProfileStore = create<ProfileState>()(
  persist(
    (set, get) => ({
      company: null,
      userData: null,
      userPreferences: null,
      isLoading: false,
      cnaes: CNAES,
      profiles: [],
      error: null,
      currentProfileId: storage.getCurrentProfileId(),
      profilesInfo: null,
      getCurrentProfileId: () => storage.getCurrentProfileId(),
      setCurrentProfileId: (profileId: string | null) => {
        storage.setCurrentProfileId(profileId);
        set({ currentProfileId: profileId });
      },
      setCompany: async (newCompany: Company) => {
        const currentCompany = get().company;
        if (!isEqual(currentCompany, newCompany)) {
          set({ company: newCompany });
          await get().saveUserCompany(newCompany);
        }
      },
      setUserPreferences: async (newPreferences: UserPreferences) => {
        const currentPreferences = get().userPreferences;
        if (!isEqual(currentPreferences, newPreferences)) {
          set({ userPreferences: newPreferences });
          await get().saveUserPreferences(newPreferences);
        }
      },
      fetchCompany: async (cnpj: string) => {
        try {
          const response = await Api.getCompany(cnpj);
          if ('error' in response) {
            return response;
          }
          return response;
        } catch (error) {
          console.error('Error fetching company:', error);
          if (error instanceof Error) {
            return {
              error: error.message || 'Ocorreu um erro ao buscar a empresa.',
              status: 500,
            };
          } else {
            return {
              error: 'Ocorreu um erro ao buscar a empresa.',
              status: 500,
            };
          }
        }
      },
      saveUserCompany: async (companyInfo: Company) => {
        const previousCompany = get().company;
        set({ isLoading: true, error: null });
        try {
          const isOnboarding = !get().company;
          await Api.saveUserCompany(companyInfo, isOnboarding);
          set({ company: companyInfo, isLoading: false });
        } catch (error: any) {
          console.error('Error saving company:', error);
          const errorMessage = error.message || 'Erro ao salvar empresa';
          set({ company: previousCompany, error: errorMessage, isLoading: false });
          throw error;
        }
      },
      saveUserPreferences: async (preferences: UserPreferences) => {
        const previousPreferences = get().userPreferences;
        set({ isLoading: true, error: null });
        try {
          const isOnboarding = !get().userPreferences;
          await Api.saveUserPreferences(preferences, isOnboarding);
          set({ userPreferences: preferences, isLoading: false });
        } catch (error: any) {
          console.error('Error saving preferences:', error);
          const errorMessage = error.message || 'Erro ao salvar preferências';
          set({ userPreferences: previousPreferences, error: errorMessage, isLoading: false });
          throw error;
        }
      },
      saveUser: async (user: FirestoreUser) => {
        await Api.saveUserData(user);
        set((state) => ({
          userData: { ...state.userData, ...user },
        }));
      },
      resetStore: () =>
        set({
          company: null,
          userPreferences: null,
          isLoading: false,
          userData: null,
          profiles: [],
          currentProfileId: null,
        }),
      loadUserData: async (profileId?: string) => {
        set({ isLoading: true, error: null });
        try {
          const storedProfileId = storage.getCurrentProfileId();
          const userData = await Api.getUserData(profileId || storedProfileId || '');
          if (userData) {
            set({
              userData,
              profiles: userData.profiles || [],
              profilesInfo: userData.profilesInfo || null,
              currentProfileId:
                profileId ||
                storedProfileId ||
                (userData.profiles && userData.profiles[0]?.id) ||
                null,
            });

            const currentProfileId =
              profileId || storedProfileId || (userData.profiles && userData.profiles[0]?.id);
            if (currentProfileId) {
              const currentProfile = userData.profiles?.find((p) => p.id === currentProfileId);
              if (currentProfile) {
                set({
                  company: currentProfile.companyInfo || null,
                  userPreferences: currentProfile.userPreferences || null,
                });
                storage.setCurrentProfileId(currentProfileId);
              }
            }

            if (userData.hasHadActivePlan === undefined) {
              get().checkUserPlanHistory();
            }
            set({ isLoading: false });
            return userData;
          }
          return null;
        } catch (error: any) {
          console.error('Error loading user data:', error);
          const errorMessage = error.message || 'Erro ao carregar dados do usuário';
          set({ error: errorMessage, isLoading: false });
          throw error;
        }
      },
      setOnboardingPreferences: (preferences: Partial<UserPreferences> | null) =>
        set({ userPreferences: preferences as UserPreferences }),
      setOnboardingComplete: async () => {
        try {
          await Api.setOnboardingComplete();
          set((state) => ({
            userData: state.userData ? { ...state.userData, onboardingComplete: true } : null,
          }));
        } catch (error) {
          console.error('Failed to set onboarding complete:', error);
        }
      },
      checkUserPlanHistory: async () => {
        const userData = get().userData;
        if (userData && userData.hasHadActivePlan === undefined) {
          try {
            const hasHadActivePlan = await Api.checkUserPlanHistory();
            set((state) => ({
              userData: state.userData ? { ...state.userData, hasHadActivePlan } : null,
            }));
          } catch (error) {
            console.error('Error checking user plan history:', error);
          }
        }
      },
      setCurrentProfile: async (profileId: string, initialLoad = false) => {
        const currentProfileId = get().currentProfileId;
        if (currentProfileId !== profileId || initialLoad) {
          const previousState = {
            currentProfileId: get().currentProfileId,
            company: get().company,
            userPreferences: get().userPreferences,
          };
          set({ isLoading: true, error: null });
          try {
            await Api.setCurrentProfileId(profileId);
            set({ currentProfileId: profileId });
            storage.setCurrentProfileId(profileId);

            if (profileId !== currentProfileId) {
              useFavoritesStore.getState().resetStore();
              useFollowingStore.getState().resetStore();
              useKanbanStore.getState().resetStore();
              useBulletinStore.getState().resetStore();
              useSavedSearchesStore.getState().resetStore();
              useRecommendedStore.getState().resetStore();
              useDocumentsStore.getState().resetStore();
              useNotificationStore.getState().resetStore();
              useLicitacoesStore.getState().resetStore();
              useTagsStore.getState().resetStore();
              usePreferencesStore.getState().resetStore();
              useAnnotationsStore.getState().resetStore();
            }
            set({ company: null, userPreferences: null });

            const { profiles } = get();
            const currentProfile = profiles.find((p) => p.id === profileId);

            if (currentProfile) {
              set({
                company: currentProfile.companyInfo || null,
                userPreferences: currentProfile.userPreferences || null,
              });
            }

            if (get().userData?.isActive) {
              await Promise.all([
                useFavoritesStore.getState().fetchFavorites(true),
                useFollowingStore.getState().fetchFollowing(true),
                useRecommendedStore.getState().fetchRecommended(true),
                useSavedSearchesStore.getState().fetchSavedSearches(true),
                useNotificationStore.getState().fetchNotifications(),
                useDocumentsStore.getState().fetchDocuments(undefined, true),
                useTagsStore.getState().fetchTags(true),
                useAnnotationsStore.getState().fetchAnnotations(true),
                usePreferencesStore.getState().fetchPreferences(true),
              ]);
            }
            set({ isLoading: false });
          } catch (error: any) {
            console.error('Error setting current profile:', error);
            const errorMessage = error.message || 'Erro ao definir perfil atual';
            set({ ...previousState, error: errorMessage, isLoading: false });
            throw error;
          }
        }
      },
      addProfile: async (profile: Partial<UserProfile>) => {
        const { profilesInfo } = get();
        if (profilesInfo && !profilesInfo.canCreateMore) {
          throw new Error(
            'Limite de perfis atingido. Faça upgrade do seu plano para criar mais perfis.',
          );
        }

        set({ isLoading: true });
        try {
          const newProfile = await Api.createProfile(profile);
          set((state) => ({
            profiles: [...state.profiles, newProfile],
            currentProfileId: newProfile.id,
          }));
          get().setCurrentProfile(newProfile.id);
        } catch (error) {
          console.error('Error adding new profile:', error);
          throw error;
        } finally {
          set({ isLoading: false });
        }
      },
      updateProfile: async (profileId: string, updates: Partial<UserProfile>) => {
        set({ isLoading: true });
        try {
          const success = await Api.updateProfile(profileId, updates);
          if (success) {
            set((state) => ({
              profiles: state.profiles.map((p) => (p.id === profileId ? { ...p, ...updates } : p)),
            }));
          }
        } catch (error) {
          console.error('Error updating profile:', error);
        } finally {
          set({ isLoading: false });
        }
      },
      deleteProfile: async (profileId: string) => {
        set({ isLoading: true });
        try {
          const success = await Api.deleteProfile(profileId);
          if (success) {
            set((state) => ({
              profiles: state.profiles.filter((p) => p.id !== profileId),
              currentProfileId:
                state.currentProfileId === profileId ? null : state.currentProfileId,
            }));
          }
        } catch (error) {
          console.error('Error deleting profile:', error);
        } finally {
          set({ isLoading: false });
        }
      },
      checkFeatureAccess: (featureId: FeatureId, count?: number): FeatureAccessResult => {
        const state = get();
        const userData = state.userData;
        if (userData?.role === 'admin') {
          return { hasAccess: true, reason: 'Você é um administrador' };
        }

        const activePlans = ['active', 'trialing'];
        const currentPlan = activePlans.includes(userData?.subscriptionStatus || '')
          ? userData?.planId
            ? userData.planId
            : PlanId.BUSCA
          : null;

        if (!currentPlan) {
          return {
            hasAccess: false,
            reason: 'Você precisa ter um plano ativo para acessar este recurso',
          };
        }

        const feature = features[featureId];
        if (!feature) {
          return {
            hasAccess: false,
            reason: 'Recurso não encontrado',
          };
        }

        if (!feature.availableIn.includes(currentPlan as PlanId)) {
          const planNames = feature.availableIn
            .map((planId) => plans.find((p) => p.id === planId)?.name)
            .join(', ');
          return {
            hasAccess: false,
            reason: `Disponível apenas nos planos: ${planNames}`,
          };
        }

        if (feature.maxUsage && count !== undefined) {
          const limit = feature.maxUsage[currentPlan as PlanId];
          const usage = count;

          if (limit !== undefined && usage >= limit) {
            return {
              hasAccess: false,
              reason: `Limite de ${limit} ${feature.name} atingido no seu plano`,
              limit,
              usage,
            };
          }

          return {
            hasAccess: true,
            limit,
            usage,
          };
        }

        return { hasAccess: true };
      },
      uploadCompanyLogo: async (file: File) => {
        try {
          const formData = new FormData();
          formData.append('logo', file);

          const response = await Api.uploadCompanyLogo(formData);
          return response.url;
        } catch (error) {
          console.error('Error uploading company logo:', error);
          throw new Error('Falha ao enviar o logo da empresa');
        }
      },
      removeCompanyLogo: async () => {
        try {
          await Api.removeCompanyLogo();
          await get().setCompany({
            ...get().company!,
            logoUrl: undefined,
          });
        } catch (error) {
          console.error('Error removing company logo:', error);
          throw new Error('Falha ao remover o logo da empresa');
        }
      },
      uploadUserProfileImage: async (file: File) => {
        try {
          const formData = new FormData();
          formData.append('file', file);
          const { url } = await Api.uploadUserProfileImage(formData);
          await get().saveUser({
            ...get().userData!,
            photoURL: url,
          });
          return url;
        } catch (error) {
          console.error('Error uploading user profile image:', error);
          throw new Error('Falha ao fazer upload da imagem do perfil');
        }
      },
      removeUserProfileImage: async () => {
        try {
          await Api.removeUserProfileImage();
          await get().saveUser({
            ...get().userData!,
            photoURL: null,
          });
        } catch (error) {
          console.error('Error removing user profile image:', error);
          throw new Error('Falha ao remover a imagem do perfil');
        }
      },
    }),
    {
      name: 'profile-storage',
      storage: createJSONStorage(() => storage),
      partialize: (state) => ({
        ...state,
        profiles: state.profiles,
        currentProfileId: state.currentProfileId,
      }),
    },
  ),
);
