import DocumentSections from '@components/Documents/DocumentSections';
import { useNotification } from '@contexts/NotificationContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { Box, CircularProgress } from '@mui/material';
import { useDocumentsStore } from '@store/documentsStore';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

const Documents: React.FC = () => {
  const {
    isLoading,
    error,
    documents,
    fetchDocuments,
    downloadDocument,
    downloadAllDocuments,
    deleteDocument,
  } = useDocumentsStore();
  const { trackEvent } = useAnalytics();
  const { showNotification } = useNotification();

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  useEffect(() => {
    if (error) {
      showNotification(error, 'error');
    }
  }, [error, showNotification]);

  useEffect(() => {
    trackEvent('documents_page_view', {
      total_documents: documents.length,
    });
  }, []);

  const handleDownload = useCallback(
    async (documentId: string, fileName: string, childId?: string) => {
      try {
        const blob = await downloadDocument(documentId, childId);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        showNotification('Documento baixado com sucesso', 'success');
      } catch (error) {
        console.error('Error downloading document:', error);
        showNotification('Erro ao baixar documento', 'error');
      }
    },
    [downloadDocument, showNotification],
  );

  const handleDownloadAll = useCallback(
    async (section?: string) => {
      try {
        const blob = await downloadAllDocuments(section);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = section ? `${section}_documents.zip` : 'all_documents.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        showNotification('Documentos baixados com sucesso', 'success');
      } catch (error) {
        console.error('Error downloading all documents:', error);
        showNotification('Erro ao baixar documentos', 'error');
      }
    },
    [downloadAllDocuments, showNotification],
  );

  const handleDownloadSelected = useCallback(
    async (selectedDocuments: string[]) => {
      try {
        const blob = await downloadAllDocuments(undefined, selectedDocuments);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'selected_documents.zip';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        showNotification('Documentos selecionados baixados com sucesso', 'success');
      } catch (error) {
        console.error('Error downloading selected documents:', error);
        showNotification('Erro ao baixar documentos selecionados', 'error');
      }
    },
    [downloadAllDocuments, showNotification],
  );

  const handleDelete = useCallback(
    async (documentId: string) => {
      try {
        await deleteDocument(documentId);
        showNotification('Documento excluído com sucesso', 'success');
      } catch (error) {
        console.error('Error deleting document:', error);
        showNotification('Erro ao excluir documento', 'error');
      }
    },
    [deleteDocument, showNotification],
  );

  const documentSectionsProps = useMemo(
    () => ({
      showNotification,
      documents,
      isLoading,
      handleDownloadSelected,
      handleDownload,
      handleDownloadAll,
      handleDelete,
    }),
    [
      documents,
      isLoading,
      handleDownloadSelected,
      handleDownload,
      handleDownloadAll,
      handleDelete,
      showNotification,
    ],
  );

  return (
    <>
      <Helmet>
        <title>Documentos da Empresa - Licitou</title>
        <meta
          name="description"
          content="Gerencie todos os documentos necessários para participar de licitações: certidões, declarações e documentos da empresa"
        />
      </Helmet>

      {isLoading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      )}

      <DocumentSections {...documentSectionsProps} />
    </>
  );
};

export default React.memo(Documents);
