import { useAnalytics } from '@hooks/useAnalytics';
import {
  Close as CloseIcon,
  Description as DescriptionIcon,
  Launch as LaunchIcon,
  Star as StarIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import { PortalServiceFactory } from '@services/portals/PortalServiceFactory';
import { useFavoritesStore } from '@store/favoritesStore';
import { useFollowingStore } from '@store/followingStore';
import { Licitacao, LicitacaoStep } from '@types';
import React, { useEffect, useState } from 'react';
import { ApplyStep, DocumentsStep, DoneStep, DoubtsStep, ItemsStep, LoginStep } from './Steps';

interface StepsModalProps {
  open: boolean;
  onClose: () => void;
  licitacao: Licitacao;
  initialStep?: number;
}

const steps: { label: string; key: LicitacaoStep }[] = [
  { label: 'Cadastro', key: 'login' },
  { label: 'Dúvidas', key: 'doubts' },
  { label: 'Itens', key: 'items' },
  { label: 'Documentos', key: 'documents' },
  { label: 'Proposta', key: 'apply' },
  { label: 'Concluído', key: 'done' },
];

const StepsModal: React.FC<StepsModalProps> = ({ open, onClose, licitacao, initialStep = 0 }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(initialStep);
  const portalService = PortalServiceFactory.create(licitacao);
  const { isFollowing, addFollowing } = useFollowingStore();
  const { isFavorite, addFavorite } = useFavoritesStore();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    setActiveStep(initialStep);
  }, [initialStep]);

  useEffect(() => {
    if (open) {
      trackEvent('steps_modal_opened', {
        licitacao_id: licitacao.id,
        portal: licitacao.portal,
        initial_step: initialStep,
        is_following: isFollowing(licitacao.id),
        is_favorite: isFavorite(licitacao.id),
      });
    }
  }, [open]);

  const handleFavoriteAndFollow = async () => {
    try {
      if (!isFavorite(licitacao.id)) {
        await addFavorite(licitacao.id);
        trackEvent('licitacao_favorited', {
          licitacao_id: licitacao.id,
          portal: licitacao.portal,
          from_steps: true,
        });
      }
      await addFollowing(licitacao.id);
      trackEvent('licitacao_followed', {
        licitacao_id: licitacao.id,
        portal: licitacao.portal,
        from_steps: true,
      });
    } catch (error) {
      trackEvent('favorite_follow_error', {
        licitacao_id: licitacao.id,
        error_message: error instanceof Error ? error.message : String(error),
      });
    }
  };

  const renderFollowAlert = () => (
    <Alert
      severity="warning"
      sx={{ mb: 3 }}
      action={
        <Button
          color="warning"
          size="small"
          variant="outlined"
          onClick={handleFavoriteAndFollow}
          startIcon={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <StarIcon fontSize="small" />
              <VisibilityIcon fontSize="small" />
            </Box>
          }
        >
          {!isFavorite(licitacao.id) ? 'Favoritar e Acompanhar' : 'Acompanhar'}
        </Button>
      }
    >
      <AlertTitle>Atenção</AlertTitle>
      Para prosseguir com os passos da licitação, é necessário favoritar e acompanhar este processo.
      Isso permitirá que você receba atualizações e gerencie os itens de interesse.
    </Alert>
  );

  const handleNext = () => {
    if (!isFollowing(licitacao.id)) {
      return;
    }
    trackEvent('steps_next', {
      licitacao_id: licitacao.id,
      current_step: steps[activeStep].key,
      next_step: steps[activeStep + 1]?.key,
    });
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    trackEvent('steps_back', {
      licitacao_id: licitacao.id,
      current_step: steps[activeStep].key,
      previous_step: steps[activeStep - 1]?.key,
    });
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStepClick = (index: number) => {
    trackEvent('step_direct_navigation', {
      licitacao_id: licitacao.id,
      from_step: steps[activeStep].key,
      to_step: steps[index].key,
      step_index: index,
    });
    setActiveStep(index);
  };

  const handleEditalClick = () => {
    trackEvent('edital_link_clicked', {
      licitacao_id: licitacao.id,
      portal: licitacao.portal,
      from_step: steps[activeStep].key,
    });
  };

  const handlePortalClick = () => {
    trackEvent('portal_link_clicked', {
      licitacao_id: licitacao.id,
      portal: licitacao.portal,
      from_step: steps[activeStep].key,
    });
  };

  const renderStepContent = (step: number) => {
    const currentStep = steps[step];
    if (!currentStep) return null;
    const portalName = licitacao.portal || 'do portal';

    switch (currentStep.key) {
      case 'login':
        return <LoginStep portalService={portalService} portalName={portalName} />;
      case 'doubts':
        return (
          <DoubtsStep licitacao={licitacao} portalService={portalService} portalName={portalName} />
        );
      case 'apply':
        return (
          <ApplyStep licitacao={licitacao} portalService={portalService} portalName={portalName} />
        );
      case 'items':
        return <ItemsStep licitacao={licitacao} portalService={portalService} />;
      case 'documents':
        return <DocumentsStep licitacao={licitacao} portalService={portalService} />;
      case 'done':
        return (
          <DoneStep licitacao={licitacao} portalService={portalService} portalName={portalName} />
        );
      default:
        return (
          <Box
            sx={{
              mt: 2,
              '& .MuiTypography-body1, & .MuiTypography-body2': { fontSize: '0.875rem' },
            }}
          >
            Não há orientação disponível para este passo
          </Box>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        trackEvent('steps_modal_closed', {
          licitacao_id: licitacao.id,
          final_step: steps[activeStep].key,
          total_steps_viewed: activeStep + 1,
        });
        onClose();
      }}
      maxWidth={false}
      PaperProps={{
        sx: {
          bgcolor: theme.palette.background.default,
          width: '80vw',
          height: '80vh',
          maxHeight: '80vh',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="div">
              Participar da Licitação
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                startIcon={<DescriptionIcon />}
                href={portalService.getEditalUrl() || '#'}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleEditalClick}
              >
                Consultar Edital
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<LaunchIcon />}
                href={portalService.getPortalUrl() || '#'}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handlePortalClick}
              >
                Acompanhar no portal
              </Button>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Stepper nonLinear activeStep={activeStep} sx={{ mb: 2 }}>
            {steps.map(({ label }, index) => (
              <Step key={label}>
                <StepButton onClick={() => handleStepClick(index)}>
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          overflow: 'auto',
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!isFollowing(licitacao.id) ? renderFollowAlert() : renderStepContent(activeStep)}
      </DialogContent>
      {isFollowing(licitacao.id) && (
        <Box sx={{ p: 2, bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack}>
              Voltar
            </Button>
            <Button
              variant="contained"
              onClick={activeStep === steps.length - 1 ? onClose : handleNext}
            >
              {activeStep === steps.length - 1 ? 'Concluir' : 'Próximo'}
            </Button>
          </Box>
        </Box>
      )}
    </Dialog>
  );
};

export default StepsModal;
