import { Box, Card, CardContent, Skeleton } from '@mui/material';

const RecommendedCardSkeleton = () => {
  return (
    <Card
      sx={{
        height: '100%',
        minHeight: '280px',
        boxShadow: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2 }}>
        <Box mb={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton width="60%" height={24} />
          </Box>
          <Box mt={1}>
            <Skeleton width="40%" height={20} />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2,
            mb: 'auto',
          }}
        >
          {[...Array(4)].map((_, index) => (
            <Box key={index} display="flex" alignItems="center" gap={1}>
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton width="70%" height={20} />
            </Box>
          ))}
        </Box>

        <Box mt={2}>
          <Skeleton width="100%" height={60} />
          <Box mt={1}>
            <Skeleton width="90%" height={16} />
            <Skeleton width="80%" height={16} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RecommendedCardSkeleton;
