import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { LicitarDigitalLicitacao } from '../../types';

interface LicitarDigitalDetailsProps {
  details: LicitarDigitalLicitacao;
}

const LicitarDigitalDetails: React.FC<LicitarDigitalDetailsProps> = ({ details }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom color="primary">
          Informações adicionais do portal
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Organização:</strong> {details.organizationUnit.organization.organizationName}
            </Typography>
            <Typography>
              <strong>Unidade:</strong> {details.organizationUnit.organizationUnitName}
            </Typography>
            <Typography>
              <strong>Número do Processo:</strong> {details.processNumber}
            </Typography>
            <Typography>
              <strong>Número da Compra:</strong> {details.purcharseNumber}
            </Typography>
            <Typography>
              <strong>Tipo de Processo:</strong> {details.processType}
            </Typography>
            <Typography>
              <strong>Data de Publicação:</strong>{' '}
              {new Date(details.publishedDate).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>
              <strong>Início da Disputa:</strong>{' '}
              {details.startDateTimeDispute
                ? new Date(details.startDateTimeDispute).toLocaleString()
                : 'Não definido'}
            </Typography>
            <Typography>
              <strong>Início do Envio de Propostas:</strong>{' '}
              {new Date(details.startDateTimeToSendProposal).toLocaleString()}
            </Typography>
            <Typography>
              <strong>Fim do Envio de Propostas:</strong>{' '}
              {details.endDateTimeToSendProposal
                ? new Date(details.endDateTimeToSendProposal).toLocaleString()
                : 'Não definido'}
            </Typography>
            <Typography>
              <strong>Prazo para Impugnação:</strong>{' '}
              {details.deadlineImpugnment
                ? new Date(details.deadlineImpugnment).toLocaleString()
                : 'Não definido'}
            </Typography>
            <Typography>
              <strong>Prazo para Esclarecimentos:</strong>{' '}
              {details.deadlineClarification
                ? new Date(details.deadlineClarification).toLocaleString()
                : 'Não definido'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} />
            <Typography>
              <strong>Descrição:</strong> {details.simpleDescription}
            </Typography>
            <Typography>
              <strong>Suporte Legal:</strong> {details.legalSupport.description}
            </Typography>
            <Typography>
              <strong>Estágio:</strong> {details.stage.stageName}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LicitarDigitalDetails;
