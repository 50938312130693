import DocumentsRequiredModal from '@components/Documents/DocumentsRequiredModal';
import {
  Description as DescriptionIcon,
  FilePresent as FilePresentIcon,
  Folder as FolderIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useProfileStore } from '@store/profileStore';
import { FeatureId, Licitacao, PortalService } from '@types';
import { useState } from 'react';

interface DocumentsStepProps {
  licitacao: Licitacao;
  portalService: PortalService;
}

export const DocumentsStep: React.FC<DocumentsStepProps> = ({ licitacao }) => {
  const [documentsModalOpen, setDocumentsModalOpen] = useState(false);
  const { checkFeatureAccess } = useProfileStore();
  const hasDocAccess = checkFeatureAccess(FeatureId.DOC_VERIFICATION).hasAccess;

  return (
    <>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box sx={{ display: 'flex', flex: 1, gap: 2, width: '100%' }}>
          {/* Left Column */}
          <Box
            sx={{
              flex: 1,
              width: '50%',
              '& .MuiTypography-body1, & .MuiTypography-body2': { fontSize: '0.875rem' },
            }}
          >
            <Typography variant="h6" color="primary" gutterBottom sx={{ fontSize: '1rem' }}>
              Documentação para Habilitação
            </Typography>

            <Box sx={{ mt: 2 }}>
              <Typography
                variant="subtitle1"
                color="primary"
                gutterBottom
                sx={{ fontSize: '0.875rem' }}
              >
                Gestão de Documentos
              </Typography>
              <Typography variant="body2">
                <ul>
                  <li>Acesse a área de Documentos para gerenciar seus arquivos</li>
                  <li>Mantenha seus documentos sempre atualizados na plataforma</li>
                  <li>Organize por categorias para facilitar a localização</li>
                  <li>Configure alertas de vencimento para suas certidões</li>
                  <li>Utilize os modelos de declarações disponíveis no sistema</li>
                </ul>
              </Typography>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography
                variant="subtitle1"
                color="primary"
                gutterBottom
                sx={{ fontSize: '0.875rem' }}
              >
                Requisitos de Autenticação
              </Typography>
              <Typography variant="body2">
                <ul>
                  <li>Documentos digitais devem ter certificação ICP-Brasil</li>
                  <li>Cópias podem precisar de autenticação em cartório</li>
                  <li>Declarações devem ser assinadas pelo responsável legal</li>
                  <li>Procurações precisam ter firma reconhecida</li>
                </ul>
              </Typography>
            </Box>
          </Box>

          {/* Right Column */}
          <Box sx={{ flex: 1, width: '50%', display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box>
                <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                  Verifique os documentos necessários:
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<FilePresentIcon />}
                  onClick={() => setDocumentsModalOpen(true)}
                  sx={{ width: 'fit-content' }}
                  disabled={!hasDocAccess}
                >
                  {hasDocAccess
                    ? 'Ver lista de Documentos Necessários'
                    : 'Faça upgrade para analisar documentos'}
                </Button>
              </Box>

              <Box>
                <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                  Gerencie seus documentos:
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<FolderIcon />}
                  href="/documentos/documentos"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ width: 'fit-content' }}
                >
                  Acessar Área de Documentos
                </Button>
              </Box>

              <Box>
                <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '0.875rem' }}>
                  Crie declarações personalizadas:
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DescriptionIcon />}
                  href="/documentos/declaracoes"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ width: 'fit-content' }}
                >
                  Gerar Declarações
                </Button>
              </Box>
            </Box>

            <Box
              sx={{
                bgcolor: 'background.paper',
                border: 1,
                borderColor: 'info.main',
                p: 2,
                borderRadius: 1,
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <InfoIcon color="info" fontSize="small" />
                <Typography variant="subtitle2" sx={{ fontSize: '0.875rem' }}>
                  Utilize a área de Documentos para manter seu cadastro atualizado e gerar
                  declarações personalizadas para cada licitação.
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>

      {hasDocAccess && (
        <DocumentsRequiredModal
          open={documentsModalOpen}
          onClose={() => setDocumentsModalOpen(false)}
          licitacao={licitacao}
        />
      )}
    </>
  );
};
